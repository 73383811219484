import { AnyObjectSchema, ArraySchema } from "yup";

import { ObjectId } from "@bluelibs/ejson";
import { Constructor } from "@bluelibs/core";

export interface IDataProviderConfig {
  id: string;
  label: string;
  description?: string;
  endpoints: IDataProviderEndpointConfig<any, any>[];
}

export enum EndpointType {
  QUERY = "QUERY",
  SUBSCRIPTION = "SUBSCRIPTION",
  SEARCH = "SEARCH",
  STATIC = "STATIC",
}

export interface IHandler<Filter, Response> {
  handle(
    filters: Filter,
    businessUnitRoles: string[],

    panelId: ObjectId,
    userId: ObjectId,
  ): Promise<Response>;
}

export interface IDataProviderEndpointConfig<Filter = any, Response = any> {
  dataProvider?: IDataProviderConfig;
  id: string;
  label: string;
  getFilterSchema(): AnyObjectSchema;
  getResponseSchema(): AnyObjectSchema | ArraySchema<any, any>; // can be also array of objects
  availablePanels: IPanelConfig<Filter, Response>[];
  formTypes?: IFormTypeConfig[];
  type: EndpointType;
}

export interface IDataProviderEndpointConfigWithHandler<Filter, Response>
  extends IDataProviderEndpointConfig<Filter, Response> {
  handler: Constructor<IHandler<Filter, Response>>;
}

export interface IFormTypeConfig {
  id: string;
  label: string;
}

export interface IPanelConfig<Filter = any, Response = any> {
  id: string;
  endpoint?: IDataProviderEndpointConfig<Filter, Response>;
  // component: React.ComponentType<Filter>; // react component which renders the panel.
  label: string;
  description: string;
  /**
   * This refers to the component that will be used to render the selection of which panels to choose.
   */
  // previewComponent: React.ComponentType<any>;
}

// server-side-data-provider will get the info from common and will enhance it with the handler

export interface IPanelAccessPathCombinations {
  dataprovider: IDataProviderConfig;
  endpoint: IDataProviderEndpointConfig<any, any>;
  panel: IPanelConfig<any, any>;
}

export interface KeyValuePair {
  [key: string]: any;
}
