import * as yup from "yup";
import { AnyObject, ArraySchema } from "yup";

import { Is, Schema, a, an } from "@bluelibs/validator-bundle";
import {
  EndpointType,
  IDataProviderEndpointConfig,
} from "@stonex/xrisk-core-common";

import { ExamplePanelB } from "./ExamplePanelB";

@Schema()
export class ExampleDemoEndpointBFilter {
  @Is(an.array().of(a.string()).optional())
  range?: string[];
}

@Schema()
export class ExampleDemoEndpointBResponse {
  @Is(
    an.array().of(
      an.object().shape({
        date: a.string(),
        value: a.number(),
      }),
    ),
  )
  chartData: {
    date: string;
    value: number;
  }[];
}

export const ExampleDemoEndpointB: IDataProviderEndpointConfig<
  ExampleDemoEndpointBFilter,
  ExampleDemoEndpointBResponse
> = {
  id: "exampleEndpointB",
  label: "Example Endpoint B",
  getFilterSchema() {
    return Schema.from(ExampleDemoEndpointBFilter);
  },
  getResponseSchema() {
    return yup
      .array()
      .of(Schema.from(ExampleDemoEndpointBResponse)) as ArraySchema<
      any[],
      AnyObject,
      any,
      any
    >;
  },
  availablePanels: [ExamplePanelB],
  type: EndpointType.QUERY,
};
