import * as ReactDOM from "react-dom";

import { XUIProvider } from "@bluelibs/x-ui";

import { kernel } from "./kernel";
import "./styles.scss";

ReactDOM.render(
  <XUIProvider kernel={kernel} />,
  document.getElementById("root"),
);
