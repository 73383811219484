export const Circle = () => {
  return (
    <svg
      width="243"
      height="675"
      viewBox="0 0 243 675"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="adminLoginCircle"
    >
      <circle cx="-94.4244" cy="337.369" r="337.124" fill="#0080FF" />
    </svg>
  );
};
