// @ts-nocheck

import { Inject, Service } from "@bluelibs/core";
import {
  CommonDataProviderRegistry,
  EndpointType,
  IPanelConfig,
} from "@stonex/xrisk-core-common";
import { IPanelConfigExtended } from "../defs";
import { gql } from "@apollo/client";

@Service()
export class PanelUIRegistry {
  constructor(
    @Inject(() => CommonDataProviderRegistry)
    private readonly commonDataProviderRegistry: CommonDataProviderRegistry,
  ) {}

  getProviders() {
    return this.commonDataProviderRegistry.getProviders();
  }

  registerPanelComponents<F, R>(
    panelConfig: IPanelConfig<F, R>,
    component: React.ComponentType<F>,
    presentationalComponent: React.ComponentType<any>,
  ) {
    Object.assign(panelConfig, {
      component,
      presentationalComponent,
    });
  }

  getComponent(dataProviderId: string, endpointId: string, panelId: string) {
    const panel = this.findPanel(dataProviderId, endpointId, panelId);

    return panel.component;
  }

  getPresentationalComponent(
    dataProviderId: string,
    endpointId: string,
    panelId: string,
  ) {
    const panel = this.findPanel(dataProviderId, endpointId, panelId);

    return panel.presentationalComponent;
  }

  findPanel(
    dataProviderId: string,
    endpointId: string,
    panelId: string,
  ): IPanelConfigExtended {
    const dataProvider = this.getProviders().find(
      (p) => p.id === dataProviderId,
    );
    if (!dataProvider) {
      throw new Error(`Data provider with id ${dataProviderId} not found`);
    }
    const endpoint = dataProvider.endpoints.find((e) => e.id === endpointId);
    if (!endpoint) {
      throw new Error(`Endpoint with id ${endpointId} not found`);
    }
    const panel = endpoint.availablePanels.find(
      (p) => p.id === panelId,
    ) as IPanelConfigExtended;

    if (!panel) {
      throw new Error(`Panel with id ${panelId} not found`);
    }
    return panel;
  }

  generateQueryForPanel(
    dataProviderId: string,
    endpointId: string,
    type?: EndpointType,
  ): any {
    const endpointSchema = this.commonDataProviderRegistry
      .getEndpoint(dataProviderId, endpointId)
      .getResponseSchema();

    const generateFieldNames = (fields: any) => {
      return Object.keys(fields)
        .map((key) => {
          if (fields[key].type === "array" || fields[key].type === "object") {
            return `${key} { ${generateFieldNames(
              fields[key].innerType.fields,
            )} }`;
          }
          return key;
        })
        .join(" ");
    };

    const queryName =
      type === EndpointType.SUBSCRIPTION
        ? `Subscription_${dataProviderId}_${endpointId}`
        : `${dataProviderId}_${endpointId}`;

    const query = `${
      type === EndpointType.SUBSCRIPTION ? "subscription" : "query"
    } ${queryName}($input: ${queryName}_filter) {
      ${queryName}(input: $input) {
        ${generateFieldNames(endpointSchema.innerType.fields)}
      }
    }`;

    return query ? gql(query) : null;
  }
}
