import { DeleteFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  CREATE_WORKSPACE_FOLDER,
  DELETE_WORKSPACE_FOLDER,
  UPDATE_WORKSPACE_FOLDER,
} from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { Button, Form, Input, Modal, Popconfirm, notification } from "antd";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import {
  WorkspaceFolderContext,
  WorkspaceFolderCreateInput,
  WorkspaceFolderUpdateCustomInput,
} from "@adminRoot/api.types";

export interface IModalState {
  open: boolean;
  parentFolderId?: string | null;
  parentFolderName?: string | null;
  folderId?: string | null;
  folderName?: string;
  type: "add" | "edit";
  businessUnitId: string;
  context: WorkspaceFolderContext;
}

export const AddEditFolderModalAtom = atom({
  open: false,
  parentFolderId: null,
  parentFolderName: null,
  folderId: null,
  folderName: "",
  type: "add",
  businessUnitId: null,
  context: "mine",
} as unknown as IModalState);

interface props {
  refetch: () => void;
}

export const AddEditFolderModal = ({ refetch }: props) => {
  const [createWorkspaceFolder, { loading: createFolderLoading }] = useMutation(
    CREATE_WORKSPACE_FOLDER,
  );
  const [editWorkspaceFolder, { loading: updateFolderLoading }] = useMutation(
    UPDATE_WORKSPACE_FOLDER,
  );
  const [deleteWorkspaceFolder, { loading: deleteFolderLoading }] = useMutation(
    DELETE_WORKSPACE_FOLDER,
  );

  const [modalState, setModalState] = useAtom(AddEditFolderModalAtom);
  const resetModalState = () => {
    setModalState({
      open: false,
      parentFolderId: null,
      parentFolderName: null,
      folderId: null,
      folderName: "",
      type: "add",
      businessUnitId: "",
      context: WorkspaceFolderContext.MINE,
    });
  };

  const [form] = Form.useForm();

  const handleSubmitForm = () => {
    const operation =
      modalState.type === "edit" ? editWorkspaceFolder : createWorkspaceFolder;

    const variableInputs =
      modalState.type === "edit"
        ? ({
            workspaceFolderId: modalState.folderId,
            name: form.getFieldValue("name"),
          } as WorkspaceFolderUpdateCustomInput)
        : ({
            name: form.getFieldValue("name"),
            parentWorkspaceFolderId: modalState.parentFolderId ?? null,
            internalBusinessUnitId: modalState.businessUnitId,
            context: modalState.context.toUpperCase(),
          } as WorkspaceFolderCreateInput);

    operation({ variables: { input: variableInputs } })
      .then((_res) => {
        resetModalState();
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  const handleDeleteFolder = () => {
    deleteWorkspaceFolder({
      variables: {
        input: {
          workspaceFolderId: modalState.folderId,
        },
      },
    })
      .then(() => {
        resetModalState();
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  useEffect(() => {
    if (modalState.open) {
      form.setFieldsValue({
        name: modalState.folderName,
      });
    } else {
      form.resetFields();
    }
  }, [modalState]);

  return (
    <Modal
      open={modalState.open}
      closable={false}
      title={
        modalState.type === "edit"
          ? "Edit Folder: " + modalState.folderName
          : modalState.parentFolderName
          ? "Add New Folder Under " + modalState.parentFolderName
          : "Add New Folder"
      }
      onOk={() => form.submit()}
      onCancel={() => resetModalState()}
      confirmLoading={createFolderLoading || updateFolderLoading}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmitForm}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter folder name",
            },
          ]}
        >
          <Input placeholder="Folder Name" autoComplete="off" />
        </Form.Item>
      </Form>
      {modalState.type === "edit" && (
        <Popconfirm
          title="Delete Workspace"
          description="Are you sure you want to delete this folder? All subfolders will be deleted. Workspaces will be moved to the top level"
          onConfirm={() => handleDeleteFolder()}
          okText="Yes"
          cancelText="No"
        >
          <Button danger ghost loading={deleteFolderLoading}>
            <DeleteFilled /> Delete Folder
          </Button>
        </Popconfirm>
      )}
    </Modal>
  );
};
