import {
  allowedDataProvidersAtom,
  dockviewApiAtom,
} from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { useQuery } from "@apollo/client";
import { useContainer } from "@bluelibs/x-ui";
import { IDataProviderEndpointConfig } from "@stonex/xrisk-core-common";
import { PanelUIRegistry } from "@stonex/xrisk-core-panel-ui";
import { Select } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";

interface props {
  filterDisabled: boolean;
  onChange: (value: any) => void;
  filterValue: any;
  formType: string;
}

export const CustomPanelFilter = ({
  filterDisabled,
  onChange,
  filterValue,
  formType,
}: props) => {
  const [dockviewApi] = useAtom(dockviewApiAtom);

  const container = useContainer();
  const panelUIRegistry = container.get(PanelUIRegistry);

  const [allowedDataProviders] = useAtom(allowedDataProvidersAtom);
  const [searchTerm, setSearchTerm] = useState("");

  // @ts-expect-error when searchEndpoint is empty
  let searchEndpoint: IDataProviderEndpointConfig = null;

  allowedDataProviders?.forEach((dataProvider) => {
    dataProvider.endpoints?.forEach((endpoint: any) => {
      endpoint.formTypes?.forEach((type: any) => {
        if (type.id === formType) {
          searchEndpoint = endpoint;
        }
      });
    });
  });

  let query = null;
  if (!!searchEndpoint && Object.keys(searchEndpoint).length !== 0) {
    query = panelUIRegistry.generateQueryForPanel(
      searchEndpoint.dataProvider?.id ?? "",
      searchEndpoint.id,
      searchEndpoint.type,
    );
  }

  const {
    data: queryResponse,
    loading: queryLoading,
    error: queryError,
  } = useQuery(query, {
    variables: {
      input: {
        panelId: dockviewApi?.activePanel?.api.id,
        query: searchTerm,
      },
    },
    skip: !searchEndpoint || !query,
  });

  const { data } =
    queryResponse?.[
      `${searchEndpoint?.dataProvider?.id}_${searchEndpoint?.id}`
    ] ?? {};

  // @ts-ignore

  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      allowClear
      disabled={filterDisabled}
      onSearch={(value) => {
        setSearchTerm(value);
      }}
      value={filterValue}
      loading={queryLoading}
      notFoundContent={
        queryLoading ? (
          "Searching..."
        ) : // @ts-ignore
        queryError?.cause.code === "PANEL_DOES_NOT_EXIST" ? (
          "Add a panel that supports this filter to see results"
        ) : queryError ? (
          <span>Something went wrong</span>
        ) : (
          <span>No results found for "{searchTerm}"</span>
        )
      }
      onChange={onChange}
      placeholder="Select a value"
    >
      {data?.map((item: any) => (
        <Select.Option key={item.id} value={item.value}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
