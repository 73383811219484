import { gql } from "@apollo/client";

export const ADMIN_GET_ALL_USERS = gql`
  query AdminsUserGetAll($input: AdminsUserGetAllInput!) {
    AdminsUserGetAll(input: $input) {
      data {
        userId
        email
        firstName
        lastName
        roles
        isEnabled
        createdAt
      }
      total
    }
  }
`;

export const ADMIN_GET_USERS_BY_BUSINESS_UNIT = gql`
  query Query($input: AdminsBusinessUnitPermissionGetAllInput!) {
    AdminsBusinessUnitPermissionGetAll(input: $input) {
      data {
        _id
        user {
          _id
          fullName
          email
          roles
          isEnabled
        }
        internalAllowedPanels {
          path {
            dataProviderId
            endpointId
            panelId
          }
        }
        businessUnitRoles
        hasAccessToAllPanels
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query UserGetAll($input: UserGetAllInput!) {
    UserGetAll(input: $input) {
      data {
        userId
        email
        firstName
        lastName
        roles
        isEnabled
        createdAt
      }
      total
    }
  }
`;

export const DELETE_USER = gql`
  mutation AdminsUserRemove($input: AdminsUserRemoveInput!) {
    AdminsUserRemove(input: $input)
  }
`;

export const CREATE_USER = gql`
  mutation AdminsUserCreate($input: AdminsUserCreateInput!) {
    AdminsUserCreate(input: $input) {
      email
      firstName
      lastName
      isEnabled
    }
  }
`;

export const UPDATE_USER = gql`
  mutation AdminsUserUpdate($input: AdminsUserUpdateInput!) {
    AdminsUserUpdate(input: $input) {
      firstName
      lastName
      isEnabled
    }
  }
`;

export const USERS_UPDATE_USER = gql`
  mutation UsersUserUpdate($input: UsersUserUpdateInput!) {
    UsersUserUpdate(input: $input) {
      firstName
      lastName
    }
  }
`;

export const GET_USER_FOLDERS = gql`
  query me($input: MeInput) {
    me(input: $input) {
      _id
      businessUnits {
        id
        name
        userId
        workspaceFolders {
          workspaces {
            name
            _id
            visibility
          }
          _id
          name
          order
          parentWorkspaceFolderId
          internalBusinessUnitId
        }
        workspaces {
          _id
          name
          order
          visibility
        }
        rootWorkspaces {
          _id
          name
          order
          visibility
        }
      }
    }
  }
`;

export const GET_BUSINESS_UNIT_MEMBERS = gql`
  query BusinessUnitUsersGet($input: BusinessUnitUsersGetInput!) {
    BusinessUnitUsersGet(input: $input) {
      data {
        firstName
        lastName
        userId
      }
    }
  }
`;

export const GET_USER_ALLOWED_PANELS = gql`
  query BusinessUnitInternalAllowedPanelsGet(
    $input: BusinessUnitInternalAllowedPanelsGetInput!
  ) {
    BusinessUnitInternalAllowedPanelsGet(input: $input) {
      data
    }
  }
`;
