import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { debounce } from "lodash";
import { Collapse, Empty, Input, Menu, MenuItemProps, Spin } from "antd";

import { useEventManager, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { WorkspaceLiveDataEvent } from "@adminBundles/UIAppBundle/events";
import { ActionType } from "@adminBundles/UIAppBundle/types";
import { businessUnits } from "@stonex/xrisk-core-common";
import { useWorkspaceMenu } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";
import { RiseOutlined, TeamOutlined } from "@ant-design/icons";
import { UserRole, WorkspaceFolderContext } from "@adminRoot/api.types";

import { sidebarCollapsedAtom } from "../Sidebar";
import "./styles.scss";
import { AddEditFolderModal } from "../../AddEditFolderModal/AddEditFolderModal";
import { FolderContextItem } from "./FolderContextItem";

type SidebarMenuItem = MenuItemProps & {
  roles?: UserRole[];
  key?: string;
  children?: SidebarMenuItem[];
};

type props = {
  activeBusinessUnit: string;
};

export const SidebarMenu = ({ activeBusinessUnit }: props) => {
  const router = useRouter();
  const guardian = useAppGuardian();
  const darkTheme = window.localStorage.getItem("theme") === "dark";
  const [activeTab, setActiveTab] = useState<string[]>(["1"]);
  const { folderStructure, loading, refetch } = useWorkspaceMenu();

  const eventManager = useEventManager();

  useEffect(() => {
    const handler = async (event: WorkspaceLiveDataEvent) => {
      const triggersToRefetch = [
        ActionType.MODIFIED_BUSINESS_UNIT,
        ActionType.MODIFIED_WORKSPACE,
        ActionType.MODIFIED_WORKSPACE_FOLDER,
      ];

      if (triggersToRefetch.includes(event.data.actionType)) {
        refetch();
      }
    };

    eventManager.addListener(WorkspaceLiveDataEvent, handler);

    return () => {
      eventManager.removeListener(WorkspaceLiveDataEvent as any, handler);
    };
  }, []);

  useEffect(() => {
    setActiveTab(
      sidebarItems.map((item) =>
        item.key === router.history.location.pathname
          ? item.key
          : // @ts-ignore
            item.children?.find(
              (child) => child.key === router.history.location.pathname,
            )?.key ?? "",
      ),
    );
  }, []);

  const [sidebarCollapsed] = useAtom(sidebarCollapsedAtom);

  const [, setSearchQuery] = useState("");

  const debouncedSetSearchQuery = debounce(setSearchQuery, 300);

  const sidebarItems: SidebarMenuItem[] = [
    {
      title: "Admins",
      key: Routes.ADMINS.path,
      icon: <TeamOutlined />,
      onClick: () => router.go(Routes.ADMINS),
      roles: [UserRole.ADMIN],
    },
  ];

  // Business Unit Teams list for Admins
  businessUnits.forEach((bu) => {
    sidebarItems.unshift({
      title: bu.name,
      key: `/dashboard/team/${bu.alias.toLowerCase()}`,
      icon: <RiseOutlined />,
      onClick: () =>
        router.go(Routes.TEAM, {
          params: {
            name: bu.alias.toLowerCase(),
          },
        }),
      roles: [UserRole.ADMIN],
    });
  });

  const businessUnitsDb = Object.keys(folderStructure);

  const isBusinessUnitAvailable = businessUnitsDb.length > 0;

  return (
    <>
      {!sidebarCollapsed && activeBusinessUnit !== "admin" && (
        <Input.Search
          placeholder="Search"
          onChange={(e) => {
            debouncedSetSearchQuery(e.target.value);
          }}
          autoComplete="off"
        />
      )}
      {!sidebarCollapsed && activeBusinessUnit !== "admin" && (
        <div
          style={{
            marginTop: 10,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {isBusinessUnitAvailable ? (
            Object.keys(WorkspaceFolderContext).map((type) => (
              <FolderContextItem
                activeBusinessUnit={activeBusinessUnit}
                folderStructure={folderStructure}
                refetch={refetch}
                type={type}
                key={type}
              />
            ))
          ) : (
            <div className="noBusinessUnitAvailable">
              {loading ? <Spin /> : <Empty description="No Data Available" />}
            </div>
          )}
        </div>
      )}
      {activeBusinessUnit === "admin" && (
        <Menu
          defaultSelectedKeys={activeTab}
          mode="inline"
          theme={darkTheme ? "dark" : "light"}
        >
          {sidebarItems
            .filter((item) => {
              if (item.roles) {
                return item.roles.some((role) =>
                  guardian.state.user?.roles.includes(role),
                );
              }
              return true;
            })
            .map((item) => (
              <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                {item.title}
              </Menu.Item>
            ))}
        </Menu>
      )}

      {/* MODALS */}
      <AddEditFolderModal refetch={refetch} />
      {/* MODALS */}
    </>
  );
};
