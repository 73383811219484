import { Workspace } from "@adminRoot/api.types";
import {
  Collection,
  CollectionLinkConfig,
  CollectionTransformMap,
} from "@bluelibs/x-ui";

import { UsersCollection, WorkspacePermissionsCollection } from "..";

export type { Workspace };

export class WorkspacesCollection extends Collection<Workspace> {
  getName() {
    return "Workspaces";
  }

  getInputs() {
    return {
      insert: "WorkspaceInsertInput!",
      update: "WorkspaceUpdateInput!",
    };
  }

  // Return here the relations with other configs
  getLinks(): CollectionLinkConfig<Workspace>[] {
    return [
      {
        collection: () => UsersCollection,
        name: "ownerUser",
        field: "ownerUserId",
      },
      {
        collection: () => WorkspacesCollection,
        name: "clonedFromWorkspace",
        field: "clonedFromWorkspaceId",
      },
      {
        collection: () => UsersCollection,
        name: "createdBy",
        field: "createdById",
      },
      {
        collection: () => UsersCollection,
        name: "updatedBy",
        field: "updatedById",
      },
      {
        collection: () => WorkspacePermissionsCollection,
        name: "permissions",
        many: true,
      },
    ];
  }

  // Return here how you want to transform certain fields
  getTransformMap(): CollectionTransformMap<Workspace> {
    return {
      createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
      updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
    };
  }
}
