import { gql } from "@apollo/client";

export const CREATE_PANEL = gql`
  mutation PanelCreate($input: PanelCreateInput!) {
    PanelCreate(input: $input) {
      _id
      name
    }
  }
`;

export const UPDATE_PANEL = gql`
  mutation PanelUpdate($input: PanelUpdateCustomInput!) {
    PanelUpdate(input: $input) {
      _id
      name
    }
  }
`;

export const DELETE_PANEL = gql`
  mutation PanelRemove($input: PanelRemoveInput!) {
    PanelRemove(input: $input)
  }
`;

export const DUPLICATE_PANEL = gql`
  mutation PanelDuplicate($input: PanelDuplicateInput!) {
    PanelDuplicate(input: $input) {
      _id
      name
    }
  }
`;

export const CREATE_BUSINESS_UNIT_PERMISSION = gql`
  mutation AdminsBusinessUnitPermissionCreate(
    $input: AdminsBusinessUnitPermissionCreateInput!
  ) {
    AdminsBusinessUnitPermissionCreate(input: $input) {
      _id
    }
  }
`;

export const UPDATE_BUSINESS_UNIT_PERMISSION = gql`
  mutation Mutation($input: AdminsBusinessUnitPermissionUpdateInput!) {
    AdminsBusinessUnitPermissionUpdate(input: $input) {
      _id
    }
  }
`;

export const REMOVE_BUSINESS_UNIT_PERMISSION = gql`
  mutation Mutation($input: AdminsBusinessUnitPermissionRemoveInput!) {
    AdminsBusinessUnitPermissionRemove(input: $input)
  }
`;

export const GET_USER_ALLOWED_PANELS = gql`
  query BusinessUnitInternalAllowedPanelsGet(
    $input: BusinessUnitInternalAllowedPanelsGetInput!
  ) {
    BusinessUnitInternalAllowedPanelsGet(input: $input) {
      data
    }
  }
`;
