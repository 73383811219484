import { Panel } from "@adminRoot/api.types";
import {
  Collection,
  CollectionLinkConfig,
  CollectionTransformMap,
} from "@bluelibs/x-ui";

import { UsersCollection, WorkspacesCollection } from "..";

export type { Panel };

export class PanelsCollection extends Collection<Panel> {
  getName() {
    return "Panels";
  }

  getInputs() {
    return {
      insert: "PanelInsertInput!",
      update: "PanelUpdateInput!",
    };
  }

  // Return here the relations with other configs
  getLinks(): CollectionLinkConfig<Panel>[] {
    return [
      {
        collection: () => WorkspacesCollection,
        name: "workspace",
        field: "workspaceId",
      },
      {
        collection: () => PanelsCollection,
        name: "clonedFromPanel",
        field: "clonedFromPanelId",
      },
      {
        collection: () => UsersCollection,
        name: "createdBy",
        field: "createdById",
      },
      {
        collection: () => UsersCollection,
        name: "updatedBy",
        field: "updatedById",
      },
    ];
  }

  // Return here how you want to transform certain fields
  getTransformMap(): CollectionTransformMap<Panel> {
    return {
      createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
      updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
    };
  }
}
