import { useQuery } from "@apollo/client";
import { useAppGuardian } from "../services/AppGuardian";
import {
  AdminsUserGetAllPaginatedResponse,
  AdminsUserGetResponse,
  UserRole,
  UserGetAllPaginatedResponse,
  UsersUserGetResponse,
} from "@adminRoot/api.types";
import { ADMIN_GET_ALL_USERS, GET_ALL_USERS } from "../queries/user.query";
import { useEffect, useState } from "react";

export const useUsers = (query?: string) => {
  const { state } = useAppGuardian();
  const isUserAdmin = state?.user?.roles.includes("ADMIN");

  const [users, setUsers] = useState<
    AdminsUserGetResponse[] | UsersUserGetResponse[]
  >([]);

  const getAllUsersQuery = useQuery(
    isUserAdmin ? ADMIN_GET_ALL_USERS : GET_ALL_USERS,
    {
      variables: {
        input: {
          options: {
            limit: 10000,
            skip: 0,
          },
          filters: {
            roles: [UserRole.USER],
            query: query || "",
          },
        },
      },
    },
  );

  useEffect(() => {
    if (getAllUsersQuery.data && isUserAdmin) {
      const { AdminsUserGetAll } = getAllUsersQuery.data as {
        AdminsUserGetAll: AdminsUserGetAllPaginatedResponse;
      };

      setUsers(AdminsUserGetAll.data);
    } else if (getAllUsersQuery.data && !isUserAdmin) {
      const { UsersUserGetAll } = getAllUsersQuery.data as {
        UsersUserGetAll: UserGetAllPaginatedResponse;
      };

      setUsers(UsersUserGetAll.data);
    }
  }, [getAllUsersQuery.data]);

  return {
    users,
    loading: getAllUsersQuery.loading,
  };
};
