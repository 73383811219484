import { XRouter, XUIBundle } from "@bluelibs/x-ui";
import * as Routes from "./routes";
import { Bundle } from "@bluelibs/core";
import { AppGuardian } from "./services/AppGuardian";
// import * as ComponentOverrides from "./overrides";

export class UIAppBundle extends Bundle {
  async init() {
    const xui = this.container.get(XUIBundle);
    xui.setGuardianClass(AppGuardian);

    const router = this.container.get(XRouter);
    router.add(Routes);
  }
}
