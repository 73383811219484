import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  Button,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { addEditVariableFormConfigAtom } from "../AddEditVariableForm/AddEditVariableForm";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import { useMutation } from "@apollo/client";
import { REMOVE_WORKSPACE_VARIABLE } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import {
  WorkspaceVariableGetResponse,
  WorkspaceVariableType,
} from "@adminRoot/api.types";
import { useWorkspaceVariables } from "@adminBundles/UIAppBundle/hooks/useWorkspaceVariables";
import { snakeCaseToText } from "@adminBundles/UIAppBundle/utils/functions";
import { dockviewApiAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { useState } from "react";
import { IDockviewPanel } from "dockview";
import {
  PANEL_MODAL_TABS,
  addEditPanelModalConfigAtom,
} from "../AddEditPanelModal/AddEditPanelModal";
import { isArray } from "lodash";

export const WorkspaceModalVariablesTable = () => {
  const [_, setAddEditVariableFormConfig] = useAtom(
    addEditVariableFormConfigAtom,
  );

  const [addEditPanelModalConfig, setAddEditPanelModalConfig] = useAtom(
    addEditPanelModalConfigAtom,
  );
  const [panelsUsingDeletingVariable, setPanelsUsingDeletingVariable] =
    useState<IDockviewPanel[]>([]);

  const [dockviewApi] = useAtom(dockviewApiAtom);

  const { workspaceVariables, refetch, loading } = useWorkspaceVariables();

  const [removeVariable, { loading: removeLoading }] = useMutation(
    REMOVE_WORKSPACE_VARIABLE,
  );

  const findPanelsUsingDeletingVariable = (variableId: string) => {
    return dockviewApi.panels.reduce((acc: IDockviewPanel[], panel) => {
      const isVariableUsed = Object.values(panel.params?.filters).some(
        (filter: any) => {
          return (
            filter?.sourceType === "variable" && filter?.value === variableId
          );
        },
      );

      if (isVariableUsed) {
        acc.push(panel);
      }

      return acc;
    }, []);
  };

  const columns: ColumnsType<WorkspaceVariableGetResponse> = [
    {
      title: "ID",
      dataIndex: "identity",
      ellipsis: true,
      key: "id",
    },
    {
      title: "Label",
      key: "label",
      ellipsis: true,
      render: (_, { label, color }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 8,
              minWidth: 8,
              height: 8,
              minHeight: 8,
              borderRadius: "50%",
              backgroundColor: color,
              marginRight: 8,
            }}
          />
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </span>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value) => snakeCaseToText(value),
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      key: "defaultValue",
      ellipsis: true,
      render: (_, record) => {
        if (record.type === WorkspaceVariableType.DATE_RANGE_TIME_AWARE) {
          if (
            isArray(record.defaultValue) &&
            record.defaultValue.length === 2
          ) {
            return `
            ${dayjs(record.defaultValue[0]).format("YYYY-MM-DD")} - ${dayjs(
              record.defaultValue[1],
            ).format("YYYY-MM-DD")}`;
          } else if (record.defaultValue) {
            return record.defaultValue;
          } else {
            return "-";
          }
        }
        if (record.type === WorkspaceVariableType.DATE_TIME_AWARE) {
          if (dayjs(record.defaultValue).isValid()) {
            return dayjs(record.defaultValue).format("YYYY-MM-DD");
          } else {
            return record.defaultValue?.toString() || "-";
          }
        }
        // limit the length of the string to 50 characters
        return record.defaultValue?.toString() || "-";
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      align: "center",
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit Variable">
            <Button
              className="actionButton"
              onClick={() => {
                setAddEditVariableFormConfig({
                  visible: true,
                  editingVariable: record,
                });
              }}
              shape="circle"
            >
              <EditFilled />
            </Button>
          </Tooltip>

          <Popconfirm
            title="Delete Variable"
            description="Are you sure you want to delete this variable?"
            onConfirm={() => {
              const _panelsUsingDeletingVariable =
                findPanelsUsingDeletingVariable(record.identity);
              if (_panelsUsingDeletingVariable.length) {
                setPanelsUsingDeletingVariable(_panelsUsingDeletingVariable);
              } else {
                removeVariable({
                  variables: {
                    input: {
                      workspaceVariableId: record._id,
                    },
                  },
                })
                  .then(() => refetch())
                  .catch((err) => {
                    notification.error({ message: err.message });
                  });
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button className="actionButton" danger ghost shape="circle">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Variable Cannot Be Deleted!"
        open={panelsUsingDeletingVariable.length > 0}
        onOk={() => {
          setPanelsUsingDeletingVariable([]);
        }}
        onCancel={() => {
          setPanelsUsingDeletingVariable([]);
        }}
      >
        <p>
          The following panels are using this variable. Please remove the
          variable from the panel filters before deleting it.
        </p>
        <ul>
          {panelsUsingDeletingVariable.map((panel) => (
            <li key={panel.id}>
              <Button
                type="link"
                onClick={() => {
                  // to close the modal
                  setPanelsUsingDeletingVariable([]);
                  setAddEditPanelModalConfig({
                    open: true,
                    activeTab: PANEL_MODAL_TABS.FILTERS,
                    editingPanel: {
                      ...panel,
                      params: panel.params ?? {},
                      title: panel.api.title,
                      api: panel.api,
                    },
                  });
                }}
              >
                {panel.title}
              </Button>
            </li>
          ))}
        </ul>
      </Modal>
      <Table
        columns={columns}
        // disabled pagination for now
        loading={loading || removeLoading}
        pagination={false}
        dataSource={workspaceVariables}
      />
    </>
  );
};
