import { useMutation } from "@apollo/client";
import {
  AdminsBusinessUnitPermissionGetResponse,
  AdminsBusinessUnitPermissionUpdateInput,
  BusinessUnitPermissionInternalAllowedPanel,
  User,
} from "@adminRoot/api.types";
import {
  BusinessUnit,
  CommonDataProviderRegistry,
} from "@stonex/xrisk-core-common";
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Modal,
  Row,
  Spin,
  Table,
  notification,
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useContainer } from "@bluelibs/x-ui";
import { useUsers } from "@adminBundles/UIAppBundle/hooks/useUsers";
import { getPanelAccessPathString } from "@adminBundles/UIAppBundle/utils/functions";
import "./styles.scss";
import { UPDATE_BUSINESS_UNIT_PERMISSION } from "@adminBundles/UIAppBundle/queries/panels.query";
import { PanelUIRegistry } from "@stonex/xrisk-core-panel-ui";
import { DebounceUserSelect } from "./DebounceUserSelect";
import { BusinessUnitRoleMultiSelect } from "../BusinessUnitRoleMultiSelect/BusinessUnitRoleMultiSelect";

type FieldType = {
  id: string;
  // for the lack of a better type
  businessUnitPermissions: any[];
  businessUnitRoles: string[];
  hasAccessToAllPanels: boolean;
};

export const AddUserForm = forwardRef<
  FormInstance,
  {
    onSuccess: () => void;
    editingUser:
      | (User & {
          allowedPanels: BusinessUnitPermissionInternalAllowedPanel[];
          businessUnitPermissionId: string;
          hasAccessToAllPanels: boolean;
          businessUnitRoles: string[];
        })
      | null;
    businessUnit: BusinessUnit;
    teamMembers: AdminsBusinessUnitPermissionGetResponse[];
  }
>((props, ref) => {
  const [form] = Form.useForm();
  const [updateUser, { loading }] = useMutation<
    {
      AdminsBusinessUnitPermissionUpdate: AdminsBusinessUnitPermissionGetResponse;
    },
    { input: AdminsBusinessUnitPermissionUpdateInput }
  >(UPDATE_BUSINESS_UNIT_PERMISSION);

  const [panelPresentationModalOpen, setPanelPresentationModalOpen] =
    useState(false);

  const [presentationalComponent, setPresentationalComponent] =
    useState<React.ReactNode>(null);

  // @ts-ignore
  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  const [panels, setPanels] = useState([] as any[]);
  const [accessToAllPanelsChecked, setAccessToAllPanelsChecked] =
    useState(false);

  const container = useContainer();
  const dataProviderRegistry = container.get(CommonDataProviderRegistry);
  const panelUiRegistry = container.get(PanelUIRegistry);
  const panelAccessPathCombinations = dataProviderRegistry
    .getPanelAccessPathCombinations()
    .filter((path) => {
      return props.businessUnit.dataProviderIds.includes(path.dataprovider.id);
    });

  useEffect(() => {
    dataProviderRegistry.getProviders().forEach((provider) => {
      if (props.businessUnit.dataProviderIds.includes(provider.id)) {
        provider.endpoints.forEach((endpoint) => {
          setPanels([...panels, ...endpoint.availablePanels]);
        });
      }
    });
  }, []);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  const { users, loading: usersLoading } = useUsers("");

  const onFinish = (values: FieldType) => {
    const internalAllowedPanels: any[] = values.businessUnitPermissions?.map(
      (permission) => {
        return {
          path: {
            panelId: permission.panel.id,
            endpointId: permission.endpoint.id,
            dataProviderId: permission.dataprovider.id,
          },
        };
      },
    );

    updateUser({
      variables: {
        input: {
          hasAccessToAllPanels: values.hasAccessToAllPanels ?? false,
          internalAllowedPanels: internalAllowedPanels ?? [],
          internalBusinessUnitId: props.businessUnit.id,
          userId: values.id,
          businessUnitRoles:
            values.businessUnitRoles?.length > 0
              ? [...values.businessUnitRoles]
              : [],
        },
      },
    })
      .then(() => {
        props.onSuccess();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  useEffect(() => {
    if (props.editingUser) {
      if (users?.length > 0) {
        form.setFieldsValue({
          id: props.editingUser._id,
        });
      }
      if (props.editingUser.allowedPanels) {
        form.setFieldsValue({
          businessUnitPermissions: props.editingUser.allowedPanels.map((p) => {
            return panelAccessPathCombinations.find((path) =>
              path.dataprovider.id === p.path.dataProviderId &&
              path.endpoint.id === p.path.endpointId &&
              path.panel.id === p.path.panelId
                ? path
                : null,
            );
          }),
        });
      }

      form.setFieldsValue({
        hasAccessToAllPanels: props.editingUser.hasAccessToAllPanels,
        businessUnitRoles: props.editingUser.businessUnitRoles,
      });

      setAccessToAllPanelsChecked(props.editingUser.hasAccessToAllPanels);
    } else {
      form.resetFields();
    }
  }, [form, props.editingUser, users]);

  const columns = [
    {
      title: "Panel Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (_: any, record: any) => {
        return panels.map((panel) => {
          if (panel.id === record.panel.id) {
            return panel.label;
          }

          return record.panel.label;
        });
      },
    },
    {
      title: "Panel Path",
      dataIndex: "panelPath",
      key: "panelPath",
      render: (_: any, record: any) => {
        return `${record.dataprovider.id}.${record.endpoint.id}.${record.panel.id}`;
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      width: "10%",
      render: (_: any, record: any) => (
        <Button
          type="link"
          onClick={() => {
            const presentationalComponent = panelUiRegistry.findPanel(
              record.dataprovider.id,
              record.endpoint.id,
              record.panel.id,
            ).presentationalComponent;
            setPresentationalComponent(presentationalComponent);
            setPanelPresentationModalOpen(true);
          }}
        >
          View
        </Button>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: any, record: any) =>
        panels.map((panel) => {
          if (panel.id === record.panel.id) {
            return (
              <span style={{ fontSize: "12px" }}>{panel.description}</span>
            );
          }

          return (
            <span style={{ fontSize: "12px" }}>{record.panel.description}</span>
          );
        }),
    },
  ];

  return (
    <>
      <Modal
        open={panelPresentationModalOpen}
        onOk={() => setPanelPresentationModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        title="Panel Presentation"
        width={800}
      >
        <div style={{ padding: "24px" }}>
          {presentationalComponent ?? (
            <span>Presentational Component Not Found</span>
          )}
        </div>
      </Modal>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={{ hasAccessToAllPanels: false }}
        style={{ padding: "24px" }}
        form={form}
      >
        <Row justify={"space-between"}>
          <Col span={24}>
            <Form.Item<FieldType>
              label="User"
              name="id"
              rules={[{ required: true, message: "Please select a user!" }]}
            >
              <DebounceUserSelect
                disabled={!!props.editingUser}
                teamMembers={props.teamMembers}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify={"space-between"}>
          <Col span={24}>
            <Form.Item<FieldType> label="User role(s)" name="businessUnitRoles">
              <BusinessUnitRoleMultiSelect
                businessUnitId={props.businessUnit.id}
                selectedRoles={props.editingUser?.businessUnitRoles ?? []}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item name="hasAccessToAllPanels">
              <Checkbox
                defaultChecked={props.editingUser?.hasAccessToAllPanels}
                onChange={(e) => {
                  form.setFieldValue("hasAccessToAllPanels", e.target.checked);
                  form.setFieldValue("businessUnitPermissions", []);
                  setAccessToAllPanelsChecked(e.target.checked);
                }}
              />
              <span style={{ marginLeft: "8px" }}>
                Has access to all panels
              </span>
            </Form.Item>
          </Col>
        </Row>

        {!accessToAllPanelsChecked && (
          <Row>
            <Form.Item<FieldType> name="businessUnitPermissions">
              <Table
                style={{ width: "100%" }}
                dataSource={panelAccessPathCombinations}
                columns={columns}
                pagination={false}
                rowKey={(record) => getPanelAccessPathString(record)}
                scroll={{ y: 240 }}
                rowSelection={{
                  hideSelectAll: true,
                  type: "checkbox",
                  onSelect: (record, selected, selectedRows) => {
                    form.setFieldValue("businessUnitPermissions", selectedRows);
                  },
                  defaultSelectedRowKeys: props.editingUser?.allowedPanels.map(
                    (p) => getPanelAccessPathString(p.path),
                  ),
                  // selectedRowKeys: form.getFieldValue("businessUnitPermissions"),
                }}
              />
            </Form.Item>
          </Row>
        )}

        {loading && (
          <Col span={24} style={{ textAlign: "center" }}>
            <Spin />
          </Col>
        )}
      </Form>
    </>
  );
});
