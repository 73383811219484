import { IRoute } from "@bluelibs/x-ui";
import { MePage } from "./Me/MePage";
import { TeamPage } from "./Team/TeamPage";
import { Workspace } from "./Workspace/Workspace";
import { UserRole } from "@adminRoot/api.types";
import { AdminsPage } from "./Admins/AdminsPage";

export const DASHBOARD: IRoute = {
  path: "/dashboard",
  component: MePage,
  exact: true,
};

export const ME: IRoute = {
  path: "/dashboard/me",
  component: MePage,
};

export const TEAM: IRoute = {
  path: "/dashboard/team/:name",
  component: TeamPage,
  roles: [UserRole.ADMIN],
};

export const ADMINS: IRoute = {
  path: "/dashboard/admins",
  component: AdminsPage,
  roles: [UserRole.ADMIN],
};

export const WORKSPACE: IRoute = {
  path: "/dashboard/workspace/:id",
  component: Workspace,
};
