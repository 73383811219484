import * as React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export function ExamplePanelComponent(props: any) {
  const [rowData, setRowData] = React.useState(props.columnRowData);

  React.useEffect(() => {
    setRowData(props.columnRowData);
  }, [props.columnRowData]);

  const [colDefs, setColDefs] = React.useState([
    { field: "stock" },

    {
      field: "price",
      valueFormatter: function (params: any) {
        return `${params.value}$`;
      },
    },
    {
      field: "date",
      valueFormatter: function (params: any) {
        return (
          new Date(params.value).toLocaleDateString() ??
          new Date().toLocaleTimeString()
        );
      },
    },
    {
      field: "Country",
      valueGetter: function (params: any) {
        return params.data.country;
      },
      cellRenderer: function (params: any) {
        return params.value.charAt(0).toUpperCase() + params.value.slice(1);
      },
    },
  ]);

  return (
    <>
      <div
        className="ag-theme-alpine-dark" // applying the grid theme
        style={{ height: "100%" }} // the grid will fill the size of the parent container
      >
        <AgGridReact rowData={rowData} columnDefs={colDefs} />
      </div>
      {/* <Button type="primary" onClick={props.onChange}>
        Change Variable
      </Button> */}
    </>
  );
}

export function ExamplePanelPreview() {
  return <div>test</div>;
}
