import { Collapse, Empty, Flex, notification, Typography } from "antd";
import { IconButton } from "../../IconButton/IconButton";
import { AppstoreAddOutlined, FolderAddFilled } from "@ant-design/icons";
import {
  WorkspaceFolderContext,
  Workspace,
  WorkspaceFolder,
} from "@adminRoot/api.types";
import {
  addEditWorkspaceModalConfigAtom,
  WORKSPACE_MODAL_TABS,
} from "../../AddEditWorkspaceModal/AddEditWorkspaceModal";
import { useAtom } from "jotai";
import { AddEditFolderModalAtom } from "../../AddEditFolderModal/AddEditFolderModal";
import { FolderItem } from "./FolderItem";
import { WorkspaceItem } from "./WorkspaceItem";
import { ExtendedWorkspaceFolder } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";
import { useDrop } from "react-dnd";
import { useMutation } from "@apollo/client";
import { UPDATE_WORKSPACE_FOLDER } from "@adminBundles/UIAppBundle/queries/workspaces.query";

interface FolderContextItemProps {
  activeBusinessUnit: string;
  folderStructure: any;
  type: string;
  refetch: () => void;
}

export function FolderContextItem({
  activeBusinessUnit,
  folderStructure,
  type,
  refetch,
}: FolderContextItemProps) {
  const [, setAddEditFolderModalConfig] = useAtom(AddEditFolderModalAtom);
  const [, setAddEditWorkspaceModalConfig] = useAtom(
    addEditWorkspaceModalConfigAtom,
  );

  const [updateFolder, { loading }] = useMutation(UPDATE_WORKSPACE_FOLDER);

  const renderFolder = (
    folders: Array<ExtendedWorkspaceFolder | Workspace>,
  ) => {
    return folders?.map((item, index) => {
      if (item.__typename === "WorkspaceFolder") {
        return (
          <FolderItem
            folder={item}
            workspaceFoldersData={
              folderStructure[item.internalBusinessUnitId]
                .mine as WorkspaceFolder[]
            }
            key={item._id}
            index={index}
            refetch={refetch}
          />
        );
      } else {
        return <WorkspaceItem workspace={item as Workspace} key={item._id} />;
      }
    });
  };

  const [{ isOver, isOverCurrent }, drop] = useDrop(() => ({
    accept: ["workspace", "folder"],
    drop(draggedItem: WorkspaceFolder | Workspace, monitor) {
      const didDropOnChild = monitor.didDrop();
      if (didDropOnChild) {
        return;
      }
      const isItemWorkspace = draggedItem.__typename === "Workspace";
      if (isItemWorkspace) {
        updateFolder({
          variables: {
            input: {
              workspaceFolderId: null,
              workspacesIds: [draggedItem._id],
            },
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      } else if (!isItemWorkspace) {
        updateFolder({
          variables: {
            input: {
              parentWorkspaceFolderId: null,
              workspaceFolderId: draggedItem._id,
            },
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  return (
    <Collapse bordered={false} defaultActiveKey={["MINE", "SHARED"]}>
      <Collapse.Panel
        style={{ backgroundColor: isOver ? "#f0f0f01e" : "transparent" }}
        ref={drop}
        header={
          <Flex justify="space-between" align="center">
            <Typography.Text strong>
              {type === WorkspaceFolderContext.MINE
                ? "My Workspaces"
                : "Shared Workspaces"}
            </Typography.Text>

            <div className="topLevelActionHolder">
              <IconButton
                buttonProps={{ type: "text" }}
                tooltipTitle="Add Folder"
                icon={<FolderAddFilled />}
                onClick={(e) => {
                  e.stopPropagation();
                  setAddEditFolderModalConfig({
                    open: true,
                    type: "add",
                    businessUnitId: activeBusinessUnit,
                    context: type as WorkspaceFolderContext,
                  });
                }}
              />

              {type === WorkspaceFolderContext.MINE && (
                <IconButton
                  buttonProps={{ type: "text" }}
                  tooltipTitle="Add Workspace"
                  icon={<AppstoreAddOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAddEditWorkspaceModalConfig({
                      open: true,
                      type: "add",
                      activeTab: WORKSPACE_MODAL_TABS.GENERAL,
                      businessUnitId: activeBusinessUnit,
                    });
                  }}
                />
              )}
            </div>
          </Flex>
        }
        key={type}
        className="businessUnitFoldersCollapse"
      >
        {renderFolder(folderStructure?.[activeBusinessUnit]?.[type])}
        {folderStructure?.[activeBusinessUnit]?.[type]?.length === 0 && (
          <Empty description="Folder Empty" />
        )}
      </Collapse.Panel>
    </Collapse>
  );
}
