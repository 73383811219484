import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  notification,
} from "antd";
import { Dashboard } from "../Dashboard";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery } from "@apollo/client";
import {
  UserRole,
  AdminsUserGetAllInput,
  AdminsUserGetAllPaginatedResponse,
  AdminsUserRemoveInput,
  AdminsUserGetResponse,
} from "@adminRoot/api.types";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import "./styles.scss";
import { useRef, useState } from "react";
import {
  ADMIN_GET_ALL_USERS,
  DELETE_USER,
} from "@adminBundles/UIAppBundle/queries/user.query";
import Header from "@adminBundles/UIAppBundle/components/Header/Header";
import { useRouter } from "@bluelibs/x-ui";
import { AddAdminForm } from "@adminBundles/UIAppBundle/components/AddAdminForm/AddAdminForm";

export function AdminsPage() {
  const router = useRouter();
  const [editingUser, setEditingUser] = useState<AdminsUserGetResponse | null>(
    null,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");

  const formRef = useRef();

  const [addAdminModalOpen, setAddAdminModalOpen] = useState(false);

  const {
    data: queryResponse,
    loading,
    refetch,
  } = useQuery<
    {
      AdminsUserGetAll: AdminsUserGetAllPaginatedResponse;
    },
    { input: AdminsUserGetAllInput }
  >(ADMIN_GET_ALL_USERS, {
    variables: {
      input: {
        options: {
          limit: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
        filters: {
          roles: [UserRole.ADMIN],
          query: searchTerm,
        },
      },
    },
  });

  const { data, total } = queryResponse?.AdminsUserGetAll || {};

  const [deleteAdmin, { loading: deleteAdminLoading }] = useMutation<
    {
      AdminsUserRemove: boolean;
    },
    { input: AdminsUserRemoveInput }
  >(DELETE_USER);

  const handleDelete = (id: string) => {
    deleteAdmin({
      variables: {
        input: {
          userId: id,
        },
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  const columns: ColumnsType<AdminsUserGetResponse> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { firstName, lastName }) => (
        <span>{firstName + " " + lastName}</span>
      ),
      sorter: (a, b) => (a?.lastName || "").localeCompare(b?.lastName || ""),
      width: "25%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      render: (_, { email }) => <span>{email}</span>,
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      filters: [
        { text: "Admin", value: UserRole.ADMIN },
        { text: "User", value: UserRole.USER },
      ],
      onFilter: (value: UserRole | any, record) =>
        record.roles.includes(value) as boolean,
      render: (_, { roles }) =>
        roles?.map((tag) => <Tag key={tag}>{tag.toUpperCase()}</Tag>),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "isEnabled",
      filters: [
        { text: "Enabled", value: true },
        { text: "Disabled", value: false },
      ],
      onFilter: (value: boolean | any, record) => record.isEnabled === value,
      render: (_, { isEnabled }) =>
        isEnabled ? (
          <Tag color="green">Enabled</Tag>
        ) : (
          <Tag color="red">Disabled</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="actionButton"
            onClick={() => {
              if (record) {
                setEditingUser(record);
                setAddAdminModalOpen(true);
              }
            }}
          >
            <EditFilled />
          </Button>
          <Popconfirm
            title="Delete User"
            description="Are you sure you want to delete this admin?"
            onConfirm={() => handleDelete(record?.userId)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger ghost className="actionButton">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Dashboard>
      <Header title="Admins" />

      <div className="teamPageLayout">
        <div className="teamTableHeader">
          <Button
            onClick={() => {
              setEditingUser(null);
              setAddAdminModalOpen(true);
            }}
            className="addUserButton"
            type="primary"
          >
            + Add New
          </Button>
          <Input.Search
            placeholder="Search User"
            onChange={(e) => setSearchTerm(e.target.value)}
            autoComplete="off"
          />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading || deleteAdminLoading}
          pagination={{
            total: total,
            pageSize,
            onChange: (page) => {
              setCurrentPage(page);
            },
            onShowSizeChange: (_, size) => {
              setPageSize(size);
            },
            current: currentPage,
            pageSizeOptions: ["4", "8", "12"],
            showSizeChanger: true,
            defaultPageSize: 8,
          }}
          size="middle"
        />
      </div>
      <Modal
        open={addAdminModalOpen}
        // @ts-ignore
        onOk={() => formRef?.current && formRef?.current?.submit()}
        onCancel={() => {
          setEditingUser(null);
          setAddAdminModalOpen(false);
        }}
        destroyOnClose
        title={editingUser ? "Edit Admin" : "Add New Admin"}
        width={600}
      >
        <AddAdminForm
          ref={formRef as any}
          onSuccess={() => {
            setAddAdminModalOpen(false);
            refetch();
          }}
          editingUser={editingUser}
        />
      </Modal>
    </Dashboard>
  );
}
