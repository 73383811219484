import {
  EndpointType,
  IDataProviderEndpointConfig,
} from "@stonex/xrisk-core-common";
import { ExamplePanelS } from "./ExamplePanelS";
import { Is, Schema, a } from "@bluelibs/validator-bundle";

@Schema()
export class ExampleDemoEndpointSFilter {
  @Is(a.object().shape({}))
  filter: {};
}

@Schema()
export class ExampleDemoEndpointSResponse {
  @Is(a.string())
  data: string;
}

export const ExampleDemoEndpointS: IDataProviderEndpointConfig<
  ExampleDemoEndpointSFilter,
  ExampleDemoEndpointSResponse
> = {
  id: "exampleEndpointS",
  label: "Example Endpoint S",
  getFilterSchema() {
    return Schema.from(ExampleDemoEndpointSFilter);
  },
  getResponseSchema() {
    return Schema.from(ExampleDemoEndpointSResponse);
  },
  type: EndpointType.STATIC,
  availablePanels: [ExamplePanelS],
};
