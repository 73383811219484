import { useMutation } from "@apollo/client";
import {
  CREATE_USER,
  UPDATE_USER,
} from "@adminBundles/UIAppBundle/queries/user.query";
import { AdminsUserGetResponse, User } from "@adminRoot/api.types";
import { CommonDataProviderRegistry } from "@stonex/xrisk-core-common";
import {
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Spin,
  notification,
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useContainer } from "@bluelibs/x-ui";

type FieldType = {
  email?: string;
  firstName?: string;
  lastName?: string;
  isEnabled?: boolean;
  businessUnitPermissions?: string[];
};

export const AddAdminForm = forwardRef<
  FormInstance,
  { onSuccess: () => void; editingUser: AdminsUserGetResponse | null }
>((props, ref) => {
  const [form] = Form.useForm();
  const [createUser, { loading: createLoading }] = useMutation(CREATE_USER);
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);
  // @ts-ignore
  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState([]);

  const onFinish = (values: any) => {
    props.editingUser
      ? updateUser({
          variables: {
            input: {
              userId: props.editingUser.userId,
              firstName: values.firstName,
              lastName: values.lastName,
              isEnabled: values.isEnabled,
            },
          },
        })
          .then(() => {
            form.resetFields();
            props.onSuccess();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          })
      : createUser({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              isEnabled: values.isEnabled,
            },
          },
        })
          .then(() => {
            form.resetFields();
            props.onSuccess();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
  };

  useEffect(() => {
    if (props.editingUser) {
      form.setFieldsValue({
        email: props.editingUser.email,
        firstName: props.editingUser.firstName,
        lastName: props.editingUser.lastName,
        isEnabled: props.editingUser.isEnabled,
      });
    } else {
      form.resetFields();
    }
  }, [form, props.editingUser]);

  return (
    <>
      <Form
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        style={{ padding: "24px" }}
        form={form}
        initialValues={{ isEnabled: true }}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.businessUnitPermissions) {
            setSelectedBusinessUnits(changedValues.businessUnitPermissions);
          }
        }}
      >
        <Flex vertical>
          <Form.Item<FieldType>
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter email!" },
              { type: "email", message: "Please enter valid email!" },
            ]}
          >
            <Input
              disabled={props.editingUser ? true : false}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please enter first name!" },
              // {
              //   pattern: /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u,
              //   message: "First name must only include letters and special characters!",
              // },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please enter last name!" },
              // {
              //   pattern: /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u,
              //   message: "First name must only include letters and special characters!",
              // },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Flex>

        <Form.Item<FieldType>
          name="isEnabled"
          valuePropName="checked"
          wrapperCol={{ offset: 8, span: 16 }}
        >
          <Checkbox>Is Enabled?</Checkbox>
        </Form.Item>
      </Form>{" "}
      {(createLoading || updateLoading) && (
        <Col span={24} style={{ textAlign: "center" }}>
          <Spin />
        </Col>
      )}
    </>
  );
});
