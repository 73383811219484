import {
  CopyFilled,
  DeleteFilled,
  EditFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_PANEL,
  DUPLICATE_PANEL,
} from "@adminBundles/UIAppBundle/queries/panels.query";
import { Modal, Popover, notification } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  PANEL_MODAL_TABS,
  addEditPanelModalConfigAtom,
} from "../AddEditPanelModal/AddEditPanelModal";
import { IDockviewPanelHeaderProps } from "dockview";
import {
  allowedPanelsAtom,
  userWorkspacePermissionsAtom,
} from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { getPanelAccessPathString } from "@adminBundles/UIAppBundle/utils/functions";
import { IEditingPanel } from "../AddEditPanelModal/AddEditPanelForm/AddEditPanelForm";

export const PanelHeader = (props: IDockviewPanelHeaderProps) => {
  const [isActivePanel, setIsActivePanel] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [, setAddEditPanelModalConfig] = useAtom(addEditPanelModalConfigAtom);
  const [allowedPanels] = useAtom(allowedPanelsAtom);
  const [userWorkspacePermissions] = useAtom(userWorkspacePermissionsAtom);

  const panelNotAllowed = !allowedPanels.some((panel) => {
    return (
      panel.value ===
      getPanelAccessPathString(props.params.internalPanelAccessPath)
    );
  });

  const [duplicatePanel] = useMutation(DUPLICATE_PANEL);
  const [deletePanel] = useMutation(DELETE_PANEL);

  useEffect(() => {
    props.api.onDidActiveChange((event) => {
      setIsActivePanel(event.isActive);
    });
  }, []);

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this panel?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePanel({
          variables: {
            input: {
              panelId: props.api.id,
            },
          },
        })
          .then(() => {
            props.api.close();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      },
    });
  };

  const panelSettingsMenuItems = [
    {
      key: "1",
      label: "Edit Panel",
      icon: <EditFilled />,
      onClick: () => {
        setAddEditPanelModalConfig({
          open: true,
          activeTab: PANEL_MODAL_TABS.GENERAL,
          editingPanel: {
            params: props.params ?? {},
            title: props.api.title,
            api: props.api,
          } as IEditingPanel,
        });
      },
    },
    {
      key: "2",
      label: "Duplicate Panel",
      icon: <CopyFilled />,
      onClick: () => {
        duplicatePanel({
          variables: {
            input: {
              panelId: props.api.id,
            },
          },
        })
          .then((res) => {
            if (res.data?.PanelDuplicate) {
              props.containerApi.addPanel({
                id: res.data.PanelDuplicate._id,
                title: res.data.PanelDuplicate.name,
                component: "Default",
                params: {
                  description: props.params.description ?? "",
                  internalPanelAccessPath:
                    props.params.internalPanelAccessPath ?? "",
                  filters: props.params.filters ?? {},
                },
              });
            }
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      },
    },
    {
      key: "3",
      label: "Delete Panel",
      icon: <DeleteFilled />,
      onClick: () => showDeleteConfirm(),
    },
  ];

  return (
    <div className="panelHeader" onClick={() => setIsActivePanel(true)}>
      <span>{props.api.title}</span>
      {isActivePanel &&
        userWorkspacePermissions?.editable &&
        !panelNotAllowed && (
          <Popover
            open={isPopoverOpen}
            onOpenChange={(visible) => setIsPopoverOpen(visible)}
            arrow={false}
            placement="bottom"
            content={
              <>
                {panelSettingsMenuItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="panelHeaderSettingsMenuItem"
                      onClick={() => {
                        item.onClick();
                        setIsPopoverOpen(false);
                      }}
                    >
                      <div className="panelHeaderSettingsMenuItemIcon">
                        {item.icon}
                      </div>
                      <div className="panelHeaderSettingsMenuItemLabel">
                        {item.label}
                      </div>
                    </div>
                  );
                })}
              </>
            }
            trigger="click"
          >
            <div className="panelHeaderSettingsButton">
              <SettingOutlined />
            </div>
          </Popover>
        )}
    </div>
  );
};
