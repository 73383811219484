export type BusinessUnitRole = {
  id: string;
  name: string;
};

export type BusinessUnit = {
  id: string;
  name: string;
  alias: string;
  dataProviderIds: string[];
  availableRoles: BusinessUnitRole[];
};

export const businessUnits: BusinessUnit[] = [
  {
    id: "fixed-income",
    name: "Fixed Income",
    alias: "FI",
    dataProviderIds: ["exampleDataProviderB"],
    availableRoles: [
      { id: "risk-manager", name: "Risk Manager" },
      { id: "product-manager", name: "Product Manager" },
    ],
  },

  {
    id: "ces",
    name: "CES",
    alias: "CES",
    dataProviderIds: ["exampleDataProviderB", "exampleDataProviderA"],
    availableRoles: [
      { id: "technical-support-engineer", name: "Technical Support Engineer" },
      { id: "feedback-analyst", name: "Feedback Analyst" },
      { id: "customer-success-manager", name: "Customer Success Manager" },
    ],
  },
  {
    id: "sfl",
    name: "SFL",
    alias: "SFL",
    dataProviderIds: [],
    availableRoles: [
      { id: "financial-analyst", name: "Financial Analyst" },
      { id: "lease-manager", name: "Lease Manager" },
    ],
  },
  {
    id: "sxm",
    name: "SXM",
    alias: "SXM",
    dataProviderIds: [],
    availableRoles: [
      { id: "sales-executive", name: "Sales Executive" },
      { id: "marketing-strategist", name: "Marketing Strategist" },
    ],
  },
  {
    id: "credit",
    name: "Credit",
    alias: "C",
    dataProviderIds: [],
    availableRoles: [
      { id: "credit-analyst", name: "Credit Analyst" },
      { id: "loan-officer", name: "Loan Officer" },
    ],
  },
  {
    id: "broker-dealer-credit",
    name: "Broker Dealer Credit",
    alias: "BDC",
    dataProviderIds: [],
    availableRoles: [
      { id: "broker-dealer", name: "Broker Dealer" },
      { id: "credit-risk-manager", name: "Credit Risk Manager" },
    ],
  },
];

export const getBusinessUnitById = (id: string): BusinessUnit | undefined => {
  return businessUnits.find((bu) => bu.id === id);
};
