import { IPanelConfig } from "@stonex/xrisk-core-common";
import {
  ExampleDemoEndpointBFilter,
  ExampleDemoEndpointBResponse,
} from "./ExampleEndpointB";

export const ExamplePanelB: IPanelConfig<
  ExampleDemoEndpointBFilter,
  ExampleDemoEndpointBResponse
> = {
  id: "examplePanelB",
  label: "Example Panel B",
  description: "This is example panel B",
};
