import { BusinessUnitPermission } from "@adminRoot/api.types";
import {
  Collection,
  CollectionLinkConfig,
  CollectionTransformMap,
} from "@bluelibs/x-ui";

import { UsersCollection } from "..";

export type { BusinessUnitPermission };

export class BusinessUnitPermissionsCollection extends Collection<BusinessUnitPermission> {
  getName() {
    return "BusinessUnitPermissions";
  }

  getInputs() {
    return {
      insert: "BusinessUnitPermissionInsertInput!",
      update: "BusinessUnitPermissionUpdateInput!",
    };
  }

  // Return here the relations with other configs
  getLinks(): CollectionLinkConfig<BusinessUnitPermission>[] {
    return [
      {
        collection: () => UsersCollection,
        name: "user",
        field: "userId",
      },
      {
        collection: () => UsersCollection,
        name: "createdBy",
        field: "createdById",
      },
      {
        collection: () => UsersCollection,
        name: "updatedBy",
        field: "updatedById",
      },
    ];
  }

  // Return here how you want to transform certain fields
  getTransformMap(): CollectionTransformMap<BusinessUnitPermission> {
    return {
      createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
      updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
    };
  }
}
