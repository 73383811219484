import * as React from "react";
import { Flex } from "antd";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export function ExamplePanelComponent2(props: any) {
  const data = {
    labels: props.chartData?.map((d: any) => d.date),
    datasets: [
      {
        label: "StoneX",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: props.chartData?.map((d: any) => d.value),
        fill: false,
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
      {props.chartData?.length > 0 ? (
        <Line data={config.data} options={config.options} />
      ) : (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <h2>Please select a date range to see data</h2>
        </Flex>
      )}
    </div>
  );
}

export function ExamplePanelPreview2() {
  return <div>test</div>;
}
