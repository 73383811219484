import { useMutation } from "@apollo/client";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import {
  CREATE_WORKSPACE,
  UPDATE_WORKSPACE,
} from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import {
  WorkspaceGetResponse,
  WorkspaceVisibility,
} from "@adminRoot/api.types";
import {
  Col,
  Form,
  FormInstance,
  Input,
  Spin,
  Switch,
  notification,
} from "antd";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useWorkspaceMenu } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";

type FieldType = {
  name: string;
  isPublic: boolean;
  description: string;
  ownerUserId: string;
};

export const AddEditWorkspaceForm = forwardRef<
  Partial<FormInstance>,
  {
    onSuccess: () => void;
    editingWorkspace?: WorkspaceGetResponse | null;
    businessUnitId?: string | null;
  }
>((props, ref) => {
  const { state } = useAppGuardian();
  const isUserAdmin = state?.user?.roles.includes("ADMIN");

  const router = useRouter();

  const { refetch: refetchFolders } = useWorkspaceMenu();

  const [form] = Form.useForm();
  const [createWorkspace, { loading: createLoading }] =
    useMutation(CREATE_WORKSPACE);
  const [updateWorkspace, { loading: updateLoading }] =
    useMutation(UPDATE_WORKSPACE);

  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  const onFinish = (values: FieldType) => {
    props.editingWorkspace
      ? updateWorkspace({
          variables: {
            input: {
              workspaceId: props.editingWorkspace._id,
              name: values.name,
              description: values.description,
              visibility: values.isPublic
                ? WorkspaceVisibility.PUBLIC
                : WorkspaceVisibility.PRIVATE,
            },
          },
        })
          .then(() => {
            form.resetFields();
            props.onSuccess();
            refetchFolders();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          })
      : createWorkspace({
          variables: {
            input: {
              internalBusinessUnitId: props.businessUnitId,
              name: values.name,
              description: values.description,
              visibility: values.isPublic
                ? WorkspaceVisibility.PUBLIC
                : WorkspaceVisibility.PRIVATE,
            },
          },
        })
          .then(({ data }) => {
            form.resetFields();
            props.onSuccess();
            if (!isUserAdmin) {
              router.go(Routes.WORKSPACE, {
                params: {
                  id: data?.WorkspaceCreate._id,
                },
              });
            }
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
  };

  useEffect(() => {
    if (props.editingWorkspace) {
      form.setFieldsValue({
        ...props.editingWorkspace,
        ownerUserId: "", // (props.editingWorkspace as AdminsWorkspaceGetResponse)?.ownerUser?._id ?? "",
      });
    } else {
      form.resetFields();
    }
  }, [form, props.editingWorkspace]);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      style={{ padding: "24px" }}
      form={form}
      initialValues={{
        isPublic: false,
      }}
    >
      {(createLoading || updateLoading) && (
        <Col span={24} style={{ textAlign: "center" }}>
          <Spin />
        </Col>
      )}

      <Form.Item<FieldType>
        label="Name"
        name="name"
        rules={[
          { required: true, message: "Please enter name!" },
          {
            min: 2,
            message: "Name must be at least 2 characters long!",
          },
        ]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea autoComplete="off" />
      </Form.Item>

      {!props.editingWorkspace && (
        <Form.Item<FieldType> label="Visibility" name="isPublic">
          <Switch checkedChildren="Public" unCheckedChildren="Private" />
        </Form.Item>
      )}
    </Form>
  );
});
