import { useQuery } from "@apollo/client";
import { useAtom } from "jotai";
import { GET_WORKSPACE_VARIABLES } from "../queries/workspaces.query";
import { WorkspaceVariableGetResponse } from "@adminRoot/api.types";
import { workspaceAtom } from "./useWorkspace";

export const useWorkspaceVariables = (): {
  workspaceVariables: WorkspaceVariableGetResponse[];
  refetch: () => void;
  loading: boolean;
} => {
  const [workspace] = useAtom(workspaceAtom);

  const {
    data: response,
    refetch,
    loading,
  } = useQuery(GET_WORKSPACE_VARIABLES, {
    variables: {
      input: {
        filters: {
          workspaceId: workspace?.data._id,
        },
      },
    },
    skip: !workspace?.data._id,
  });
  const { data: workspaceVariables } = response?.WorkspaceVariableGetAll ?? {};

  return { workspaceVariables, refetch, loading };
};
