import { IDockviewHeaderActionsProps } from "dockview";
import {
  PANEL_MODAL_TABS,
  addEditPanelModalConfigAtom,
} from "../AddEditPanelModal/AddEditPanelModal";
import { useAtom } from "jotai";
import { Tooltip } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { userWorkspacePermissionsAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";

export const PanelWindowActions = (props: IDockviewHeaderActionsProps) => {
  const [, setAddEditPanelModalConfig] = useAtom(addEditPanelModalConfigAtom);
  const [userWorkspacePermissions] = useAtom(userWorkspacePermissionsAtom);

  if (!props.isGroupActive || !userWorkspacePermissions?.editable) return null;

  return (
    <Tooltip
      title="Add Panel To This Window"
      placement="bottom"
      arrow={false}
      mouseEnterDelay={0.5}
    >
      <div
        className="panelGroupActionButton"
        onClick={() => {
          setAddEditPanelModalConfig({
            open: true,
            editingPanel: undefined,
            activeTab: PANEL_MODAL_TABS.GENERAL,
          });
        }}
      >
        <AppstoreAddOutlined width={24} />
      </div>
    </Tooltip>
  );
};
