import { useQuery } from "@apollo/client";
import { GET_WORKSPACE } from "../queries/workspaces.query";
import { WorkspaceGetResponse } from "@adminRoot/api.types";
import { atom, useAtom } from "jotai";
import { useRouter } from "@bluelibs/x-ui";
import { useEffect } from "react";

interface WorkspaceAtom {
  data: WorkspaceGetResponse;
  refetch: () => void;
  loading: boolean;
  error: any;
}

export const workspaceAtom = atom<WorkspaceAtom>(
  null as unknown as WorkspaceAtom,
);

export const useWorkspace = () => {
  const router = useRouter();

  const id = router.history.location.pathname.split("/")[3];
  const [workspace, setWorkspace] = useAtom(workspaceAtom);

  const {
    data: getWorkspaceResponse,
    loading: workspaceLoading,
    refetch: refetchWorkspace,
    error: getWorkspaceError,
  } = useQuery<{
    WorkspaceGet: WorkspaceGetResponse;
  }>(GET_WORKSPACE, {
    variables: {
      input: {
        workspaceId: id,
      },
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (getWorkspaceResponse?.WorkspaceGet) {
      setWorkspace({
        data: getWorkspaceResponse.WorkspaceGet,
        refetch: refetchWorkspace,
        loading: workspaceLoading,
        error: getWorkspaceError,
      });
    }

    return () => {
      setWorkspace(null as any);
    };
  }, [getWorkspaceResponse]);

  return {
    workspace: getWorkspaceResponse?.WorkspaceGet,
    workspaceLoading,
    refetchWorkspace,
    getWorkspaceError,
  };
};
