import { useQuery } from "@apollo/client";
import { GET_BUSINESS_UNIT_MEMBERS } from "../queries/user.query";
import { BusinessUnitUsersGetPaginatedResponse } from "@adminRoot/api.types";

interface props {
  workspaceId?: string | null;
}

export const useBusinessUnitMembers = ({ workspaceId }: props) => {
  const {
    data: response,
    loading,
    refetch,
  } = useQuery<
    {
      BusinessUnitUsersGet: BusinessUnitUsersGetPaginatedResponse | null;
    },
    any
  >(GET_BUSINESS_UNIT_MEMBERS, {
    variables: {
      input: {
        filters: {
          workspaceId: workspaceId,
        },
      },
    },
  });

  const { data } = response?.BusinessUnitUsersGet || {};

  return {
    businessUnitMembers: data,
    loading,
    refetch,
  };
};
