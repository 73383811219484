import { IDockviewPanelProps } from "dockview";
import { useAtom } from "jotai";
import { workspaceVariablesValuesAtom } from "../Sidebar/SidebarVariablesForm/SidebarVariablesForm";
import { useContainer } from "@bluelibs/x-ui";
import { PanelUIRegistry } from "@stonex/xrisk-core-panel-ui";
import { useQuery, useSubscription } from "@apollo/client";
import {
  dateTimeAwareValueToDate,
  getPanelAccessPathString,
} from "@adminBundles/UIAppBundle/utils/functions";
import { allowedPanelsAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { Flex, notification } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useEffect } from "react";
import {
  CommonDataProviderRegistry,
  EndpointType,
} from "@stonex/xrisk-core-common";
import dayjs from "dayjs";
import { isArray } from "lodash";

const DataWrapper = (props: any) => {
  const {
    component: Component,
    type,
    params,
    queryInput,
    panelNotAllowed,
    api,
  } = props;
  const container = useContainer();
  const panelUIRegistry = container.get(PanelUIRegistry);

  const useData =
    type === EndpointType.SUBSCRIPTION ? useSubscription : useQuery;

  const queryConfig = {
    variables: { input: queryInput },
    skip: !Component || !!panelNotAllowed,
  };

  const { data, error } = useData(
    panelUIRegistry.generateQueryForPanel(
      params.internalPanelAccessPath.dataProviderId,
      params.internalPanelAccessPath.endpointId,
      type,
    ),
    queryConfig,
  );

  useEffect(() => {
    if (error) {
      notification.error({
        message: `Error at panel: ${api.title}`,
        description: error?.message,
      });
    }
  }, [error]);

  const dataKey =
    type === EndpointType.SUBSCRIPTION
      ? `Subscription_${params.internalPanelAccessPath.dataProviderId}_${params.internalPanelAccessPath.endpointId}`
      : `${params.internalPanelAccessPath.dataProviderId}_${params.internalPanelAccessPath.endpointId}`;

  return <Component {...data?.[dataKey]} />;
};

export const PanelDefaultComponent = {
  Default: (props: IDockviewPanelProps) => {
    const panelObjectId = props.api.id;
    // Retrieve the allowed panels from the atom
    const [allowedPanels] = useAtom(allowedPanelsAtom);

    // Retrieve the workspace variable values from the atom
    const [workspaceVariablesValues] = useAtom(workspaceVariablesValuesAtom);

    // Retrieve the container and the required registries
    const container = useContainer();
    const panelUIRegistry = container.get(PanelUIRegistry);
    const dataProviderRegistry = container.get(CommonDataProviderRegistry);

    // Extract the data provider ID, endpoint ID, and panel ID from the internal panel access path
    const { dataProviderId, endpointId, panelId } =
      props.params.internalPanelAccessPath;

    // Get the component for the panel from the panel UI registry
    const Component = panelUIRegistry.getComponent(
      dataProviderId,
      endpointId,
      panelId,
    );

    // Get the endpoint from the data provider registry
    const endpoint = dataProviderRegistry.getEndpoint(
      dataProviderId,
      endpointId,
    );

    // Check if the panel is not allowed to be viewed
    const panelNotAllowed = !allowedPanels.some((panel) => {
      return (
        panel.value ===
        getPanelAccessPathString(props.params.internalPanelAccessPath)
      );
    });

    const getWorkspaceVariableValue = (value: any) => {
      return (
        dateTimeAwareValueToDate(workspaceVariablesValues?.[value]) ||
        workspaceVariablesValues?.[value]
      );
    };

    const handleVariableSourceType = (
      data: any,
      key: string,
      queryInput: any,
    ) => {
      if (data.operator) {
        queryInput[key] = JSON.stringify({
          value: getWorkspaceVariableValue(data.value),
          operator: data.operator,
        });
      } else {
        queryInput[key] = getWorkspaceVariableValue(data.value);
      }
    };

    const handleTimeAwareSourceType = (
      data: any,
      key: string,
      queryInput: any,
    ) => {
      if (data.operator) {
        queryInput[key] = JSON.stringify({
          value: dayjs(data.value).isValid()
            ? dayjs(data.value).toDate()
            : dateTimeAwareValueToDate(data.value),
          operator: data.operator,
        });
      }
      // if the value doesn't have an operator, it's a daterange. We're handling date ranges here
      else {
        queryInput[key] = isArray(data.value)
          ? [dayjs(data.value[0]).toDate(), dayjs(data.value[1]).toDate()]
          : dateTimeAwareValueToDate(data.value);
      }
    };

    const handleValueSourceType = (data: any, key: string, queryInput: any) => {
      if (data.operator) {
        queryInput[key] = JSON.stringify({
          value: data.value,
          operator: data.operator,
        });
      } else {
        queryInput[key] = data.value;
      }
    };

    // Main function to prepare the query input object
    const prepareQueryInput = (filters: any) => {
      const queryInput: any = {};

      for (const [key, data] of Object.entries(filters) as any) {
        if (data.isDisabled || !data.value) continue;

        switch (data.sourceType) {
          case "variable":
            handleVariableSourceType(data, key, queryInput);
            break;
          case "timeAware":
            handleTimeAwareSourceType(data, key, queryInput);
            break;
          case "value":
            handleValueSourceType(data, key, queryInput);
            break;
          default:
            break;
        }
      }

      // All query inputs should have the panel ID
      queryInput.panelId = panelObjectId;

      return queryInput;
    };

    const queryInput = prepareQueryInput(props.params?.filters ?? {});

    // If the panel is not allowed, render a warning message
    if (panelNotAllowed) {
      return (
        <Flex
          vertical
          justify="center"
          align="center"
          style={{ height: "100%" }}
        >
          <WarningFilled style={{ fontSize: "50px", color: "#faad14" }} />
          <h3>You are not allowed to view this panel.</h3>
          <hr />
          <span>Please contact your manager</span>
        </Flex>
      );
    }

    // Render the appropriate wrapper component based on the endpoint type
    return (
      <div style={{ padding: "10px", height: "100%", width: "100%" }}>
        {(endpoint.type === EndpointType.SUBSCRIPTION ||
          endpoint.type === EndpointType.QUERY) && (
          <DataWrapper
            component={Component}
            type={endpoint.type}
            params={props.params}
            queryInput={queryInput}
            panelNotAllowed={panelNotAllowed}
            api={props.api}
          />
        )}
        {endpoint.type === EndpointType.STATIC && <Component />}
      </div>
    );
  },
};
