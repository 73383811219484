import { Routes } from "@adminBundles/UIAppBundle";
import { DELETE_WORKSPACE } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { DeleteFilled } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { useRouter } from "@bluelibs/x-ui";
import { Input, Modal, notification } from "antd";
import { useState } from "react";
interface DeleteWorkspaceModalProps {
  deleteWorkspaceModalOpen: boolean;
  setDeleteWorkspaceModalOpen: (open: boolean) => void;
  workspace: any;
}

export function DeleteWorkspaceModal({
  deleteWorkspaceModalOpen,
  workspace,
  setDeleteWorkspaceModalOpen,
}: DeleteWorkspaceModalProps) {
  const router = useRouter();
  const [deleteWorkspace, { loading: deleteLoading }] =
    useMutation(DELETE_WORKSPACE);

  const [deleteWorkspaceName, setDeleteWorkspaceName] = useState("");

  return (
    <Modal
      open={deleteWorkspaceModalOpen}
      onClose={() => setDeleteWorkspaceModalOpen(false)}
      onCancel={() => setDeleteWorkspaceModalOpen(false)}
      title={
        <span>
          Delete Workspace: <b>{workspace?.data.name}</b>
        </span>
      }
      okType="danger"
      onOk={() => {
        deleteWorkspace({
          variables: {
            input: {
              workspaceId: workspace?.data._id,
            },
          },
        })
          .then(() => {
            notification.destroy("deleting");
            notification.success({
              message: "Success",
              description: "Workspace deleted successfully!",
              duration: 1.5,
            });
            router.go(Routes.ME);
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      }}
      okText="Delete Workspace"
      okButtonProps={{
        icon: <DeleteFilled />,
        disabled: deleteWorkspaceName !== workspace?.data.name,
        loading: deleteLoading,
      }}
    >
      <p>
        Please enter the exact name of the workspace to confirm deletion:{" "}
        <b>{workspace?.data.name}</b>
      </p>
      <Input
        autoComplete="off"
        value={deleteWorkspaceName}
        onChange={(e) => setDeleteWorkspaceName(e.target.value)}
        placeholder="Workspace name"
      />
    </Modal>
  );
}
