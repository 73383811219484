import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { Button, Card, Form, Input, notification } from "antd";
import "./AdminLogin.scss";
import { Circle } from "./Shapes/Circle";
import { StrippedCircle } from "./Shapes/StrippedCircle";
import { Triangles } from "./Shapes/Triangles";
import { OktaIcon } from "./Shapes/OktaIcon";
import { useEffect, useState } from "react";
import env from "@adminRoot/startup/env";

export function AdminLogin() {
  const router = useRouter();
  const guardian = useAppGuardian();
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("bluelibs-token");

  const onFinish = (values: any) => {
    setIsLoading(true);
    guardian
      .login(values.username, values.password)
      .then(() => {
        router.go(Routes.ME);
        notification.success({
          message: "Logged in",
          description: "You have successfully logged in!",
        });
      })
      .catch((err) => {
        notification.error({
          message: "Login Failed",
          description: err.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      router.go(Routes.ME);
    }
  }, [token]);

  return (
    <div className="loginBackground">
      <Circle />
      <StrippedCircle />
      <Triangles />

      <div className="adminLoginContent">
        <div className="adminLoginHeader">
          <span>Login</span>
          <span>StoneX</span>
        </div>

        <Button
          type="primary"
          className="oktaLoginButton"
          onClick={() => {
            if (!isLoading) {
              window.location.href = env.API_ROOT_URL + "/auth/okta";
            }
          }}
        >
          <OktaIcon />
          Okta Login
        </Button>
        <Form onFinish={onFinish} layout="vertical" className="loginForm">
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please enter your username!" }]}
          >
            <Input
              variant="borderless"
              style={{ color: "white" }}
              placeholder="Username"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
          >
            <Input
              type="password"
              placeholder="Password"
              variant="borderless"
              style={{ color: "white" }}
              autoComplete="off"
            />
          </Form.Item>

          <Button type="link" htmlType="submit" loading={isLoading}>
            Log in
          </Button>
        </Form>
      </div>
    </div>
  );
}
