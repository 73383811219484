import { ApolloClient } from "@bluelibs/x-ui";
import { Inject } from "@bluelibs/core";
import { Subscription } from "@adminRoot/api.types";

import { WORKSPACES_LIVE_DATA_SUBSCRIPTION } from "../queries/workspaces.query";

export class WorkspaceService {
  @Inject()
  private apolloClient: ApolloClient;

  public subscribe() {
    return this.apolloClient.subscribe<{
      WorkspaceLiveDataSubscription: Subscription["WorkspaceLiveDataSubscription"];
    }>({
      query: WORKSPACES_LIVE_DATA_SUBSCRIPTION,
      variables: {},
    });
  }
}
