import { ConfigProvider, Layout, notification, theme } from "antd";
import "./styles.scss";
import { Sidebar } from "@adminBundles/UIAppBundle/components";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { useEffect } from "react";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { useEventManager, useLiveData, useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import { useAtom } from "jotai";
import { sidebarWidthAtom } from "@adminBundles/UIAppBundle/components/Sidebar/Sidebar";
import { AppColors } from "@adminBundles/UIAppBundle/styles/variables";
import { AddEditWorkspaceModal } from "@adminBundles/UIAppBundle/components/AddEditWorkspaceModal/AddEditWorkspaceModal";
import { useWorkspaceLiveData } from "@adminBundles/UIAppBundle/hooks/useWorkspaceLiveData.hook";
import { ActionType } from "@adminBundles/UIAppBundle/types";
import { WorkspaceLiveDataEvent } from "@adminBundles/UIAppBundle/events";
import { ObjectId } from "@bluelibs/ejson";
import { WorkspaceFoldersCollection } from "@adminBundles/UIAppBundle/collections";

export function Dashboard({ children }: any) {
  const darkTheme = window.localStorage.getItem("theme") === "dark";
  const guardian = useAppGuardian();
  const router = useRouter();
  const token = window.localStorage.getItem("bluelibs-token");

  const [sidebarWidth] = useAtom(sidebarWidthAtom);

  useEffect(() => {
    const errorHandler = (e: any) => {
      const isErrResizeObserver = e.message.includes(
        "ResizeObserver loop completed with undelivered notifications" ||
          "ResizeObserver loop limit exceeded",
      );

      if (isErrResizeObserver) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );

        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  });

  useEffect(() => {
    if (token) {
      guardian.storeToken(token);
      return;
    }

    window.localStorage.setItem("theme", "dark");

    if (!guardian.state.user || !token) {
      router.go(Routes.LOGIN_ADMIN);
    }
  }, [guardian, router, token]);

  useEffect(() => {
    // Set the default notification config
    notification.config({
      placement: "bottomRight",
      bottom: 50,
      duration: 3,
    });

    // attempt at fixing "Cannot Have Two HTML5 Backends At The Same Time". It probably arises because
    // both our folder structure and Dockview uses react-dnd and react-dnd-html5-backend.
    // @ts-ignore
    if (window.__isReactDndBackendSetUp) {
      // @ts-ignore
      window.__isReactDndBackendSetUp = false;
    }
    // @ts-ignore
  }, [window.__isReactDndBackendSetUp]);

  const eventManager = useEventManager();

  /**
   * A general place to hold `useWorkspaceLiveData`
   */
  useWorkspaceLiveData();

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: darkTheme ? AppColors.darkBg : AppColors.lightBg,
          },
        },
        algorithm: darkTheme ? [theme.darkAlgorithm] : theme.defaultAlgorithm,
      }}
    >
      <Layout
        style={{ minHeight: "100vh", color: darkTheme ? "white" : "black" }}
      >
        <DndProvider backend={HTML5Backend} key={1}>
          <Sidebar />
        </DndProvider>
        <Layout.Content
          style={{
            width: `calc(100% - ${sidebarWidth}px)`,
            maxWidth: `calc(100% - ${sidebarWidth}px)`,
            marginLeft: `${sidebarWidth}px`,
          }}
          className={`LayoutContentContainer ${darkTheme ? "dark" : "light"}`}
        >
          {children}
        </Layout.Content>

        <AddEditWorkspaceModal />
      </Layout>
    </ConfigProvider>
  );
}
