import { WorkspaceVariable } from "@adminRoot/api.types";
import {
  Collection,
  CollectionLinkConfig,
  CollectionTransformMap,
} from "@bluelibs/x-ui";

import { UsersCollection, WorkspacesCollection } from "..";

export type { WorkspaceVariable };

export class WorkspaceVariablesCollection extends Collection<WorkspaceVariable> {
  getName() {
    return "WorkspaceVariables";
  }

  getInputs() {
    return {
      insert: "WorkspaceVariableInsertInput!",
      update: "WorkspaceVariableUpdateInput!",
    };
  }

  // Return here the relations with other configs
  getLinks(): CollectionLinkConfig<WorkspaceVariable>[] {
    return [
      {
        collection: () => WorkspacesCollection,
        name: "workspace",
        field: "workspaceId",
      },
      {
        collection: () => UsersCollection,
        name: "createdBy",
        field: "createdById",
      },
      {
        collection: () => UsersCollection,
        name: "updatedBy",
        field: "updatedById",
      },
    ];
  }

  // Return here how you want to transform certain fields
  getTransformMap(): CollectionTransformMap<WorkspaceVariable> {
    return {
      createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
      updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
    };
  }
}
