import { Button, Flex, Layout, Tabs } from "antd";
import { Routes } from "../..";
import {
  AppstoreOutlined,
  BarsOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { useEventManager, useRouter } from "@bluelibs/x-ui";
import { useEffect, useState } from "react";
import { StonexLogo } from "@adminBundles/UIAppBundle/assets/StonexLogo";
import { IconButton } from "../IconButton/IconButton";
import { atom, useAtom } from "jotai";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";
import { SidebarVariablesForm } from "./SidebarVariablesForm/SidebarVariablesForm";
import { addEditWorkspaceModalConfigAtom } from "../AddEditWorkspaceModal/AddEditWorkspaceModal";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { useWorkspaceMenu } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { ActionType } from "@adminBundles/UIAppBundle/types";
import { WorkspaceLiveDataEvent } from "@adminBundles/UIAppBundle/events";

enum SidebarTabs {
  MENU = "menu",
  CONFIG = "config",
  VARIABLES = "variables",
}

export const sidebarWidthAtom = atom(
  Number(localStorage.getItem("sidebarWidth")) || 400,
);
export const sidebarCollapsedAtom = atom(
  window.localStorage.getItem("sidebarCollapsed") === "true" ?? false,
);

export const Sidebar = () => {
  const router = useRouter();
  const darkTheme = window.localStorage.getItem("theme") === "dark";
  const guardian = useAppGuardian();
  const eventManager = useEventManager();

  const { userBusinessUnits } = useWorkspaceMenu();
  const [activeBusinessUnit, setActiveBusinessUnit] = useState<
    string | undefined
  >(userBusinessUnits[0]?.id);

  const [sidebarCollapsed, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom);

  const [sidebarWidth, setSidebarWidth] = useAtom(sidebarWidthAtom);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (sidebarWidth > 400) {
      localStorage.setItem("sidebarWidth", String(sidebarWidth));
    }
  }, [sidebarWidth]);

  useEffect(() => {
    setSidebarWidth(Number(localStorage.getItem("sidebarWidth")));
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem("activeBusinessUnit")) {
      setActiveBusinessUnit(
        window.localStorage.getItem("activeBusinessUnit") ??
          userBusinessUnits[0]?.id,
      );
    }
  }, [userBusinessUnits]);

  const handleMouseDown = (e: any) => {
    setIsResizing(true);
  };

  const handleMouseMove = (e: any) => {
    if (!isResizing || e.clientX < 400) return;
    setSidebarWidth(e.clientX);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  const handleSidebarCollapse = () => {
    setSidebarCollapsed((sidebarCollapsed) => !sidebarCollapsed);
    window.localStorage.setItem("sidebarCollapsed", String(!sidebarCollapsed));
  };

  useEffect(() => {
    if (sidebarCollapsed) {
      setSidebarWidth(90);
    } else {
      setSidebarWidth(Number(localStorage.getItem("sidebarWidth")) || 400);
    }
  }, [sidebarCollapsed]);

  useEffect(() => {
    const handler = async (event: WorkspaceLiveDataEvent) => {
      if (event.data.actionType === ActionType.MODIFIED_BUSINESS_UNIT) {
        if (userBusinessUnits.length === 0) {
          router.go(Routes.ME);
          window.location.reload();
          window.localStorage.removeItem("activeBusinessUnit");
        } else if (
          !userBusinessUnits.some((bu) => bu.id === activeBusinessUnit)
        ) {
          setActiveBusinessUnit(userBusinessUnits[0]?.id);
          window.localStorage.setItem(
            "activeBusinessUnit",
            userBusinessUnits[0]?.id,
          );
        }
      }
    };

    eventManager.addListener(WorkspaceLiveDataEvent, handler);

    return () => {
      eventManager.removeListener(WorkspaceLiveDataEvent as any, handler);
    };
  }, []);

  const handleLogout = async () => {
    window.localStorage.removeItem("bluelibs-token");
    router.go(Routes.HOME);
  };

  const isWorkspacePath =
    router.history.location.pathname.split("/").slice(2, 3).join("") ===
    "workspace";

  let sideBarItems: unknown[] = [];

  if (userBusinessUnits.length > 0) {
    sideBarItems = [
      ...userBusinessUnits
        .sort((a, b) => a.alias.localeCompare(b.alias))
        .map((item) => ({
          label: item.alias,
          key: item.id,
        })),
      ...(guardian.state.user?.roles.includes("ADMIN")
        ? [{ label: "ADM", key: "admin" }]
        : []),
    ];
  }

  return (
    <Layout.Sider
      collapsible
      collapsed={sidebarCollapsed}
      onCollapse={handleSidebarCollapse}
      className={darkTheme ? "sidebar dark" : "sidebar light"}
      collapsedWidth={90}
      width={sidebarWidth}
      trigger={null}
      // fixes the sidebar
    >
      <div className="sidebarContainer">
        <div
          className="sidebarContent"
          style={{
            // visibility: sidebarCollapsed ? "hidden" : "visible",
            height: "100%",
          }}
        >
          <div className="sidebarBusinessUnits">
            <Flex
              vertical
              align="center"
              gap={4}
              style={{
                borderBottom: "1px solid #808080",
              }}
            >
              <StonexLogo size={"small"} />
              <IconButton
                icon={
                  sidebarCollapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )
                }
                onClick={handleSidebarCollapse}
                buttonProps={{
                  size: "large",
                  type: "text",
                }}
              />
            </Flex>
            <Tabs
              defaultActiveKey={userBusinessUnits[0]?.id}
              activeKey={activeBusinessUnit}
              animated={false}
              onChange={(key) => {
                setSidebarCollapsed(false);
                setActiveBusinessUnit(key);
                window.localStorage.setItem("activeBusinessUnit", key);
                router.go(Routes.DASHBOARD);
              }}
              tabPosition="left"
              // @ts-ignore
              items={sideBarItems}
            />
            <Button className="logoutButton" type="text" onClick={handleLogout}>
              <LogoutOutlined />
            </Button>
          </div>

          {!sidebarCollapsed && (
            <div className="sidebarMenu">
              <Tabs
                defaultActiveKey="1"
                type="card"
                items={[
                  {
                    key: "1",
                    label: <BarsOutlined />,
                    children: (
                      <SidebarMenu
                        activeBusinessUnit={
                          (activeBusinessUnit as string) ??
                          userBusinessUnits[0]?.id
                        }
                      />
                    ),
                  },
                  {
                    disabled: !isWorkspacePath,
                    key: "2",
                    label: <AppstoreOutlined />,
                    children: <SidebarVariablesForm />,
                  },
                ]}
              />
            </div>
          )}
        </div>

        {!sidebarCollapsed && (
          <div className="siderHolder" onMouseDown={handleMouseDown}>
            <div className="line" />
          </div>
        )}
      </div>
    </Layout.Sider>
  );
};
