import { Service } from "@bluelibs/core";
import {
  IDataProviderConfig,
  IDataProviderEndpointConfig,
  IPanelAccessPathCombinations,
} from "../defs";
import { getBusinessUnitById } from "../businessUnits";

@Service()
export class CommonDataProviderRegistry {
  private providers: Array<IDataProviderConfig> = [];

  register(provider: IDataProviderConfig) {
    // check if provider exists with the same id
    this.checkNameIsString(provider.id);

    if (this.hasProvider(provider.id)) {
      throw new Error(`DataProvider with id ${provider.id} already exists`);
    }

    // ensure link to the dataProvider parent object.
    provider.endpoints.forEach((endpoint) => {
      endpoint.dataProvider = provider;
    });

    const endpointNames = provider.endpoints.map((e) => e.id);

    // check endpoint names are ok
    endpointNames.forEach((id) => this.checkNameIsString(id));

    // check if provider endpoints have unique names
    if (new Set(endpointNames).size !== endpointNames.length) {
      throw new Error(
        `DataProvider with id ${provider.id} has duplicate endpoint names`,
      );
    }

    this.providers.push(provider);
  }

  getAllEndpoints(): IDataProviderEndpointConfig<any, any>[] {
    return this.providers.flatMap((p) => p.endpoints);
  }

  getEndpoint(dataProviderId: string, endpointId: string) {
    const provider = this.providers.find((p) => p.id === dataProviderId);
    if (!provider) {
      throw new Error(`DataProvider with id ${dataProviderId} not found`);
    }
    const endpoint = provider.endpoints.find((e) => e.id === endpointId);
    if (!endpoint) {
      throw new Error(`Endpoint with id ${endpointId} not found`);
    }
    return endpoint;
  }

  /**
   * @returns the combination of endpoints, panels, and data-providers.
   * @example [
   *    { dataprovider: { id: "dp1", ... }, endpoint: { id: "ep1", ... }, panel: { id: "p1", ... } },
   *    { dataprovider: { id: "dp1", ... }, endpoint: { id: "ep1", ... }, panel: { id: "p2", ... } },
   * ]
   *
   * @input `internalBusinessUnitId`: If not provided, it implies all possible combinations of all business units.
   */
  getPanelAccessPathCombinations(
    internalBusinessUnitId: string | undefined = undefined,
  ) {
    const pathCombinations: IPanelAccessPathCombinations[] = [];

    let currentDataProviders = [...this.providers];

    if (internalBusinessUnitId) {
      const buDataProviderIds =
        getBusinessUnitById(internalBusinessUnitId)?.dataProviderIds ?? [];

      currentDataProviders = currentDataProviders.filter((provider) =>
        buDataProviderIds.includes(provider.id),
      );
    }

    currentDataProviders.forEach((dataprovider) => {
      dataprovider.endpoints.forEach((endpoint) => {
        endpoint.availablePanels.forEach((panel) => {
          const alreadyExists =
            false &&
            pathCombinations.find(
              (comb) =>
                comb.dataprovider.id === dataprovider.id &&
                comb.endpoint.id === endpoint.id &&
                comb.panel.id === panel.id,
            );

          if (!alreadyExists) {
            pathCombinations.push({ dataprovider, endpoint, panel });
          }
        });
      });
    });

    return pathCombinations;
  }

  getProviders() {
    return this.providers;
  }

  getProvider(id: string) {
    return this.providers.find((p) => p.id === id);
  }

  hasProvider(id: string) {
    return this.providers.some((p) => p.id === id);
  }

  checkNameIsString(id: string) {
    if (!id.match(/^[a-zA-Z]+$/)) {
      throw new Error(`Name ${id} must only contain alphanumeric characters`);
    }
  }
}
