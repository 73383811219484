import {
  HolderOutlined,
  LockOutlined,
  PieChartFilled,
  TeamOutlined,
} from "@ant-design/icons";
import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import { Workspace, WorkspaceVisibility } from "@adminRoot/api.types";
import { Typography } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useDrag } from "react-dnd";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { GlobeIcon } from "@adminBundles/UIAppBundle/assets/GlobeIcon";

interface props {
  workspace: Workspace;
}

export const WorkspaceItem = ({ workspace }: props) => {
  const router = useRouter();
  const [activeWorkspace] = useAtom(workspaceAtom);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "workspace",
    item: workspace,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      
      if (item && dropResult) {
        /* empty */
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const handleClick = () => {
    router.go(Routes.WORKSPACE, {
      params: {
        id: workspace._id,
      },
    });
  };

  return (
    <Typography.Text
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={
        activeWorkspace?.data._id === workspace._id
          ? "workspaceListItem active"
          : "workspaceListItem"
      }
      key={workspace._id}
      ref={drag}
      onClick={handleClick}
    >
      <div className="title">
        <PieChartFilled />
        &nbsp; &nbsp;
        {workspace.name}
        &nbsp; &nbsp;
        {workspace.visibility === WorkspaceVisibility.PRIVATE && (
          <LockOutlined style={{ color: "#ffffff87" }} />
        )}
        {workspace.visibility === WorkspaceVisibility.SHARED && (
          <TeamOutlined style={{ color: "#ffffff87" }} />
        )}
        {workspace.visibility === WorkspaceVisibility.PUBLIC && <GlobeIcon />}
      </div>
      <HolderOutlined
        className="holder"
        style={{
          cursor: isDragging ? "grabbing" : "grab",
        }}
      />
    </Typography.Text>
  );
};
