import * as yup from "yup";
import { AnyObject, ArraySchema } from "yup";

import { Is, Schema, a, an } from "@bluelibs/validator-bundle";
import {
  EndpointType,
  IDataProviderEndpointConfig,
} from "@stonex/xrisk-core-common";

import { ExamplePanelA } from "./ExamplePanelA";

@Schema()
export class ExampleDemoEndpointAFilter {
  @Is(an.object().shape({ value: a.date(), operator: a.string() }).required())
  date: { value: Date; operator: string };
  @Is(an.object().shape({ value: a.number(), operator: a.string() }))
  price?: { value: number; operator: string };
  @Is(
    a.string().meta({
      formType: "country",
    }),
  )
  country: string;
}

@Schema()
export class ExampleDemoEndpointAResponse {
  @Is(
    an.array().of(
      an.object().shape({
        stock: a.string(),
        date: a.date(),
        price: a.number(),
        country: a.string(),
      }),
    ),
  )
  columnRowData: {
    stock: string;
    date: Date;
    price: number;
    country: string;
  }[];
}

export const ExampleDemoEndpointA: IDataProviderEndpointConfig<
  ExampleDemoEndpointAFilter,
  ExampleDemoEndpointAResponse
> = {
  id: "exampleEndpointA",
  label: "Example Endpoint A",
  getFilterSchema() {
    return Schema.from(ExampleDemoEndpointAFilter);
  },
  getResponseSchema() {
    return yup
      .array()
      .of(Schema.from(ExampleDemoEndpointAResponse)) as ArraySchema<
      any[],
      AnyObject,
      any,
      any
    >;
  },
  availablePanels: [ExamplePanelA],
  type: EndpointType.QUERY,
};
