// useDropFolderItem.ts
import { useDrop } from "react-dnd";
import { notification } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_WORKSPACE_FOLDER } from "../queries/workspaces.query";
import { Workspace, WorkspaceFolder } from "@adminRoot/api.types";

export const useDropFolderItem = (
  folder: WorkspaceFolder | null,
  refetch: any,
  isRoot = false,
) => {
  const [updateFolder, { loading }] = useMutation(UPDATE_WORKSPACE_FOLDER);

  const [{ isOver, isOverCurrent }, drop] = useDrop(() => ({
    accept: ["workspace", "folder"],
    drop(draggedItem: WorkspaceFolder | Workspace, monitor) {
      const didDropOnChild = monitor.didDrop();
      if (didDropOnChild) {
        return;
      }
      const isItemWorkspace = draggedItem.__typename === "Workspace";
      if (isItemWorkspace) {
        updateFolder({
          variables: {
            input: {
              workspaceFolderId: isRoot ? null : folder?._id,
              parentWorkspaceFolderId: isRoot
                ? null
                : folder?.parentWorkspaceFolderId ?? null,
              workspacesIds: [draggedItem._id],
            },
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      } else if (!isItemWorkspace) {
        updateFolder({
          variables: {
            input: {
              parentWorkspaceFolderId: isRoot ? null : folder?._id,
              workspaceFolderId: draggedItem._id,
            },
          },
        })
          .then(() => {
            refetch();
          })
          .catch((err) => {
            notification.error({
              message: "Error",
              description: err.message,
            });
          });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));

  return { isOver, isOverCurrent, drop, loading };
};
