export const ExamplePanelComponent3 = (props: any) => {
  // generate a static component with various links to famous analytics tools

  return (
    <div>
      <h1>Analytics Tools</h1>
      <ul>
        <li>
          <a href="https://www.google.com/analytics">Google Analytics</a>
        </li>
        <li>
          <a href="https://analytics.google.com/analytics/web/">
            Google Analytics 360
          </a>
        </li>
        <li>
          <a href="https://analytics.google.com/analytics/web/">
            Google AdWords
          </a>
        </li>
        <li>
          <a href="https://analytics.google.com/analytics/web/">
            Google Adsense
          </a>
        </li>
        <li>
          <a href="https://analytics.google.com/analytics/web/">
            Google Search Console
          </a>
        </li>
      </ul>
    </div>
  );
};
