import { useMutation } from "@apollo/client";
import {
  allowedPanelsAtom,
  dockviewApiAtom,
} from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import {
  CREATE_PANEL,
  UPDATE_PANEL,
} from "@adminBundles/UIAppBundle/queries/panels.query";
import { PanelVisualUnitType, PanelGetResponse } from "@adminRoot/api.types";
import {
  Flex,
  Form,
  FormInstance,
  Input,
  Select,
  Spin,
  notification,
} from "antd";
import { DockviewPanelApi, IDockviewPanel } from "dockview";
import { useAtom } from "jotai";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  getPanelAccessPathString,
  getPanelPathFromString,
} from "@adminBundles/UIAppBundle/utils/functions";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { UPDATE_WORKSPACE } from "@adminBundles/UIAppBundle/queries/workspaces.query";

export type IEditingPanel = IDockviewPanel;

export const AddEditPanelForm = forwardRef<
  Partial<FormInstance>,
  {
    onSuccess: () => void;
    editingPanel?: IEditingPanel;
  }
>((props, ref) => {
  const [form] = Form.useForm();
  const [createPanel, { loading: createPanelLoading }] =
    useMutation(CREATE_PANEL);
  const [editPanel, { loading: editPanelLoading }] = useMutation(UPDATE_PANEL);
  const [updateWorkspace, { loading: editWorkspaceLoading }] =
    useMutation(UPDATE_WORKSPACE);

  const [dockViewApi] = useAtom(dockviewApiAtom);
  const [workspace] = useAtom(workspaceAtom);
  const [allowedPanels] = useAtom(allowedPanelsAtom);

  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
    resetFields: () => form.resetFields(),
  }));

  useEffect(() => {
    if (props.editingPanel && allowedPanels.length > 0) {
      form.setFieldsValue({
        title: props.editingPanel.api.title,
        description: props.editingPanel.params?.description,
        panel: getPanelAccessPathString(
          props.editingPanel.params?.internalPanelAccessPath,
        ),
      });
    } else {
      form.resetFields();
    }
  }, [props.editingPanel, allowedPanels]);

  const handleFinish = async (values: any) => {
    if (props.editingPanel) {
      editPanel({
        variables: {
          input: {
            panelId: props.editingPanel.api.id,
            name: values.title,
            visualUnitType: PanelVisualUnitType.CHART,
            workspaceId: workspace.data._id,
            description: values.description,
            internalPanelAccessPath: {
              ...getPanelPathFromString(values.panel),
            },
          },
        },
      })
        .then((res) => {
          if (res.data?.PanelUpdate) {
            props.editingPanel?.api?.updateParameters({
              description: values.description,
              internalPanelAccessPath: {
                ...getPanelPathFromString(values.panel),
              },
            });
            props.editingPanel?.api?.setTitle(values.title);

            updateWorkspace({
              variables: {
                input: {
                  workspaceId: workspace.data._id,
                  gridSettings: dockViewApi.toJSON(),
                },
              },
            }).then(() => {
              workspace.refetch();
            });
          }
          props.onSuccess();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err.message,
          });
        });
    } else {
      createPanel({
        variables: {
          input: {
            workspaceId: workspace.data._id,
            name: values.title,
            description: values.description,
            visualUnitType: PanelVisualUnitType.CHART,
            internalPanelAccessPath: {
              ...getPanelPathFromString(values.panel),
            },
          },
        },
      })
        .then((res) => {
          if (res.data?.PanelCreate) {
            dockViewApi.addPanel({
              id: res.data.PanelCreate._id,
              title: res.data.PanelCreate.name,
              component: "Default",
              params: {
                description: values.description,
                internalPanelAccessPath: {
                  ...getPanelPathFromString(values.panel),
                },
                filters: {},
              },
            });
          }
          props.onSuccess();
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err.message,
          });
        });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      disabled={createPanelLoading || editPanelLoading || editWorkspaceLoading}
      initialValues={{
        remember: true,
      }}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Please enter a title!",
          },
        ]}
      >
        <Input placeholder="Enter title..." autoComplete="off" />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea placeholder="Enter description..." autoComplete="off" />
      </Form.Item>

      <Form.Item
        label="Panel"
        name={"panel"}
        rules={[
          {
            required: true,
            message: "Please select a panel!",
          },
        ]}
      >
        <Select
          placeholder="Select a panel..."
          showSearch
          filterOption={(input, option) =>
            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={allowedPanels}
          optionRender={(option) => (
            <Flex vertical id="panelOption">
              <span>{option.data.label}</span>
              <span style={{ fontSize: "12px", color: "#999" }}>
                {option.data.description}
              </span>
            </Flex>
          )}
          optionLabelProp="label"
        />
      </Form.Item>
      {editPanelLoading || createPanelLoading || editWorkspaceLoading ? (
        <Flex justify="center">
          <Flex vertical>
            <Spin />
          </Flex>
        </Flex>
      ) : null}
    </Form>
  );
});
