import { Bundle } from "@bluelibs/core";
import { CommonDataProviderRegistry } from "@stonex/xrisk-core-common";
import { ExampleDemoEndpointA } from "./ExampleEndpointA";
import { ExampleDemoEndpointB } from "./ExampleEndpointB";
import { ExampleDemoEndpointC } from "./ExampleEndpointC";
import { ExampleDemoEndpointS } from "./ExampleEndpointS";

const DATA_PROVIDER_A_ID = "exampleDataProviderA";
const DATA_PROVIDER_B_ID = "exampleDataProviderB";

export class ExampleCommonBundle extends Bundle {
  override async prepare() {
    const registry = this.container.get(CommonDataProviderRegistry);

    registry.register({
      id: DATA_PROVIDER_A_ID,
      endpoints: [
        ExampleDemoEndpointA,
        ExampleDemoEndpointB,
        ExampleDemoEndpointC,
      ],
      label: "Example DataProvider A",
    });

    registry.register({
      id: DATA_PROVIDER_B_ID,
      endpoints: [
        ExampleDemoEndpointB,
        ExampleDemoEndpointA,
        ExampleDemoEndpointS,
      ],
      label: "Example DataProvider B",
    });
  }
}
