import { Checkbox, Flex, Form, Select } from "antd";
import { Switch } from "react-router-dom";

const mockpanelConfig = [
  {
    id: "1",
    name: "3D Chart",
    type: "boolean",
  },
  {
    id: "3",
    name: "Table",
    type: "boolean",
  },
  {
    id: "2",
    name: "Chart Type",
    type: "dropdown",
    options: ["line", "bar", "pie", "scatter", "bubble"],
  },
];

export const PanelConfig = () => {
  return (
    <Flex vertical gap={"middle"} style={{ padding: "20px" }}>
      {mockpanelConfig.map((config, index) => {
        return (
          <Flex justify="space-between" align="center">
            <span>{config.name}</span>
            {config.type === "boolean" ? (
              <Checkbox />
            ) : (
              <Select
                style={{ minWidth: 150 }}
                options={
                  config.options?.map((option) => {
                    return { label: option, value: option };
                  }) || []
                }
              />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};
