import { PanelVisualUnitType } from "@adminRoot/api.types";
import { COMPARE_OPERATORS, PanelFilter } from "./PanelFilter";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Flex, Form, notification } from "antd";
import { FormInstance } from "antd/lib";
import { useContainer } from "@bluelibs/x-ui";
import {
  CommonDataProviderRegistry,
  EndpointType,
} from "@stonex/xrisk-core-common";
import { useMutation } from "@apollo/client";
import { UPDATE_PANEL } from "@adminBundles/UIAppBundle/queries/panels.query";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { useAtom } from "jotai";
import { dockviewApiAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { UPDATE_WORKSPACE } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { IEditingPanel } from "../AddEditPanelForm/AddEditPanelForm";
import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";

export const PanelFilters = forwardRef<
  Partial<FormInstance>,
  {
    onSuccess: () => void;
    editingPanel: IEditingPanel;
  }
>((props, ref) => {
  const [form] = Form.useForm();
  const [filters, setFilters] = useState<any>([]);

  const container = useContainer();
  const dataProviderRegistry = container.get(CommonDataProviderRegistry);

  const [dockViewApi] = useAtom(dockviewApiAtom);
  const [workspace] = useAtom(workspaceAtom);
  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  const [editPanel] = useMutation(UPDATE_PANEL);

  const { dataProviderId, endpointId } =
    // @ts-expect-error works fine
    props.editingPanel?.api?.panel?.params.internalPanelAccessPath ?? {};

  const endpoint = dataProviderRegistry.getEndpoint(dataProviderId, endpointId);

  useEffect(() => {
    const endpointFilterFields = endpoint.getFilterSchema().fields;

    for (const [key, filter] of Object.entries(endpointFilterFields)) {
      const { value, sourceType, operator, isDisabled } =
        // @ts-expect-error works fine
        props.editingPanel?.api?.panel?._params?.filters?.[key] ?? {};
      setFilters((prevFilters: any) => [
        ...prevFilters,
        {
          label: key,
          dataType:
            // @ts-expect-error works fine
            filter?.type !== "object"
              ? // @ts-expect-error works fine
                filter?.type
              : // @ts-expect-error works fine
                filter?.fields?.value?.type,
          value: value,
          sourceType: sourceType,
          operator: operator
            ? operator
            : // @ts-expect-error if the filter is an object it has to have a comp operator
            filter?.type === "object"
            ? COMPARE_OPERATORS.equals
            : null,
          // @ts-expect-error works fine
          isRequired: filter.spec.presence === "required" ? true : false,
          // @ts-expect-error works fine
          isDisabled: filter.spec.presence === "required" ? false : isDisabled,
          // @ts-expect-error works fine
          formType: filter.spec.meta?.formType ?? null,
        },
      ]);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  const handleFinish = async (values: any) => {
    editPanel({
      variables: {
        input: {
          panelId: props.editingPanel.api.id,
          name: values.title,
          visualUnitType: PanelVisualUnitType.CHART,
          workspaceId: workspace.data._id,
          description: props.editingPanel.params?.description,
          internalPanelAccessPath:
            props.editingPanel.params?.internalPanelAccessPath,
          filters: values,
        },
      },
    })
      .then((res) => {
        if (res.data?.PanelUpdate) {
          props.editingPanel?.api?.updateParameters({
            description: props.editingPanel.params?.description,
            internalPanelAccessPath:
              props.editingPanel.params?.internalPanelAccessPath,
            filters: values,
          });
        }
        props.onSuccess();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  if (endpoint.type === EndpointType.STATIC) {
    return (
      <Flex align="center" justify="center" vertical>
        <h2>This Panel Does Not Have Any Filters</h2>
        <InfoCircleOutlined style={{ fontSize: "50px", color: "#f0ad4e" }} />
      </Flex>
    );
  }

  return (
    <div className="panelFiltersContainer">
      <div className="header">
        <span>Disabled</span>
        <span>Type</span>
        <span>Input Label</span>
        <span>Comparison</span>
        <span>Input</span>
      </div>
      <Form
        className="filters"
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        <div style={{ width: "100%" }}>
          {filters?.map((field: any) => (
            <Form.Item key={field.label} name={field.label}>
              <PanelFilter
                panelId={props.editingPanel.api.id}
                key={field.label}
                filter={field}
                onFilterChange={(
                  value: any,
                  sourceType: "timeAware" | "value" | "variable",
                  isDisabled?: boolean,
                  operator?: COMPARE_OPERATORS,
                ) => {
                  form.setFieldsValue({
                    [field.label]: {
                      value,
                      sourceType,
                      isDisabled,
                      operator,
                    },
                  });
                }}
              />
            </Form.Item>
          ))}
        </div>
      </Form>
    </div>
  );
});
