export const Triangles = () => {
  return (
    <svg
      width="266"
      height="232"
      viewBox="0 0 266 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="adminLoginTriangles"
    >
      <path
        d="M0.429872 0.0251172L207.387 58.123L53.5943 208.304L0.429872 0.0251172Z"
        fill="#0080FF"
        fill-opacity="0.6"
      />
      <path
        d="M77.9882 69.4177L284.946 127.516L131.153 277.697L77.9882 69.4177Z"
        fill="#0080FF"
        fill-opacity="0.6"
      />
      <path
        d="M158.47 137.894L365.428 195.992L211.635 346.174L158.47 137.894Z"
        fill="#0080FF"
        fill-opacity="0.6"
      />
    </svg>
  );
};
