import { gql } from "@apollo/client";

export const GET_ALL_WORKSPACES = gql`
  query WorkspaceGetAll($input: WorkspaceGetAllInput!) {
    WorkspaceGetAll(input: $input) {
      mine {
        businessUnitId
        workspaceFolders {
          _id
          name
          parentWorkspaceFolderId
          workspacesIds
          context
          internalBusinessUnitId
        }
        workspaces {
          _id
          name
        }
      }
      shared {
        businessUnitId
        workspaceFolders {
          _id
          name
          parentWorkspaceFolderId
          workspacesIds
          context
          internalBusinessUnitId
        }
        workspaces {
          _id
          name
        }
      }
    }
  }
`;

export const DUPLICATE_WORKSPACE = gql`
  mutation Mutation($input: WorkspaceDuplicateInput!) {
    WorkspaceDuplicate(input: $input) {
      name
      _id
    }
  }
`;

export const CREATE_WORKSPACE = gql`
  mutation Mutation($input: WorkspaceCreateInput!) {
    WorkspaceCreate(input: $input) {
      name
      _id
    }
  }
`;

export const DELETE_WORKSPACE = gql`
  mutation Mutation($input: WorkspaceRemoveInput!) {
    WorkspaceRemove(input: $input)
  }
`;

export const UPDATE_WORKSPACE = gql`
  mutation Mutation($input: WorkspaceUpdateCustomInput!) {
    WorkspaceUpdate(input: $input) {
      name
    }
  }
`;

export const GET_WORKSPACE_PERMISSIONS = gql`
  query WorkspacePermissionGetAll($input: WorkspacePermissionGetAllInput!) {
    WorkspacePermissionGetAll(input: $input) {
      data {
        editable
        readable
        _id
        user {
          _id
          fullName
        }
        workspace {
          _id
        }
      }
      total
    }
  }
`;

export const GET_USER_WORKSPACE_PERMISSIONS = gql`
  query WorkspacePermissionGetAll($input: WorkspacePermissionGetAllInput!) {
    WorkspacePermissionGetAll(input: $input) {
      data {
        editable
        readable
      }
    }
  }
`;

export const UPDATE_WORKSPACE_PERMISSION = gql`
  mutation Mutation($input: WorkspacePermissionUpdateCustomInput!) {
    WorkspacePermissionUpdate(input: $input) {
      _id
    }
  }
`;

export const CREATE_WORKSPACE_PERMISSION = gql`
  mutation Mutation($input: WorkspacePermissionCreateInput!) {
    WorkspacePermissionCreate(input: $input) {
      _id
    }
  }
`;

export const REMOVE_WORKSPACE_PERMISSION = gql`
  mutation Mutation($input: WorkspacePermissionRemoveInput!) {
    WorkspacePermissionRemove(input: $input)
  }
`;

export const GET_WORKSPACE = gql`
  query WorkspaceGet($input: WorkspaceGetInput!) {
    WorkspaceGet(input: $input) {
      gridSettings
      description
      name
      visibility
      _id
      internalBusinessUnitId
    }
  }
`;

export const CREATE_WORKSPACE_FOLDER = gql`
  mutation Mutation($input: WorkspaceFolderCreateInput!) {
    WorkspaceFolderCreate(input: $input) {
      _id
    }
  }
`;

export const UPDATE_WORKSPACE_FOLDER = gql`
  mutation Mutation($input: WorkspaceFolderUpdateCustomInput!) {
    WorkspaceFolderUpdate(input: $input) {
      _id
    }
  }
`;

export const DELETE_WORKSPACE_FOLDER = gql`
  mutation Mutation($input: WorkspaceFolderRemoveInput!) {
    WorkspaceFolderRemove(input: $input)
  }
`;

export const CREATE_WORKSPACE_VARIABLE = gql`
  mutation Mutation($input: WorkspaceVariableCreateInput!) {
    WorkspaceVariableCreate(input: $input) {
      _id
      color
      defaultValue
      identity
      label
      type
    }
  }
`;

export const REMOVE_WORKSPACE_VARIABLE = gql`
  mutation Mutation($input: WorkspaceVariableRemoveInput!) {
    WorkspaceVariableRemove(input: $input)
  }
`;

export const UPDATE_WORKSPACE_VARIABLE = gql`
  mutation Mutation($input: WorkspaceVariableUpdateCustomInput!) {
    WorkspaceVariableUpdate(input: $input) {
      _id
    }
  }
`;

export const GET_WORKSPACE_VARIABLES = gql`
  query Query($input: WorkspaceVariableGetAllInput!) {
    WorkspaceVariableGetAll(input: $input) {
      data {
        _id
        label
        color
        defaultValue
        identity
        type
      }
    }
  }
`;

export const WORKSPACES_LIVE_DATA_SUBSCRIPTION = gql`
  subscription WorkspaceLiveDataSubscription {
    WorkspaceLiveDataSubscription {
      event
      data
    }
  }
`;

export const GET_USER_LAST_WORKSPACES = gql`
  query WorkspaceList($input: WorkspaceListInput!) {
    WorkspaceList(input: $input) {
      skip
      limit
      total {
        mine
        shared
      }
      data {
        mine {
          _id
          name
          visibility
          createdAt
        }
        shared {
          _id
          name
          visibility
          createdAt
        }
      }
    }
  }
`;
