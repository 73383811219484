import { Modal } from "antd";
import { atom, useAtom } from "jotai";
import { AddEditWorkspaceForm } from "../AddEditWorkspaceForm/AddEditWorkspaceForm";
import { WorkspaceGetResponse } from "@adminRoot/api.types";
import { useEffect, useRef, useState } from "react";
import { WorkspaceModalVariablesTable } from "../WorkspaceModalVariablesTable/WorkspaceModalVariablesTable";
import {
  AddEditVariableForm,
  addEditVariableFormConfigAtom,
} from "../AddEditVariableForm/AddEditVariableForm";
import { EditWorkspacePermissionsForm } from "../EditWorkspacePermissionsForm/EditWorkspacePermissionsForm";
import "./styles.scss";

export interface IAddEditWorkspaceModalProps {
  onSuccess?: (() => void) | null;
  open: boolean;
  editingWorkspace?: WorkspaceGetResponse | null;
  activeTab: WORKSPACE_MODAL_TABS;
  businessUnitId?: string | null;
  onCancel?: (() => void) | null;
  type?: "add" | "edit";
}

export enum WORKSPACE_MODAL_TABS {
  GENERAL = "1",
  VARIABLES = "2",
  SHARING = "3",
}

export const addEditWorkspaceModalConfigAtom = atom({
  open: false,
  editingWorkspace: null,
  onSuccess: null,
  onCancel: null,
  activeTab: false,
  businessUnitId: null,
  type: "edit",
} as unknown as IAddEditWorkspaceModalProps);

export const AddEditWorkspaceModal = () => {
  const [addEditWorkspaceModalConfig, setAddEditWorkspaceModalConfig] = useAtom(
    addEditWorkspaceModalConfigAtom,
  );

  const [addEditVariableFormConfig, setAddEditVariableFormConfig] = useAtom(
    addEditVariableFormConfigAtom,
  );

  const [activeTab, setActiveTab] = useState(
    addEditWorkspaceModalConfig.activeTab,
  );

  useEffect(() => {
    setActiveTab(addEditWorkspaceModalConfig.activeTab);

    return () => {
      setActiveTab(WORKSPACE_MODAL_TABS.GENERAL);
    };
  }, [addEditWorkspaceModalConfig.open]);

  const { onSuccess, editingWorkspace } = addEditWorkspaceModalConfig;

  const formRef = useRef<any>(null);

  const handleOk = () => {
    if (activeTab === WORKSPACE_MODAL_TABS.VARIABLES) {
      if (addEditVariableFormConfig.visible) {
        formRef?.current?.submit();
        return;
      } else {
        setAddEditWorkspaceModalConfig({
          open: false,
          editingWorkspace: null,
          onSuccess: null,
          activeTab: WORKSPACE_MODAL_TABS.GENERAL,
        });
        onSuccess && onSuccess();
      }
    } else if (activeTab === WORKSPACE_MODAL_TABS.GENERAL) {
      formRef?.current?.submit();
    } else {
      setAddEditWorkspaceModalConfig({
        open: false,
        editingWorkspace: null,
        onSuccess: null,
        activeTab: WORKSPACE_MODAL_TABS.GENERAL,
      });

      if (addEditWorkspaceModalConfig.onCancel) {
        addEditWorkspaceModalConfig.onCancel();
      }
    }
  };

  const handleCancel = () => {
    setAddEditWorkspaceModalConfig({
      open: false,
      editingWorkspace: null,
      onSuccess: null,
      onCancel: null,
      activeTab: WORKSPACE_MODAL_TABS.GENERAL,
    });

    if (addEditWorkspaceModalConfig.onCancel) {
      addEditWorkspaceModalConfig.onCancel();
    }
  };

  return (
    <Modal
      open={addEditWorkspaceModalConfig.open}
      onOk={handleOk}
      cancelButtonProps={
        activeTab === WORKSPACE_MODAL_TABS.SHARING
          ? { style: { display: "none" } }
          : {}
      }
      onCancel={handleCancel}
      className="addEditWorkspaceModal"
      width={"fit-content"}
      style={{ minWidth: "600px", maxWidth: "60vw " }}
      destroyOnClose
    >
      <div className="tabs">
        <div
          onClick={() => setActiveTab(WORKSPACE_MODAL_TABS.GENERAL)}
          className={
            activeTab === WORKSPACE_MODAL_TABS.GENERAL ? "tab active" : "tab"
          }
        >
          General
        </div>
        {addEditWorkspaceModalConfig.type !== "add" && (
          <>
            <div
              onClick={() => setActiveTab(WORKSPACE_MODAL_TABS.VARIABLES)}
              className={
                activeTab === WORKSPACE_MODAL_TABS.VARIABLES
                  ? "tab active"
                  : "tab"
              }
            >
              Variables
            </div>
            <div
              onClick={() => setActiveTab(WORKSPACE_MODAL_TABS.SHARING)}
              className={
                activeTab === WORKSPACE_MODAL_TABS.SHARING
                  ? "tab active"
                  : "tab"
              }
            >
              Sharing
            </div>
          </>
        )}
      </div>

      <div className="content">
        {activeTab === WORKSPACE_MODAL_TABS.GENERAL && (
          <AddEditWorkspaceForm
            ref={formRef}
            onSuccess={() => {
              setAddEditWorkspaceModalConfig({
                open: false,
                editingWorkspace: null,
                onSuccess: null,
                activeTab: WORKSPACE_MODAL_TABS.GENERAL,
              });
              onSuccess && onSuccess();
            }}
            editingWorkspace={editingWorkspace}
            businessUnitId={addEditWorkspaceModalConfig.businessUnitId}
          />
        )}
        {activeTab === WORKSPACE_MODAL_TABS.VARIABLES && (
          <>
            <WorkspaceModalVariablesTable />
            <AddEditVariableForm ref={formRef} />
          </>
        )}
        {activeTab === WORKSPACE_MODAL_TABS.SHARING && (
          <EditWorkspacePermissionsForm workspaceId={editingWorkspace?._id} />
        )}
      </div>
    </Modal>
  );
};
