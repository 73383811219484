import { WorkspaceFolder } from "@adminRoot/api.types";
import {
  Collection,
  CollectionLinkConfig,
  CollectionTransformMap,
} from "@bluelibs/x-ui";

import { UsersCollection, WorkspacesCollection } from "..";

export type { WorkspaceFolder };

export class WorkspaceFoldersCollection extends Collection<WorkspaceFolder> {
  getName() {
    return "WorkspaceFolders";
  }

  getInputs() {
    return {
      insert: "WorkspaceFolderInsertInput!",
      update: "WorkspaceFolderUpdateInput!",
    };
  }

  // Return here the relations with other configs
  getLinks(): CollectionLinkConfig<WorkspaceFolder>[] {
    return [
      {
        collection: () => WorkspaceFoldersCollection,
        name: "parentWorkspaceFolder",
        field: "parentWorkspaceFolderId",
      },
      {
        collection: () => UsersCollection,
        name: "user",
        field: "userId",
      },
      {
        collection: () => WorkspacesCollection,
        name: "workspaces",
        many: true,
        field: "workspacesIds",
      },
      {
        collection: () => UsersCollection,
        name: "createdBy",
        field: "createdById",
      },
      {
        collection: () => UsersCollection,
        name: "updatedBy",
        field: "updatedById",
      },
    ];
  }

  // Return here how you want to transform certain fields
  getTransformMap(): CollectionTransformMap<WorkspaceFolder> {
    return {
      createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
      updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
    };
  }
}
