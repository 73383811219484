import { Kernel } from "@bluelibs/core";
import { XUIBundle } from "@bluelibs/x-ui";
import { XUIAdminBundle } from "@bluelibs/x-ui-admin";
import env from "@adminRoot/startup/env";

import { UIAppBundle } from "../bundles/UIAppBundle/UIAppBundle";

import { ExampleCommonBundle } from "@stonex/example-common";
import { ExamplePanelUIBundle } from "@stonex/example-panel-ui";

const apiUrl = env.API_URL;

// All UI bundles need to be prefixed with UI
// All X-Framework bundles have the first prefix X
export const kernel = new Kernel({
  bundles: [
    new XUIBundle({
      graphql: {
        // ApolloClient Options
        // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClientOptions
        uri: apiUrl,
      },
      apollo: {
        client: {
          uri: apiUrl,
        },

        enableSubscriptions: true,
      },
    }),
    new UIAppBundle(),
    new XUIAdminBundle(),

    new ExampleCommonBundle(),
    new ExamplePanelUIBundle(),
  ],
});
