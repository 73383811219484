import { Modal } from "antd";
import {
  AddEditPanelForm,
  IEditingPanel,
} from "./AddEditPanelForm/AddEditPanelForm";
import { atom, useAtom } from "jotai";
import { useRef, useState } from "react";
import { IDockviewPanel } from "dockview";

import "./styles.scss";
import { PanelFilters } from "./PanelFilters/PanelFilters";
import { PanelConfig } from "./PanelConfig/PanelConfig";

export enum PANEL_MODAL_TABS {
  GENERAL = "1",
  FILTERS = "2",
  CONFIG = "3",
}

interface IAddEditPanelModalProps {
  onSuccess?: (() => void) | null;
  open: boolean;
  editingPanel?: IEditingPanel;
  activeTab: PANEL_MODAL_TABS;
}

export const addEditPanelModalConfigAtom = atom<IAddEditPanelModalProps>({
  open: false,
  editingPanel: undefined,
  onSuccess: null,
  activeTab: PANEL_MODAL_TABS.GENERAL,
});

export const AddEditPanelModal = () => {
  const [addEditPanelModalConfig, setAddEditPanelModalConfig] = useAtom(
    addEditPanelModalConfigAtom,
  );

  const setActiveTab = (tab: PANEL_MODAL_TABS) => {
    setAddEditPanelModalConfig({
      ...addEditPanelModalConfig,
      activeTab: tab,
    });
  };

  const { onSuccess, editingPanel, open, activeTab } = addEditPanelModalConfig;

  const formRef = useRef<any>(null);

  const resetModalConfig = () => {
    setAddEditPanelModalConfig({
      open: false,
      editingPanel: undefined,
      onSuccess: null,
      activeTab: PANEL_MODAL_TABS.GENERAL,
    });
  };

  const handleOk = () => {
    formRef?.current?.submit();
    if (activeTab !== PANEL_MODAL_TABS.GENERAL) {
      resetModalConfig();
    }
  };

  const handleCancel = () => {
    resetModalConfig();
  };

  return (
    <Modal
      className="addEditPanelModal"
      // title={editingPanel ? "Edit Panel" : "Add Panel"}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      afterClose={handleCancel}
      width={"max-content"}
      style={{ minWidth: "1000px" }}
      closable={false}
      destroyOnClose
    >
      <div className="tabs">
        <div
          onClick={() => setActiveTab(PANEL_MODAL_TABS.GENERAL)}
          className={
            activeTab === PANEL_MODAL_TABS.GENERAL ? "tab active" : "tab"
          }
        >
          General
        </div>
        {editingPanel && (
          <>
            <div
              onClick={() => setActiveTab(PANEL_MODAL_TABS.FILTERS)}
              className={
                activeTab === PANEL_MODAL_TABS.FILTERS ? "tab active" : "tab"
              }
            >
              Filters
            </div>
            <div
              onClick={() => setActiveTab(PANEL_MODAL_TABS.CONFIG)}
              className={
                activeTab === PANEL_MODAL_TABS.CONFIG ? "tab active" : "tab"
              }
            >
              Config
            </div>
          </>
        )}
      </div>

      {activeTab === PANEL_MODAL_TABS.GENERAL && (
        <AddEditPanelForm
          ref={formRef}
          onSuccess={() => {
            onSuccess?.();
            formRef?.current?.resetFields();
            resetModalConfig();
          }}
          editingPanel={editingPanel}
        />
      )}
      {activeTab === PANEL_MODAL_TABS.FILTERS && editingPanel && (
        <PanelFilters
          ref={formRef}
          editingPanel={editingPanel}
          onSuccess={() => {
            // onSuccess?.();
          }}
        />
      )}
      {activeTab === PANEL_MODAL_TABS.CONFIG && <PanelConfig />}
    </Modal>
  );
};
