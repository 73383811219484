import { AnyObjectSchema } from "yup";
import * as React from "react";
import {
  IDataProviderConfig,
  IDataProviderEndpointConfig,
  IPanelConfig,
} from "@stonex/xrisk-core-common";
import { Constructor } from "@bluelibs/core";
import { PanelUIRegistry } from "./lib";
import { use } from "@bluelibs/x-ui-react-bundle";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { useEffect } from "react";

type UseEndpointResponseType<R> = {
  data: R;
  isLoading: boolean;
  error: any;
};

export interface IPanelConfigExtended extends IPanelConfig<any, any> {
  component: React.ComponentType<any>;
  presentationalComponent: React.ComponentType<any>;
}

export function useEndpoint<F, R>(
  dataProviderId: string,
  endpointId: string,
  panelId: string,
  config: F,
): UseEndpointResponseType<R> {
  // write the logic to fetch the data using ApolloClient via `useQuery()` hook
  const registry = use(PanelUIRegistry);
  // use the registry to fetch the endpoint'name and perform the request and return the response.
  const apolloClient = use(ApolloClient);

  useEffect(() => {
    // fetch the data
    apolloClient.query({
      query: registry.generateQueryForPanel(dataProviderId, endpointId),
      variables: {
        panelId,
        input: config,
      },
    });
  });

  return {
    data: {} as R,
    isLoading: false,
    error: null,
  };
}
