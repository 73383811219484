import { IPanelConfig } from "@stonex/xrisk-core-common";
import {
  ExampleDemoEndpointAFilter,
  ExampleDemoEndpointAResponse,
} from "./ExampleEndpointA";

export const ExamplePanelA: IPanelConfig<
  ExampleDemoEndpointAFilter,
  ExampleDemoEndpointAResponse
> = {
  id: "examplePanelA",
  label: "Example Panel A",
  description: "This is example panel A",
};
