import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  notification,
} from "antd";
import { Dashboard } from "../Dashboard";
import { ColumnsType } from "antd/lib/table";
import { useMutation, useQuery } from "@apollo/client";
import {
  UserRole,
  AdminsBusinessUnitPermissionGetAllInput,
  AdminsBusinessUnitPermissionGetAllPaginatedResponse,
  User,
  AdminsBusinessUnitPermissionGetResponse,
  BusinessUnitPermissionInternalAllowedPanel,
  AdminsBusinessUnitPermissionRemoveInput,
} from "@adminRoot/api.types";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import "./styles.scss";
import { useRef, useState } from "react";
import { AddUserForm } from "@adminBundles/UIAppBundle/components";
import { ADMIN_GET_USERS_BY_BUSINESS_UNIT } from "@adminBundles/UIAppBundle/queries/user.query";
import Header from "@adminBundles/UIAppBundle/components/Header/Header";
import { useRouter } from "@bluelibs/x-ui";
import { businessUnits } from "@stonex/xrisk-core-common";
import { REMOVE_BUSINESS_UNIT_PERMISSION } from "@adminBundles/UIAppBundle/queries/panels.query";

export function TeamPage() {
  const router = useRouter();
  const [editingUser, setEditingUser] = useState<
    | (User & {
        allowedPanels: BusinessUnitPermissionInternalAllowedPanel[];
        businessUnitPermissionId: string;
        hasAccessToAllPanels: boolean;
        businessUnitRoles: string[];
      })
    | null
  >(null);

  const teamName = router.history.location.pathname.split("/")[3];
  const businessUnit = businessUnits.find(
    (bu) => bu.alias.toLowerCase() === teamName.toLowerCase(),
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");

  const formRef = useRef();

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);

  const {
    data: queryResponse,
    loading,
    refetch,
  } = useQuery<
    {
      AdminsBusinessUnitPermissionGetAll: AdminsBusinessUnitPermissionGetAllPaginatedResponse;
    },
    { input: AdminsBusinessUnitPermissionGetAllInput }
  >(ADMIN_GET_USERS_BY_BUSINESS_UNIT, {
    variables: {
      input: {
        options: {
          limit: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
        filters: {
          internalBusinessUnitId: businessUnit?.id ?? "",
          query: searchTerm,
        },
      },
    },
  });

  const { data, total } =
    queryResponse?.AdminsBusinessUnitPermissionGetAll || {};

  const [deleteUserFromBusinessUnit, { loading: deleteUserLoading }] =
    useMutation<
      {
        AdminsBusinessUnitPermissionDelete: AdminsBusinessUnitPermissionGetResponse;
      },
      { input: AdminsBusinessUnitPermissionRemoveInput }
    >(REMOVE_BUSINESS_UNIT_PERMISSION);

  const handleDelete = (businessUnitPermissionId: string) => {
    deleteUserFromBusinessUnit({
      variables: {
        input: {
          businessUnitPermissionId,
        },
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  const columns: ColumnsType<AdminsBusinessUnitPermissionGetResponse> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, { user }) => <span>{user?.fullName}</span>,
      sorter: (a, b) =>
        (a?.user?.fullName || "").localeCompare(b?.user?.fullName || ""),
      width: "25%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "25%",
      render: (_, { user }) => <span>{user?.email}</span>,
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      filters: [
        { text: "Admin", value: UserRole.ADMIN },
        { text: "User", value: UserRole.USER },
      ],
      onFilter: (value: UserRole | any, record) =>
        record.user?.roles.includes(value) as boolean,
      render: (_, { user }) =>
        user?.roles?.map((tag) => <Tag key={tag}>{tag.toUpperCase()}</Tag>),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "isEnabled",
      filters: [
        { text: "Enabled", value: true },
        { text: "Disabled", value: false },
      ],
      onFilter: (value: boolean | any, record) =>
        record.user?.isEnabled === value,
      render: (_, { user }) =>
        user?.isEnabled ? (
          <Tag color="green">Enabled</Tag>
        ) : (
          <Tag color="red">Disabled</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            className="actionButton"
            onClick={() => {
              if (record.user) {
                setEditingUser({
                  ...record.user,
                  allowedPanels: record.internalAllowedPanels,
                  businessUnitPermissionId: record._id,
                  hasAccessToAllPanels: record.hasAccessToAllPanels,
                  businessUnitRoles: record.businessUnitRoles,
                });
                setAddUserModalOpen(true);
              }
            }}
          >
            <EditFilled />
          </Button>
          <Popconfirm
            title="Delete User"
            description="Are you sure you want to remove this user from this business unit?"
            onConfirm={() => handleDelete(record?._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger ghost className="actionButton">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Dashboard>
      <Header
        title={teamName === "admins" ? "Admins" : businessUnit?.name || ""}
      />

      <div className="teamPageLayout">
        <div className="teamTableHeader">
          <Button
            onClick={() => {
              setEditingUser(null);
              setAddUserModalOpen(true);
            }}
            className="addUserButton"
            type="primary"
          >
            + Add New
          </Button>
          <Input.Search
            placeholder="Search User"
            onChange={(e) => setSearchTerm(e.target.value)}
            autoComplete="off"
          />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading || deleteUserLoading}
          pagination={{
            total: total,
            pageSize,
            onChange: (page) => {
              setCurrentPage(page);
            },
            onShowSizeChange: (_, size) => {
              setPageSize(size);
            },
            current: currentPage,
            pageSizeOptions: ["4", "8", "12"],
            showSizeChanger: true,
            defaultPageSize: 8,
          }}
          size="middle"
        />
      </div>
      <Modal
        open={addUserModalOpen}
        // @ts-ignore
        onOk={() => formRef?.current && formRef?.current?.submit()}
        onCancel={() => {
          setEditingUser(null);
          setAddUserModalOpen(false);
        }}
        destroyOnClose
        title={editingUser ? "Edit User" : "Add New User"}
        width={1200}
        style={{
          top: 20,
        }}
      >
        <AddUserForm
          ref={formRef as any}
          onSuccess={() => {
            setAddUserModalOpen(false);
            refetch();
          }}
          editingUser={editingUser}
          businessUnit={businessUnit!}
          teamMembers={data ?? []}
        />
      </Modal>
    </Dashboard>
  );
}
