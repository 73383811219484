import { AdminsUserGetResponse } from "@adminRoot/api.types";
import dayjs from "dayjs";
import {
  DateRangeTimeAwareType,
  DateTimeAwareType,
} from "../components/Sidebar/SidebarVariablesForm/SidebarVariablesForm";

export function decodeUrlEncodedString(urlEncodedString: string): string {
  return decodeURIComponent(urlEncodedString.replace(/\+/g, " "));
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const usersToOptions = (users: AdminsUserGetResponse[]) =>
  users?.map(({ firstName, lastName, userId }) => ({
    label: `${firstName} ${lastName}`,
    value: userId,
  }));

export const filterOption = (
  input: string,
  option?: { label: string; value: string },
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const getPanelAccessPathString = (path: any) => {
  return `${path.dataprovider?.id ?? path.dataProviderId}.${
    path.endpoint?.id ?? path.endpointId
  }.${path.panel?.id ?? path.panelId}`;
};

export const getPanelPathFromString = (path: string) => {
  const [dataProviderId, endpointId, panelId] = path.split(".");
  return {
    dataProviderId,
    endpointId,
    panelId,
  };
};

export const debounce = (fn: any, delay: number) => {
  let timer: any;
  return function (...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const dateTimeAwareValueToDate = (
  value: DateTimeAwareType | DateRangeTimeAwareType,
) => {
  switch (value) {
    case DateTimeAwareType.TODAY:
      return dayjs().startOf("day").toDate();
    case DateTimeAwareType.FIRST_DAY_OF_MONTH:
      return dayjs().startOf("month").toDate();
    case DateTimeAwareType.LAST_DAY_OF_MONTH:
      return dayjs().endOf("month").toDate();
    case DateRangeTimeAwareType.THIS_WEEK:
      return [dayjs().startOf("week").toDate(), dayjs().endOf("week").toDate()];
    case DateRangeTimeAwareType.THIS_MONTH:
      return [
        dayjs().startOf("month").toDate(),
        dayjs().endOf("month").toDate(),
      ];
    case DateRangeTimeAwareType.LAST_YEAR:
      return [
        dayjs().subtract(1, "year").startOf("year").toDate(),
        dayjs().subtract(1, "year").endOf("year").toDate(),
      ];
    default:
      return undefined;
  }
};

export const snakeCaseToText = (text: string) => {
  return text
    .split("_")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};

export const camelCaseToText = (text: string) => {
  return text
    .split(/(?=[A-Z])/)
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};
