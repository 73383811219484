import { useQuery } from "@apollo/client";
import { GET_ALL_WORKSPACES } from "../queries/workspaces.query";
import {
  BusinessUnitStructure,
  Workspace,
  WorkspaceFolder,
  WorkspaceFolderContext,
  WorkspaceGetAllResponse,
} from "@adminRoot/api.types";
import { useEffect, useState } from "react";
import { BusinessUnit, businessUnits } from "@stonex/xrisk-core-common";

export type ExtendedWorkspaceFolder = WorkspaceFolder & {
  subFolders: WorkspaceFolder[];
};

export const useWorkspaceMenu = () => {
  const [userBusinessUnits, setUserBusinessUnits] = useState<BusinessUnit[]>(
    [],
  );
  const [folderStructure, setFolderStructure] = useState<
    Record<string, Record<string, Array<Workspace | ExtendedWorkspaceFolder>>>
  >({});
  const [allFolders, setAllFolders] = useState<WorkspaceFolder[]>([]);

  const {
    data: res,
    loading,
    refetch,
    error,
  } = useQuery<any>(GET_ALL_WORKSPACES, {
    variables: {
      input: {
        query: "",
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const data = res?.WorkspaceGetAll as WorkspaceGetAllResponse;

  useEffect(() => {
    // find and extract business units from the user's workspace permissions
    if (data) {
      setUserBusinessUnits(
        data.mine.map((bu) => {
          return (
            businessUnits.find((b) => b.id === bu.businessUnitId) ??
            ({} as BusinessUnit)
          );
        }),
      );

      const myFolders = data.mine.reduce(
        (acc, bu) => [...acc, ...bu.workspaceFolders],
        [] as WorkspaceFolder[],
      );

      const sharedFolders = data.shared.reduce(
        (acc, bu) => [...acc, ...bu.workspaceFolders],
        [] as WorkspaceFolder[],
      );

      const allFolders = [...myFolders, ...sharedFolders];

      setAllFolders(allFolders);
    }

    // extract folder structure

    function createFolderTree(
      folders: WorkspaceFolder[],
      bu: BusinessUnitStructure,
    ): Array<ExtendedWorkspaceFolder | Workspace> {
      const folderMap: { [key: string]: any } = {};
      const rootFolders: Array<ExtendedWorkspaceFolder | Workspace> = [];

      // Initialize the folder map
      folders.forEach((folder) => {
        folderMap[folder._id] = {
          ...folder,
          workspaces: folder.workspacesIds.map((id) => {
            return { ...bu.workspaces.find((w) => w._id === id) };
          }),
        };
      });

      // Build the tree
      folders.forEach((folder) => {
        if (folder.parentWorkspaceFolderId) {
          const parent = folderMap[folder.parentWorkspaceFolderId];
          if (parent) {
            parent.subFolders
              ? parent.subFolders.push(folderMap[folder._id])
              : (parent.subFolders = [folderMap[folder._id]]);
          }
        } else {
          rootFolders.push(folderMap[folder._id]);
        }
      });

      // push workspaces without a folder to the root. find workspaces that are not in any folder by filtering workspaces that's not included in any folder
      const workspacesNotInFolder = bu.workspaces.filter(
        (w) => !folders.some((f) => f.workspacesIds.includes(w._id)),
      );

      if (workspacesNotInFolder.length) {
        rootFolders.push(...workspacesNotInFolder);
      }

      return rootFolders;
    }

    if (data) {
      data.mine.forEach((bu) => {
        setFolderStructure((prev: any) => ({
          ...prev,
          [bu.businessUnitId]: {
            ...prev[bu.businessUnitId],
            [WorkspaceFolderContext.MINE]: createFolderTree(
              bu.workspaceFolders,
              bu,
            ),
          },
        }));
      });

      data.shared.forEach((bu) => {
        setFolderStructure((prev: any) => ({
          ...prev,
          [bu.businessUnitId]: {
            ...prev[bu.businessUnitId],
            [WorkspaceFolderContext.SHARED]: createFolderTree(
              bu.workspaceFolders,
              bu,
            ),
          },
        }));
      });
    }
  }, [data]);

  return {
    userBusinessUnits,
    data,
    folderStructure,
    loading,
    refetch,
    allFolders,
    error,
  };
};
