import "./styles.scss";
import {
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
} from "antd";
import {
  WorkspaceVariableGetResponse,
  WorkspaceVariableType,
} from "@adminRoot/api.types";
import dayjs from "dayjs";
import { useWorkspaceVariables } from "@adminBundles/UIAppBundle/hooks/useWorkspaceVariables";
import { atom, useAtom } from "jotai";
import {
  dateTimeAwareValueToDate,
  debounce,
} from "@adminBundles/UIAppBundle/utils/functions";
import { useEffect, useState } from "react";
import { CustomPanelFilter } from "../../AddEditPanelModal/PanelFilters/CustomPanelFilter";
import { isArray, set } from "lodash";

export enum DateTimeAwareType {
  TODAY = "Today",
  FIRST_DAY_OF_MONTH = "First Day of Month",
  LAST_DAY_OF_MONTH = "Last Day of Month",
}

export enum DateRangeTimeAwareType {
  THIS_WEEK = "This Week",
  THIS_MONTH = "This Month",
  LAST_YEAR = "Last Year",
}

export const workspaceVariablesValuesAtom = atom<Record<string, any>>({});

export const SidebarVariablesForm = () => {
  const { RangePicker } = DatePicker;

  const [openTimeSelects, setOpenTimeSelects] = useState<string[]>([]);

  const { workspaceVariables, loading } = useWorkspaceVariables();

  const [workspaceVariablesValues, setWorkspaceVariablesValues] = useAtom(
    workspaceVariablesValuesAtom,
  );

  // set default values for workspace variables
  useEffect(() => {
    if (workspaceVariables) {
      const defaultValues: Record<string, any> = {};
      workspaceVariables.forEach((variable) => {
        defaultValues[variable.identity] = variable.defaultValue;
      });
      setWorkspaceVariablesValues(defaultValues);
    }
  }, [workspaceVariables]);

  const toggleTimeSelect = (variableId: string) => {
    if (openTimeSelects.includes(variableId)) {
      setOpenTimeSelects(openTimeSelects.filter((id) => id !== variableId));
    } else {
      setOpenTimeSelects([...openTimeSelects, variableId]);
    }
  };

  const renderInput = (variable: WorkspaceVariableGetResponse) => {
    const handleOnChange = debounce((value: any) => {
      setWorkspaceVariablesValues((prev) => ({
        ...prev,
        [variable.identity]: value,
      }));
    }, 500);

    const value = workspaceVariablesValues[variable.identity];

    switch (variable.type) {
      case WorkspaceVariableType.DATE_TIME_AWARE:
        return (
          <Select
            style={{ width: "100%" }}
            open={openTimeSelects.includes(variable.identity)}
            onClick={(e) => {
              // @ts-ignore
              if (
                // @ts-ignore
                e.target.className === "ant-select-selection-item" ||
                // @ts-ignore
                e.target.className === "ant-select-selection-search-input"
              ) {
                toggleTimeSelect(variable.identity);
              }
            }}
            value={
              dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : value
            }
            onChange={(value) => {
              handleOnChange(value);
              setOpenTimeSelects(
                openTimeSelects.filter((id) => id !== variable.identity),
              );
            }}
            placeholder={"Select a date"}
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />

                  <DatePicker
                    allowClear={false}
                    value={dayjs(value).isValid() ? dayjs(value) : null}
                    onChange={(value) => {
                      handleOnChange(value);
                      setOpenTimeSelects(
                        openTimeSelects.filter(
                          (id) => id !== variable.identity,
                        ),
                      );
                    }}
                  />
                </>
              );
            }}
          >
            {Object.values(DateTimeAwareType).map((value) => (
              <Select.Option value={value} key={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        );
      case WorkspaceVariableType.NUMBER:
        return (
          <InputNumber
            value={value}
            placeholder="Enter value..."
            onChange={(value) => handleOnChange(value)}
          />
        );
      case WorkspaceVariableType.STRING:
        return (
          <Input
            value={value}
            placeholder="Enter value..."
            onChange={(e) => handleOnChange(e.target.value)}
            autoComplete="off"
          />
        );
      case WorkspaceVariableType.DATE_RANGE_TIME_AWARE:
        return (
          <Select
            style={{ width: "100%" }}
            open={openTimeSelects.includes(variable.identity)}
            onClick={(e) => {
              if (
                // @ts-ignore
                e.target.className === "ant-select-selection-item" ||
                // @ts-ignore
                e.target.className === "ant-select-selection-search-input"
              ) {
                toggleTimeSelect(variable.identity);
              }
            }}
            value={
              isArray(value)
                ? dayjs(value[0]).format("YYYY-MM-DD") +
                  " - " +
                  dayjs(value[1]).format("YYYY-MM-DD")
                : value
            }
            onChange={(value) => {
              handleOnChange(value);
              setOpenTimeSelects(
                openTimeSelects.filter((id) => id !== variable.identity),
              );
            }}
            placeholder={"Select a date"}
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />

                  <DatePicker.RangePicker
                    allowClear={false}
                    onChange={(value) => {
                      setOpenTimeSelects(
                        openTimeSelects.filter(
                          (id) => id !== variable.identity,
                        ),
                      );
                      handleOnChange(value);
                    }}
                  />
                </>
              );
            }}
          >
            {Object.values(DateRangeTimeAwareType).map((value) => (
              <Select.Option value={value} key={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        );
      case WorkspaceVariableType.BOOLEAN:
        return (
          <Switch
            defaultValue={value}
            value={value}
            onChange={(value) => handleOnChange(value)}
          />
        );
      default:
        return (
          <CustomPanelFilter
            filterDisabled={false}
            filterValue={value}
            formType={variable.type}
            onChange={(value) => handleOnChange(value)}
          />
        );
    }
  };

  return (
    <div className="variablesListContainer">
      <span>Value Of Variables</span>
      <div className="variablesList">
        {workspaceVariables?.length === 0 && (
          <div className="noVariables">No Workspace Variables</div>
        )}
        {loading && <Spin />}
        {workspaceVariables?.map((variable) => (
          <div key={variable.identity} className="variableItem">
            <div className="variableLabel">
              <div
                className="dot"
                style={{
                  backgroundColor: variable.color,
                }}
              />
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {variable.label}
              </span>
            </div>
            <div className="variableInput">{renderInput(variable)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
