import { Button, ButtonProps, Flex, Tooltip } from "antd";

interface props {
  tooltipTitle?: string;
  buttonProps?: ButtonProps;
  onClick: (e: any) => void;
  icon: React.ReactNode;
  size?: number;
}

export const IconButton = ({
  onClick,
  icon,
  tooltipTitle,
  buttonProps,
  size,
}: props) => {
  return (
    <Tooltip title={tooltipTitle}>
      <Button
        className="actionButton"
        onClick={onClick}
        {...buttonProps}
        style={{
          fontSize: size ? size : 20,
        }}
      >
        <Flex align="center" justify="center">
          {icon}
        </Flex>
      </Button>
    </Tooltip>
  );
};
