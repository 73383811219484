import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { Button, Row, Spin, notification } from "antd";
import { DockviewApi, DockviewReact, DockviewReadyEvent } from "dockview";

import {
  useContainer,
  useEventManager,
  useGuardian,
  useRouter,
} from "@bluelibs/x-ui";

import Header from "@adminBundles/UIAppBundle/components/Header/Header";
import { useMutation, useQuery } from "@apollo/client";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { PanelHeader } from "@adminBundles/UIAppBundle/components/PanelHeader/PanelHeader";
import { PanelWindowActions } from "@adminBundles/UIAppBundle/components/PanelWindowActions/PanelWindowActions";
import { PanelDefaultComponent } from "@adminBundles/UIAppBundle/components/PanelDefaultComponent/PanelDefaultComponent";
import { useWorkspace } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { GET_USER_ALLOWED_PANELS } from "@adminBundles/UIAppBundle/queries/user.query";
import { PanelUIRegistry } from "@stonex/xrisk-core-panel-ui";
import { getPanelAccessPathString } from "@adminBundles/UIAppBundle/utils/functions";
import { Routes } from "@adminBundles/UIAppBundle";
import { CommonDataProviderRegistry } from "@stonex/xrisk-core-common";
import { WorkspaceLiveDataEvent } from "@adminBundles/UIAppBundle/events/WorkspaceLiveData.event";
import { ActionType } from "@adminBundles/UIAppBundle/types";
import {
  GET_USER_WORKSPACE_PERMISSIONS,
  UPDATE_WORKSPACE,
} from "@adminBundles/UIAppBundle/queries/workspaces.query";
import {
  AddEditPanelModal,
  PANEL_MODAL_TABS,
  addEditPanelModalConfigAtom,
} from "@adminBundles/UIAppBundle/components/AddEditPanelModal/AddEditPanelModal";

import { Dashboard } from "../Dashboard";
import "./styles.scss";

export const dockviewApiAtom = atom<DockviewApi>({} as DockviewApi);
export const allowedPanelsAtom = atom<Record<any, any>[]>([]);
export const allowedDataProvidersAtom = atom<Record<any, any>[]>([]);
export const userWorkspacePermissionsAtom = atom<{
  editable: boolean;
  readable: boolean;
}>({
  editable: false,
  readable: false,
});

export function Workspace() {
  const router = useRouter();
  const guardian = useGuardian();
  const workspaceId = router.history.location.pathname.split("/")[3];

  const darkTheme = window.localStorage.getItem("theme") === "dark";

  const [dockviewApi, setDockviewApi] = useAtom(dockviewApiAtom);
  const [, setAddEditPanelModalConfig] = useAtom(addEditPanelModalConfigAtom);
  const [, setAllowedPanels] = useAtom(allowedPanelsAtom);
  const [, setAllowedDataProviders] = useAtom(allowedDataProvidersAtom);
  const [userWorkspacePermissions, setUserWorkspacePermissions] = useAtom(
    userWorkspacePermissionsAtom,
  );

  const { workspace, refetchWorkspace, workspaceLoading, getWorkspaceError } =
    useWorkspace();

  const container = useContainer();
  const panelUIRegistry = container.get(PanelUIRegistry);
  const dataProviderRegistry = container.get(CommonDataProviderRegistry);

  useEffect(() => {
    refetchWorkspace();
  }, [workspaceId]);

  const eventManager = useEventManager();

  useEffect(() => {
    const handler = async (event: WorkspaceLiveDataEvent) => {
      const triggersToRefetch = [
        ActionType.MODIFIED_BUSINESS_UNIT,
        ActionType.MODIFIED_WORKSPACE,
        ActionType.MODIFIED_PANEL,
      ];

      if (triggersToRefetch.includes(event.data.actionType)) {
        refetchWorkspace();
      }
    };

    eventManager.addListener(WorkspaceLiveDataEvent, handler);

    return () => {
      eventManager.removeListener(WorkspaceLiveDataEvent as any, handler);
    };
  }, []);

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  useEffect(() => {
    if (getWorkspaceError) {
      router.go(Routes.ME);
      notification.error({
        message: "Error",
        description: getWorkspaceError.message,
      });
    }
  }, [getWorkspaceError]);

  const onReady = (event: DockviewReadyEvent) => {
    if (Object.keys(workspace?.gridSettings).length > 0) {
      try {
        const layout = workspace?.gridSettings;
        event.api.fromJSON(layout);
      } catch (err) {
        console.error(err);
        // notification.error({
        //   message: "Error",
        //   description: "Error loading the layout",
        // });
      }
    }

    setDockviewApi(event.api);
  };

  useEffect(() => {
    if (Object.keys(dockviewApi).length === 0) return;
    dockviewApi?.onDidLayoutChange(() => {
      if (userWorkspacePermissions?.editable === false) return;
      updateWorkspace({
        variables: {
          input: {
            workspaceId: workspaceId,
            gridSettings: dockviewApi.toJSON(),
          },
        },
      })
        .then(() => {
          refetchWorkspace();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, [dockviewApi, userWorkspacePermissions?.editable]);

  // Dockview shows an ugly black blank screen if there are no panels, so we add a group to make it look better
  useEffect(() => {
    if (dockviewApi.totalPanels === 0 && dockviewApi.groups.length === 0) {
      dockviewApi.addGroup();
    }
  }, [dockviewApi.totalPanels, dockviewApi.groups?.length]);

  const { data: allowedPanelsResponse, refetch: refetchAllowedPanels } =
    useQuery(GET_USER_ALLOWED_PANELS, {
      variables: {
        input: {
          workspaceId: workspaceId,
        },
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    refetchAllowedPanels();
  }, [workspaceId]);

  const { data: allowedPanelsData } =
    allowedPanelsResponse?.BusinessUnitInternalAllowedPanelsGet || {};

  useEffect(() => {
    allowedPanelsData?.forEach((path: any) => {
      let panel: any;
      try {
        panel = panelUIRegistry.findPanel(
          path.path.dataProviderId,
          path.path.endpointId,
          path.path.panelId,
        );
      } catch (e) {
        console.error(e);
      }

      setAllowedPanels((prev) => [
        ...prev,
        {
          value: getPanelAccessPathString(path.path),
          label: panel?.label,
          description: panel?.description,
        },
      ]);

      setAllowedDataProviders((prev) => {
        const dataProviderId = path.path.dataProviderId;
        const isDataProviderAlreadyAdded = prev.some(
          (provider) => provider.value === dataProviderId,
        );

        const dataProvider = dataProviderRegistry
          .getProviders()
          .find((provider: any) => provider.id === dataProviderId);

        if (isDataProviderAlreadyAdded) {
          return prev;
        }

        const uniqueDataProvider = {
          value: dataProviderId,
          label: dataProvider?.label,
          endpoints: dataProvider?.endpoints,
          description: dataProvider?.description,
        };

        return [...prev, uniqueDataProvider];
      });
    });

    return () => {
      setAllowedPanels([]);
      setAllowedDataProviders([]);
    };
  }, [allowedPanelsResponse]);

  const {
    data: userWorkspacePermissionsResponse,
    loading: userWorkspacePermissionsLoading,
  } = useQuery(GET_USER_WORKSPACE_PERMISSIONS, {
    variables: {
      input: {
        filters: {
          workspaceId: workspaceId,
          userId: guardian.state.user?._id,
        },
      },
    },
    skip: !guardian.state.user?._id,
  });

  const { data: userWorkspacePermissionsData } =
    userWorkspacePermissionsResponse?.WorkspacePermissionGetAll ?? {};

  useEffect(() => {
    if (userWorkspacePermissionsData) {
      setUserWorkspacePermissions(userWorkspacePermissionsData[0]);
    }

    return () => {
      setUserWorkspacePermissions({
        editable: false,
        readable: false,
      });
    };
  }, [userWorkspacePermissionsData]);

  return (
    <Dashboard>
      {workspaceLoading || userWorkspacePermissionsLoading ? (
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          {/*  */}
          <AddEditPanelModal />
          {/*  */}

          <Header title={workspace?.name} />
          <div className="workspaceContainer" key={JSON.stringify(workspace)}>
            {workspace && userWorkspacePermissionsResponse && (
              <DockviewReact
                components={PanelDefaultComponent}
                onReady={onReady}
                defaultTabComponent={(props) => <PanelHeader {...props} />}
                rightHeaderActionsComponent={PanelWindowActions}
                className={
                  darkTheme ? "dockview-theme-dark" : "dockview-theme-light"
                }
              />
            )}
            {dockviewApi.totalPanels === 0 && (
              <Button
                className="addFirstPanelButton"
                size="large"
                type="dashed"
                onClick={() => {
                  setAddEditPanelModalConfig({
                    open: true,
                    editingPanel: undefined,
                    activeTab: PANEL_MODAL_TABS.GENERAL,
                    onSuccess: () => {
                      refetchWorkspace();
                    },
                  });
                }}
              >
                <AppstoreAddOutlined />
                Add Your First Panel
              </Button>
            )}
          </div>
        </>
      )}
    </Dashboard>
  );
}
