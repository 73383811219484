import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { useWorkspacePermissions } from "@adminBundles/UIAppBundle/hooks/useWorkspacePermissions";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
} from "antd";
import { FormInstance } from "antd/lib";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { filterOption } from "@adminBundles/UIAppBundle/utils/functions";
import { useBusinessUnitMembers } from "@adminBundles/UIAppBundle/hooks/useBusinessUnitMembers";
import {
  WorkspaceGetResponse,
  WorkspaceVisibility,
} from "@adminRoot/api.types";
import { useMutation } from "@apollo/client";
import { UPDATE_WORKSPACE } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { useAtom } from "jotai";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";

export const EditWorkspacePermissionsForm = forwardRef<
  FormInstance,
  {
    onSuccess?: (() => void) | null;
    workspaceId: string | null;
  }
>(({ workspaceId }, ref) => {
  const [form] = Form.useForm();

  const [workspace] = useAtom(workspaceAtom);

  const editingWorkspace = workspace.data;

  const [currentPage, setCurrentPage] = useState(1);

  const {
    createWorkspacePermission,
    loading: permissionsLoading,
    deleteWorkspacePermission,
    updateWorkspacePermission,
    permissions,
    refetch: refetchPermissions,
    searchPermissions,
    total: totalPermissions,
  } = useWorkspacePermissions({ currentPage, workspaceId } as any);

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);

  const { businessUnitMembers, loading: usersLoading } = useBusinessUnitMembers(
    {
      workspaceId: workspaceId,
    },
  );

  // @ts-expect-error works fine
  useImperativeHandle(ref, () => ({
    submit: () => form.submit(),
  }));

  useEffect(() => {
    form.setFieldValue(
      "visibility",
      editingWorkspace?.visibility === WorkspaceVisibility.PUBLIC
        ? true
        : false,
    );
  }, [editingWorkspace]);

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (_: any, { user }: any) => <span>{user.fullName}</span>,
      sorter: (a: any, b: any) =>
        a.user.lastName[0].localeCompare(b.user.lastName[0]),
      width: "20%",
    },
    {
      title: "Read",

      key: "readable",
      width: "20%",
      render: () => (
        <Row justify="center">
          <Col>
            <CheckOutlined />
          </Col>
        </Row>
      ),
    },
    {
      title: "Edit",
      key: "editable",
      width: "20%",
      render: (_: any, { editable, _id }: any) => (
        <Row justify="center">
          <Col>
            <Button
              type="text"
              style={{
                color: editable ? "green" : "red",
              }}
              onClick={() => {
                updateWorkspacePermission({
                  editable: !editable,
                  permissionId: _id,
                }).catch((err) => {
                  console.error(err);
                });
              }}
            >
              {editable ? <CheckOutlined /> : <CloseOutlined />}
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "30%",
      render: (_: any, { _id }: any) => (
        <Row justify="center">
          <Popconfirm
            title="Delete Permission"
            description="Are you sure you want to delete this permission?"
            onConfirm={() =>
              deleteWorkspacePermission(_id)
                .then((res) => {
                  if (
                    res?.length === 1 &&
                    editingWorkspace?.visibility === WorkspaceVisibility.SHARED
                  ) {
                    updateWorkspace({
                      variables: {
                        input: {
                          description: editingWorkspace?.description,
                          name: editingWorkspace?.name,
                          gridSettings: editingWorkspace?.gridSettings,
                          workspaceId: editingWorkspace?._id,
                          visibility: WorkspaceVisibility.PRIVATE,
                        },
                      },
                    }).catch((err) => {
                      console.error(err);
                    });
                  }
                })
                .catch((e) => console.error(e))
            }
            okText="Yes"
            cancelText="No"
          >
            <Button className="actionButton" danger type="text">
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </Row>
      ),
    },
  ];

  return (
    <div className="">
      <Form form={form} layout="vertical">
        <Form.Item
          name="visibility"
          label={<span>Visibility</span>}
          tooltip="Making a workspace public will give all users in the business unit a read permission. They will be able to see the workspace but won't be able to edit it."
        >
          <Switch
            onChange={(checked) => {
              updateWorkspace({
                variables: {
                  input: {
                    description: editingWorkspace?.description,
                    name: editingWorkspace?.name,
                    gridSettings: editingWorkspace?.gridSettings,
                    workspaceId: editingWorkspace?._id,
                    visibility: checked
                      ? WorkspaceVisibility.PUBLIC
                      : permissions.length > 1
                      ? WorkspaceVisibility.SHARED
                      : WorkspaceVisibility.PRIVATE,
                  },
                },
              }).catch((err) => {
                console.error(err);
              });
            }}
            checkedChildren="Public"
            unCheckedChildren={
              editingWorkspace?.visibility === WorkspaceVisibility.SHARED
                ? "Shared"
                : "Private"
            }
          />
        </Form.Item>
        <Form.Item name="addUserId" label="Add User">
          <Select
            showSearch
            placeholder="Select a user"
            filterOption={filterOption}
            optionFilterProp="children"
            onSelect={(userId) =>
              createWorkspacePermission(userId).then((res) => {
                form.setFieldValue("addUserId", undefined);
                if (
                  res.length > 1 &&
                  editingWorkspace?.visibility === WorkspaceVisibility.PRIVATE
                ) {
                  updateWorkspace({
                    variables: {
                      input: {
                        description: editingWorkspace?.description,
                        name: editingWorkspace?.name,
                        gridSettings: editingWorkspace?.gridSettings,
                        workspaceId: editingWorkspace?._id,
                        visibility: WorkspaceVisibility.SHARED,
                      },
                    },
                  }).catch((err) => {
                    console.error(err);
                  });
                }
              })
            }
            loading={usersLoading}
          >
            {businessUnitMembers?.map((user: any) => (
              <Select.Option
                key={user.userId}
                value={user.userId}
                disabled={permissions.find(
                  (permission) => permission.user?._id === user.userId,
                )}
              >
                {user.firstName} {user.lastName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <Input.Search
        autoComplete="off"
        placeholder="Search by user name"
        onChange={(e) => {
          //TODO There is no userName filter in the query, so we handle this in the frontend. But
          // this will only work for the current page.
          searchPermissions(e.target.value);
        }}
      />

      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={permissions}
        size="small"
        pagination={{
          current: currentPage,
          onChange: (page) => setCurrentPage(page),
          total: totalPermissions,
          pageSize: 5,
        }}
        loading={permissionsLoading}
      />
    </div>
  );
});
