import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  notification,
} from "antd";
import { Dashboard } from "../Dashboard";
import "./styles.scss";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import Header from "@adminBundles/UIAppBundle/components/Header/Header";
import { IconButton } from "@adminBundles/UIAppBundle/components/IconButton/IconButton";
import { SettingFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { USERS_UPDATE_USER } from "@adminBundles/UIAppBundle/queries/user.query";
import { GET_USER_LAST_WORKSPACES } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { WorkspaceItem } from "@adminBundles/UIAppBundle/components/Sidebar/SidebarMenu/WorkspaceItem";
import { Routes } from "@adminBundles/UIAppBundle";
import { useEventManager, useRouter } from "@bluelibs/x-ui";
import { WorkspaceLiveDataEvent } from "@adminBundles/UIAppBundle/events";
import { ActionType } from "@adminBundles/UIAppBundle/types";

export function MePage() {
  const guardian = useAppGuardian();
  const darkTheme = window.localStorage.getItem("theme") === "dark";
  const router = useRouter();
  const eventManager = useEventManager();

  const [userNewName, setUserNewName] = useState("");

  //@ts-ignore idk why ts is complaining about refetch not being in the type even though it is
  const { data, refetch: refetchLastWorkspaces } = useQuery(
    GET_USER_LAST_WORKSPACES,
    {
      variables: {
        input: {
          options: {
            sort: {
              createdAt: -1,
            },
          },
        },
      },
    },
  );

  useEffect(() => {
    const handler = async (event: WorkspaceLiveDataEvent) => {
      const triggersToRefetch = [
        ActionType.MODIFIED_BUSINESS_UNIT,
        ActionType.MODIFIED_WORKSPACE,
        ActionType.MODIFIED_PANEL,
      ];

      if (triggersToRefetch.includes(event.data.actionType)) {
        refetchLastWorkspaces();
      }
    };

    eventManager.addListener(WorkspaceLiveDataEvent, handler);

    return () => {
      eventManager.removeListener(WorkspaceLiveDataEvent as any, handler);
    };
  }, []);

  useEffect(() => {
    setUserNewName(
      guardian.state.user?.profile?.firstName +
        " " +
        guardian.state.user?.profile?.lastName,
    );
  }, [guardian.state.user]);

  const [editProfileModalVisible, setEditProfileModalVisible] = useState(false);

  const [updateUser] = useMutation(USERS_UPDATE_USER);

  const setTheme = (val: any) => {
    window.localStorage.setItem("theme", val);
    window.location.reload();
  };

  return (
    <Dashboard>
      <div className="layout">
        <Header title="Me" />

        <Row justify="center" align="middle" style={{ height: "25%" }}>
          <Col>
            <Card
              title={
                <Flex justify="space-between" align="center">
                  <h3>My Profile</h3>
                  {guardian.hasRole("USER") && (
                    <IconButton
                      icon={<SettingFilled />}
                      onClick={() => setEditProfileModalVisible(true)}
                    />
                  )}
                </Flex>
              }
              bordered={false}
              style={{ width: 300 }}
            >
              <span>
                {guardian.state.user?.profile &&
                  guardian.state.user?.profile?.firstName +
                    " " +
                    guardian.state.user?.profile?.lastName}
              </span>
              <p>{guardian.state.user?.email}</p>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <b>Theme</b>
                <Select
                  onChange={(val) => setTheme(val)}
                  defaultValue={darkTheme ? "dark" : "light"}
                  style={{ width: 120 }}
                  options={[
                    { value: "dark", label: "Dark" },
                    { value: "light", label: "Light" },
                  ]}
                />
              </div> */}
            </Card>
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col>
            <Card
              title={<h3>My Last Workspaces</h3>}
              style={{
                height: "450px",
                width: "380px",
              }}
            >
              <Flex vertical>
                {data?.WorkspaceList?.data?.mine.map((workspace: any) => (
                  <Button
                    key={workspace._id}
                    type="text"
                    onClick={() =>
                      router.go(Routes.WORKSPACE, {
                        params: {
                          id: workspace._id,
                        },
                      })
                    }
                  >
                    {workspace.name}
                  </Button>
                ))}
                {data?.WorkspaceList?.data?.mine.length === 0 && <Empty />}
              </Flex>
            </Card>
          </Col>
          <Col>
            <Card
              title={<h3>Last Workspaces Shared With Me</h3>}
              style={{
                height: "450px",
                width: "380px",
              }}
            >
              <Flex vertical>
                {data?.WorkspaceList?.data?.shared.map((workspace: any) => (
                  <Button
                    key={workspace._id}
                    type="text"
                    onClick={() =>
                      router.go(Routes.WORKSPACE, {
                        params: {
                          id: workspace._id,
                        },
                      })
                    }
                  >
                    {workspace.name}
                  </Button>
                ))}
              </Flex>
              {data?.WorkspaceList?.data?.shared.length === 0 && <Empty />}
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        title="Edit Profile"
        open={editProfileModalVisible}
        onCancel={() => setEditProfileModalVisible(false)}
        destroyOnClose
        onOk={() => {
          updateUser({
            variables: {
              input: {
                firstName: userNewName.split(" ").slice(0, -1).join(" "),
                lastName: userNewName.split(" ").slice(-1).join(" "),
              },
            },
          })
            .then(() => {
              setEditProfileModalVisible(false);
              guardian.updateState({
                user: {
                  ...guardian.state.user,
                  profile: {
                    ...guardian.state.user?.profile,
                    firstName: userNewName.split(" ").slice(0, -1).join(" "),
                    lastName: userNewName.split(" ").slice(-1).join(" "),
                  },
                },
              });
            })
            .catch((e) => {
              notification.error({
                message: "Error",
                description: e.message,
              });
            });
        }}
        closable={false}
      >
        <span>Name</span>
        <Input
          value={userNewName}
          onChange={(e) => setUserNewName(e.target.value)}
          placeholder="Enter your name..."
          autoComplete="off"
        />
      </Modal>
    </Dashboard>
  );
}
