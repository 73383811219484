import * as yup from "yup";
import { AnyObject, ArraySchema } from "yup";

import { Is, Schema, a, an } from "@bluelibs/validator-bundle";
import {
  EndpointType,
  IDataProviderEndpointConfig,
} from "@stonex/xrisk-core-common";

@Schema()
export class ExampleDemoEndpointCFilter {
  @Is(a.string())
  query?: string;
}

@Schema()
export class ExampleDemoEndpointCResponse {
  @Is(
    an.array().of(
      an.object().shape({
        name: a.string(),
        value: a.string(),
        id: a.string(),
      }),
    ),
  )
  data: {
    name: string;
    value: string;
    id: string;
  }[];
}

export const ExampleDemoEndpointC: IDataProviderEndpointConfig<
  ExampleDemoEndpointCFilter,
  ExampleDemoEndpointCResponse
> = {
  id: "exampleEndpointC",
  label: "Example Endpoint C",
  getFilterSchema() {
    return Schema.from(ExampleDemoEndpointCFilter);
  },
  getResponseSchema() {
    return yup
      .array()
      .of(Schema.from(ExampleDemoEndpointCResponse)) as ArraySchema<
      any[],
      AnyObject,
      any,
      any
    >;
  },
  type: EndpointType.SEARCH,
  availablePanels: [],
  formTypes: [
    {
      id: "country",
      label: "Country",
    },
  ],
};
