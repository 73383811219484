import { useEffect } from "react";
import { notification } from "antd";

import { useRouter } from "@bluelibs/x-ui";
import { Routes } from "@adminBundles/UIAppBundle";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { decodeUrlEncodedString } from "@adminBundles/UIAppBundle/utils/functions";

export function Home() {
  const router = useRouter();
  const guardian = useAppGuardian();

  const token = router.history.location.search.match(/token=(.*)/)?.[1];

  const err = router.history.location.search.match(/err=(.*)/)?.[1];

  if (token) {
    guardian.storeToken(token).then(() => {
      router.go(Routes.ME);
    });
  } else {
    router.go(Routes.LOGIN_ADMIN);
  }

  useEffect(() => {
    window.localStorage.setItem("theme", "dark");
    if (err) {
      notification.error({
        message: "Login Failed",
        description: decodeUrlEncodedString(err),
      });
    }
  }, []);

  return <></>;
}
