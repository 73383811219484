import { useEffect } from "react";
import env from "@adminRoot/startup/env";

export const Login = () => {
  useEffect(() => {
    window.location.href = env.API_ROOT_URL + "/auth/okta";
  }, []);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
