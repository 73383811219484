import { useWorkspaceVariables } from "@adminBundles/UIAppBundle/hooks/useWorkspaceVariables";
import { WorkspaceVariableType } from "@adminRoot/api.types";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Flex,
  Input,
  InputNumber,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import {
  DateRangeTimeAwareType,
  DateTimeAwareType,
} from "../../Sidebar/SidebarVariablesForm/SidebarVariablesForm";
import dayjs from "dayjs";
import { camelCaseToText } from "@adminBundles/UIAppBundle/utils/functions";
import { CustomPanelFilter } from "./CustomPanelFilter";
import { isArray, set } from "lodash";
import { useAtom } from "jotai";
import { allowedDataProvidersAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { IDataProviderEndpointConfig } from "@stonex/xrisk-core-common";

export enum COMPARE_OPERATORS {
  equals = "eq",
  greaterThan = "gt",
  lessThan = "lt",
}

const filterDataTypeToWorkspaceVariableTypeMap: Record<
  string,
  WorkspaceVariableType[]
> = {
  string: [WorkspaceVariableType.STRING],
  number: [WorkspaceVariableType.NUMBER],
  date: [WorkspaceVariableType.DATE_TIME_AWARE],
  boolean: [WorkspaceVariableType.BOOLEAN],
  array: [WorkspaceVariableType.DATE_RANGE_TIME_AWARE],
};

interface props {
  panelId: string;
  filter: {
    label: string;
    dataType: string;
    value: string;
    operator: COMPARE_OPERATORS;
    sourceType: "value" | "variable" | "timeAware";
    isRequired: boolean;
    isDisabled: boolean;
    formType: string;
  };
  onFilterChange: (
    value: any,
    sourceType: any,
    disabled: boolean,
    operator?: COMPARE_OPERATORS,
  ) => void;
}

export const PanelFilter = ({ filter, onFilterChange, panelId }: props) => {
  const [filterValue, setFilterValue] = useState<any | null>(filter.value);
  const [allowedDataProviders] = useAtom(allowedDataProvidersAtom);
  const [filterComparison, setFilterComparison] = useState<COMPARE_OPERATORS>(
    filter.operator ?? undefined,
  );
  const [filterType, setFilterType] = useState(filter.sourceType || "value");
  const [filterDisabled, setFilterDisabled] = useState(
    filter.isDisabled || false,
  );

  const [timeSelectOpen, setTimeSelectOpen] = useState(false);

  const {
    workspaceVariables,
    refetch: refetchVariables,
    loading: variablesLoading,
  } = useWorkspaceVariables();

  useEffect(() => {
    refetchVariables();
  }, []);

  useEffect(() => {
    onFilterChange(filterValue, filterType, filterDisabled, filterComparison);
  }, [filterType]);

  useEffect(() => {
    console.log(filter);
    setFilterType(
      filter.sourceType
        ? filter.sourceType
        : filter.dataType === "date" || filter.dataType === "array"
        ? "timeAware"
        : "value",
    );
  }, []);

  let searchEndpoint = null;

  allowedDataProviders?.forEach((dataProvider) => {
    dataProvider.endpoints?.forEach((endpoint: any) => {
      endpoint.formTypes?.forEach((type: any) => {
        if (type.id === filter.formType) {
          searchEndpoint = endpoint;
        }
      });
    });
  });

  if (filter.formType && !searchEndpoint) {
    return <></>;
  }

  return (
    <div className="filter">
      <Tooltip
        title={
          filter.isRequired
            ? "This filter is required and cannot be disabled"
            : ""
        }
        arrow={false}
      >
        <Checkbox
          defaultChecked={filter.isDisabled}
          disabled={filter.isRequired}
          onChange={(e) => {
            setFilterDisabled(e.target.checked);
            onFilterChange(
              filterValue,
              filterType,
              e.target.checked,
              filterComparison,
            );
          }}
        />
      </Tooltip>
      <Select
        value={filterType}
        disabled={filterDisabled}
        onChange={(sourceType) => {
          setFilterType(sourceType);
          onFilterChange(
            null,
            sourceType,
            filterDisabled,
            filterComparison
              ? filterComparison
              : ["number", "date", "array"].includes(filter.dataType)
              ? COMPARE_OPERATORS.equals
              : undefined,
          );
          setFilterValue(null);
        }}
      >
        {(filter.dataType === "date" || filter.dataType === "array") && (
          <Select.Option value="timeAware">Time</Select.Option>
        )}
        {filter.dataType !== "date" && filter.dataType !== "array" && (
          <Select.Option value="value">Value</Select.Option>
        )}
        <Select.Option value="variable">Variable</Select.Option>
      </Select>
      <span>{camelCaseToText(filter.label)}</span>

      <Select
        value={filterComparison}
        disabled={filterDisabled || filter.dataType === "array"}
        onChange={(value) => {
          setFilterComparison(value as COMPARE_OPERATORS);
          onFilterChange(filterValue, filterType, filterDisabled, value);
        }}
        defaultValue={COMPARE_OPERATORS.equals}
        style={{
          visibility:
            filterType === "timeAware" ||
            filter.dataType === "date" ||
            filter.dataType === "number"
              ? "visible"
              : "hidden",
        }}
      >
        <Select.Option value={COMPARE_OPERATORS.equals}>Equals</Select.Option>

        <Select.Option value={COMPARE_OPERATORS.greaterThan}>
          Greater Than
        </Select.Option>
        <Select.Option value={COMPARE_OPERATORS.lessThan}>
          Less Than
        </Select.Option>
      </Select>

      {filterType === "timeAware" ? (
        <Select
          open={timeSelectOpen}
          onClick={(e) => {
            if (
              // @ts-ignore
              e.target.className === "ant-select-selection-item" ||
              // @ts-ignore
              e.target.className === "ant-select-selection-search-input"
            ) {
              setTimeSelectOpen(!timeSelectOpen);
            }
          }}
          value={
            isArray(filterValue)
              ? dayjs(filterValue[0]).format("YYYY-MM-DD") +
                " - " +
                dayjs(filterValue[1]).format("YYYY-MM-DD")
              : dayjs(filterValue).isValid()
              ? dayjs(filterValue).format("YYYY-MM-DD")
              : filterValue ?? undefined
          }
          disabled={filterDisabled}
          onChange={(value) => {
            setFilterValue(value);
            onFilterChange(value, filterType, filterDisabled, filterComparison);
            setTimeSelectOpen(false);
          }}
          placeholder={"Select a date"}
          dropdownRender={(menu) => {
            return (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                {filter.dataType === "date" ? (
                  <DatePicker
                    allowClear={false}
                    value={
                      dayjs(filterValue).isValid() ? dayjs(filterValue) : null
                    }
                    onChange={(value) => {
                      setFilterValue(value);
                      onFilterChange(
                        value,
                        filterType,
                        filterDisabled,
                        filterComparison,
                      );
                      setTimeSelectOpen(false);
                    }}
                  />
                ) : (
                  <DatePicker.RangePicker
                    allowClear={false}
                    onChange={(value) => {
                      setTimeSelectOpen(false);
                      setFilterValue(value);
                      onFilterChange(
                        value,
                        filterType,
                        filterDisabled,
                        filterComparison,
                      );
                    }}
                  />
                )}
              </>
            );
          }}
        >
          {Object.values(
            filter.dataType === "date"
              ? DateTimeAwareType
              : DateRangeTimeAwareType,
          ).map((value) => (
            <Select.Option value={value} key={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      ) : filterType === "value" ? (
        filter.dataType === "boolean" ? (
          <Switch
            checked={filterValue}
            disabled={filterDisabled}
            onChange={(value) => {
              setFilterValue(value);
              onFilterChange(
                value,
                filterType,
                filterDisabled,
                filterComparison,
              );
            }}
            style={{ maxWidth: "50px", minWidth: "50px" }}
          />
        ) : filter.dataType === "number" ? (
          <InputNumber
            disabled={filterDisabled}
            value={filterValue}
            onChange={(val) => {
              setFilterValue(Number(val));
              setFilterComparison(filterComparison ?? COMPARE_OPERATORS.equals);
              onFilterChange(
                Number(val),
                filterType,
                filterDisabled,
                filterComparison ?? COMPARE_OPERATORS.equals,
              );
            }}
            placeholder="Enter a number"
          />
        ) : filter.formType ? (
          <CustomPanelFilter
            formType={filter.formType}
            filterDisabled={filterDisabled}
            filterValue={filterValue}
            onChange={(value) => {
              setFilterValue(value);
              onFilterChange(value, filterType, filterDisabled, undefined);
            }}
          />
        ) : (
          <Input
            disabled={filterDisabled}
            autoComplete="off"
            type="value"
            value={filterValue}
            onChange={(e) => {
              setFilterValue(e.target.value);
              onFilterChange(
                e.target.value,
                filterType,
                filterDisabled,
                filterComparison,
              );
            }}
            placeholder="Enter a value"
          />
        )
      ) : filterType === "variable" ? (
        <Select
          disabled={filterDisabled}
          value={filterValue}
          onChange={(value) => {
            setFilterValue(value);
            onFilterChange(
              value,
              filterType,
              filterDisabled,
              filterComparison
                ? filterComparison
                : ["number", "date", "array"].includes(filter.dataType)
                ? COMPARE_OPERATORS.equals
                : undefined,
            );
          }}
          placeholder="Select a variable"
          loading={variablesLoading}
          key={workspaceVariables?.length}
        >
          {workspaceVariables?.map((variable) => {
            if (filter.formType && variable.type === filter.formType) {
              return (
                <Select.Option
                  value={variable.identity}
                  key={variable.identity}
                >
                  <span className="variableOption">
                    <div
                      className="dot"
                      style={{
                        backgroundColor: variable.color,
                      }}
                    ></div>
                    {variable.label}
                  </span>
                </Select.Option>
              );
            } else if (!filter.formType) {
              return (
                filterDataTypeToWorkspaceVariableTypeMap[
                  filter.dataType
                ].includes(variable.type as WorkspaceVariableType) && (
                  <Select.Option
                    value={variable.identity}
                    key={variable.identity}
                  >
                    <span className="variableOption">
                      <div
                        className="dot"
                        style={{
                          backgroundColor: variable.color,
                        }}
                      ></div>
                      {variable.label}
                    </span>
                  </Select.Option>
                )
              );
            }
          })}
        </Select>
      ) : null}
    </div>
  );
};
