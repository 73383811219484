export const StrippedCircle = () => {
  return (
    <svg
      width="333"
      height="260"
      viewBox="0 0 333 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="adminLoginStrippedCircle"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.05592 -177.138C4.79064 -203.069 10.0851 -228.781 17.9394 -253.864L276.565 -512.49C301.649 -520.344 327.361 -525.639 353.292 -528.374L2.05592 -177.138ZM123.774 151.882C134.356 161.879 145.354 171.181 156.712 179.788L710.217 -373.718C701.611 -385.075 692.309 -396.074 682.311 -406.655L123.774 151.882ZM237.407 226.987C223.635 221.005 210.133 214.2 196.978 206.573L737.003 -333.452C744.629 -320.296 751.434 -306.795 757.417 -293.022L237.407 226.987ZM286.815 244.632C303.305 249.308 320.041 252.892 336.906 255.384L785.814 -193.524C783.322 -210.389 779.738 -227.124 775.061 -243.615L286.815 244.632ZM467.147 253.039C444.868 257.157 422.304 259.351 399.721 259.621L790.05 -130.708C789.781 -108.125 787.587 -85.5612 783.468 -63.2827L467.147 253.039ZM566.777 220.46C605.658 201.69 642.101 176.179 674.355 143.925C706.608 111.671 732.12 75.2284 750.89 36.3479L566.777 220.46ZM647.756 -439.151L91.2781 117.326C81.897 106.082 73.238 94.4397 65.301 82.4598L612.889 -465.129C624.869 -457.192 636.512 -448.533 647.756 -439.151ZM527.603 -507.737C542.118 -502.577 556.391 -496.542 570.342 -489.633L40.7969 39.912C33.8874 25.9616 27.8527 11.688 22.6927 -2.82705L527.603 -507.737ZM8.04925 -55.2354L475.194 -522.38C457.5 -526.032 439.603 -528.462 421.64 -529.669L0.760165 -108.79C1.96799 -90.8266 4.39769 -72.9301 8.04925 -55.2354Z"
        fill="#0080FF"
      />
    </svg>
  );
};
