import { Bundle } from "@bluelibs/core";
import { PanelUIRegistry } from "@stonex/xrisk-core-panel-ui";
import {
  ExamplePanelComponent,
  ExamplePanelPreview,
} from "./ExamplePanelComponents";

import {
  ExamplePanelA,
  ExamplePanelB,
  ExamplePanelS,
} from "@stonex/example-common";
import {
  ExamplePanelComponent2,
  ExamplePanelPreview2,
} from "./ExamplePanelComponents2";
import { ExamplePanelComponent3 } from "./ExamplePanelComponent3";

export class ExamplePanelUIBundle extends Bundle {
  override async prepare() {
    this.container
      .get(PanelUIRegistry)
      .registerPanelComponents(
        ExamplePanelA,
        ExamplePanelComponent,
        ExamplePanelPreview,
      );

    this.container
      .get(PanelUIRegistry)
      .registerPanelComponents(
        ExamplePanelB,
        ExamplePanelComponent2,
        ExamplePanelPreview2,
      );
    this.container
      .get(PanelUIRegistry)
      .registerPanelComponents(
        ExamplePanelS,
        ExamplePanelComponent3,
        ExamplePanelPreview,
      );
  }
}
