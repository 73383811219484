import { useRouter } from "@bluelibs/x-ui";
import "./styles.scss";
import { useAppGuardian } from "@adminBundles/UIAppBundle/services/AppGuardian";
import { Routes } from "@adminBundles/UIAppBundle";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import { useAtom } from "jotai";
import { Breadcrumb, Button, Menu, Popover, Spin, notification } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SettingFilled,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  WORKSPACE_MODAL_TABS,
  addEditWorkspaceModalConfigAtom,
} from "../AddEditWorkspaceModal/AddEditWorkspaceModal";
import { useEffect, useState } from "react";
import { DUPLICATE_WORKSPACE } from "@adminBundles/UIAppBundle/queries/workspaces.query";
import { useMutation } from "@apollo/client";
import { userWorkspacePermissionsAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { useWorkspaceMenu } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";
import { WorkspaceFolder } from "@adminRoot/api.types";
import { capitalize } from "lodash";
import { DeleteWorkspaceModal } from "../DeleteWorkspaceModal/DeleteWorkspaceModal";

interface props {
  title?: string;
}

const Header = ({ title }: props) => {
  const guardian = useAppGuardian();
  const router = useRouter();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [workspace] = useAtom(workspaceAtom);
  const [userWorkspacePermissions] = useAtom(userWorkspacePermissionsAtom);
  const { allFolders } = useWorkspaceMenu();
  const [breadCrumbItems, setBreadCrumbItems] = useState<WorkspaceFolder[]>([]);
  const [_, setAddEditWorkspaceModalConfig] = useAtom(
    addEditWorkspaceModalConfigAtom,
  );
  const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] =
    useState(false);

  const workspaceParentFolder = allFolders.find(
    (folder) => folder.workspacesIds.indexOf(workspace?.data._id) !== -1,
  );

  const findParentFolder = (
    currentFolder: WorkspaceFolder,
    allFolders: WorkspaceFolder[],
    breadCrumbItems: WorkspaceFolder[],
  ): any => {
    // Reset breadcrumb items at the start
    let updatedBreadCrumbItems = [];

    const parentFolder = allFolders.find((folder) => {
      return folder._id === currentFolder.parentWorkspaceFolderId;
    });

    if (parentFolder) {
      updatedBreadCrumbItems = [...breadCrumbItems, parentFolder];
      setBreadCrumbItems(updatedBreadCrumbItems);
      return (
        findParentFolder(parentFolder, allFolders, updatedBreadCrumbItems) ||
        parentFolder
      );
    } else {
      if (workspaceParentFolder) {
        updatedBreadCrumbItems = [...breadCrumbItems, workspaceParentFolder];
        setBreadCrumbItems(updatedBreadCrumbItems);
      }
    }
    return null;
  };

  useEffect(() => {
    if (workspaceParentFolder) {
      // Start with an empty breadcrumb list
      findParentFolder(workspaceParentFolder, allFolders, []);
    }

    return () => {
      setBreadCrumbItems([]);
    };
  }, [workspaceParentFolder]);

  const [cloneWorkspace, { loading: cloneLoading }] =
    useMutation(DUPLICATE_WORKSPACE);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        !e.composedPath().includes(document.querySelector(".ant-popover")!) &&
        !e.composedPath().includes(document.querySelector(".settingsButton")!)
      ) {
        setPopoverOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
  }, []);

  const handleDuplicateWorkspace = () => {
    setPopoverOpen(false);
    notification.info({
      message: "Cloning Workspace...",
      description: (
        <span>
          <Spin size="small" />
        </span>
      ),
      duration: 4,
      key: "cloning",
    });

    cloneWorkspace({
      variables: {
        input: {
          workspaceId: workspace.data._id,
        },
      },
    })
      .then((res) => {
        notification.destroy("cloning");
        notification.success({
          message: "Success",
          description: "Workspace cloned successfully!",
          duration: 1.5,
        });
        new Promise((resolve) => setTimeout(resolve, 1500)).then(() => {
          router.go(Routes.WORKSPACE, {
            params: {
              id: res.data?.WorkspaceDuplicate._id,
            },
          });
        });
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: err.message,
        });
      });
  };

  return (
    <header className="header">
      <Breadcrumb
        separator=">"
        style={{ display: "inline-block", marginRight: "10px" }}
        items={
          breadCrumbItems.length > 0
            ? [
                {
                  title: capitalize(
                    breadCrumbItems?.[0]?.internalBusinessUnitId,
                  ),
                },
                {
                  title: capitalize(breadCrumbItems?.[0]?.context),
                },
                ...(breadCrumbItems ?? []).map((item) => ({
                  title: item.name,
                })),
              ]
            : workspaceParentFolder
            ? [
                {
                  title: workspaceParentFolder.name,
                },
              ]
            : []
        }
      />
      <span className="title">
        {workspace?.data._id &&
          (userWorkspacePermissions?.editable || guardian.hasRole("ADMIN")) && (
            <Popover
              open={popoverOpen}
              placement="bottom"
              content={
                <Menu selectable={false}>
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      setPopoverOpen(false);
                      setAddEditWorkspaceModalConfig({
                        open: true,
                        editingWorkspace: workspace.data,
                        activeTab: WORKSPACE_MODAL_TABS.GENERAL,
                        onSuccess: () => {
                          workspace.refetch();
                        },
                      });
                    }}
                  >
                    <EditOutlined />
                    &nbsp; Edit Workspace
                  </Menu.Item>
                  <Menu.Item key="3" onClick={() => handleDuplicateWorkspace()}>
                    <CopyOutlined />
                    &nbsp; Duplicate Workspace
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    onClick={() => {
                      setPopoverOpen(false);
                      setAddEditWorkspaceModalConfig({
                        open: true,
                        editingWorkspace: workspace.data,
                        activeTab: WORKSPACE_MODAL_TABS.SHARING,
                        onSuccess: () => {
                          workspace.refetch();
                        },
                      });
                    }}
                  >
                    <UserAddOutlined />
                    &nbsp; Share Workspace
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    danger
                    onClick={() => {
                      setPopoverOpen(false);
                      setDeleteWorkspaceModalOpen(true);
                    }}
                  >
                    <DeleteOutlined />
                    &nbsp; Delete Workspace
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                type="text"
                icon={<SettingFilled />}
                className="settingsButton"
                onClick={() => setPopoverOpen(!popoverOpen)}
              />
            </Popover>
          )}
        &nbsp;
        {title}
      </span>
      <div className="userSettings">
        <span
          className="userName"
          onClick={() => {
            router.go(Routes.ME);
          }}
        >
          {guardian.state.user?.profile &&
            guardian.state.user?.profile?.firstName +
              " " +
              guardian.state.user?.profile?.lastName}
        </span>
      </div>

      <DeleteWorkspaceModal
        deleteWorkspaceModalOpen={deleteWorkspaceModalOpen}
        setDeleteWorkspaceModalOpen={setDeleteWorkspaceModalOpen}
        workspace={workspace}
      />
    </header>
  );
};

export default Header;
