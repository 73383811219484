import { useEffect } from "react";

import { ObjectId } from "@bluelibs/ejson";
import { use, useEventManager } from "@bluelibs/x-ui";
import { EJSON } from "@bluelibs/x-ui-admin";
import {
  WorkspaceLiveDataSubscriptionWorkspaceModifyEventData,
  WorkspaceLiveDataSubscriptionEventData,
  WorkspaceLiveDataSubscriptionEventType,
  WorkspaceLiveDataSubscriptionPanelModifyEventData,
  WorkspaceLiveDataSubscriptionWorkspaceFolderModifyEventData,
  WorkspaceLiveDataSubscriptionBusinessUnitModifyEventData,
} from "@adminRoot/api.types";

import { WorkspaceService } from "../services";
import { WorkspaceLiveDataEvent } from "../events";
import { ActionType } from "../types";

export const useWorkspaceLiveData = () => {
  const workspaceService = use(WorkspaceService);
  const eventManager = useEventManager();

  const onBusinessUnitModified = (businessUnitId: ObjectId) => {
    eventManager.emit(
      new WorkspaceLiveDataEvent({
        actionType: ActionType.MODIFIED_BUSINESS_UNIT,
        data: { businessUnitId },
      }),
    );
  };

  const onWorkspaceModified = (workspaceId: ObjectId) => {
    eventManager.emit(
      new WorkspaceLiveDataEvent({
        actionType: ActionType.MODIFIED_WORKSPACE,
        data: { workspaceId },
      }),
    );
  };

  const onWorkspaceFolderModified = (workspaceFolderId: ObjectId) => {
    eventManager.emit(
      new WorkspaceLiveDataEvent({
        actionType: ActionType.MODIFIED_WORKSPACE_FOLDER,
        data: { workspaceFolderId },
      }),
    );
  };

  const onPanelModified = (workspaceId?: ObjectId, panelIds?: ObjectId[]) => {
    eventManager.emit(
      new WorkspaceLiveDataEvent({
        actionType: ActionType.MODIFIED_PANEL,
        data: { workspaceId, panelIds },
      }),
    );
  };

  useEffect(() => {
    const observable = workspaceService.subscribe();

    const subscriptionHandler = observable.subscribe({
      error(err) {
        console.error(err);
      },

      next: (value: any) => {
        const subscriptionResponse =
          value.data.WorkspaceLiveDataSubscription ?? {};

        const { event } = subscriptionResponse;

        let data = {} as WorkspaceLiveDataSubscriptionEventData;

        if (subscriptionResponse.data) {
          data = EJSON.parse(subscriptionResponse.data);
        }

        switch (event) {
          case WorkspaceLiveDataSubscriptionEventType.BUSINESS_UNIT_MODIFIED: {
            const { businessUnitId } =
              data as WorkspaceLiveDataSubscriptionBusinessUnitModifyEventData;

            return onBusinessUnitModified?.(businessUnitId);
          }

          case WorkspaceLiveDataSubscriptionEventType.WORKSPACE_MODIFIED: {
            const { workspaceId } =
              data as WorkspaceLiveDataSubscriptionWorkspaceModifyEventData;

            return onWorkspaceModified?.(workspaceId);
          }

          case WorkspaceLiveDataSubscriptionEventType.WORKSPACE_FOLDER_MODIFIED: {
            const { workspaceFolderId } =
              data as WorkspaceLiveDataSubscriptionWorkspaceFolderModifyEventData;

            return onWorkspaceFolderModified?.(workspaceFolderId);
          }

          case WorkspaceLiveDataSubscriptionEventType.PANEL_MODIFIED: {
            const { workspaceId, panelIds } =
              data as WorkspaceLiveDataSubscriptionPanelModifyEventData;

            return onPanelModified?.(workspaceId, panelIds);
          }

          default: {
            console.error("No proper event found!");
            // throw new Error("No proper event found!");
          }
        }
      },
    });

    return () => {
      subscriptionHandler.unsubscribe();
    };
  }, []);
};
