import { useMutation, useQuery } from "@apollo/client";
import { useAppGuardian } from "../services/AppGuardian";
import {
  CREATE_WORKSPACE_PERMISSION,
  GET_WORKSPACE_PERMISSIONS,
  REMOVE_WORKSPACE_PERMISSION,
  UPDATE_WORKSPACE_PERMISSION,
} from "../queries/workspaces.query";
import { useEffect, useState } from "react";
import {
  WorkspacePermissionGetAllPaginatedResponse,
  WorkspacePermissionGetResponse,
} from "@adminRoot/api.types";
import { notification } from "antd";

interface props {
  currentPage: number;
  workspaceId: string;
}

export const useWorkspacePermissions = ({
  currentPage,
  workspaceId,
}: props) => {
  const [permissions, setPermissions] = useState<
    WorkspacePermissionGetResponse[]
  >([]);

  const getAllPermissionsQuery = useQuery(GET_WORKSPACE_PERMISSIONS, {
    variables: {
      input: {
        options: {
          limit: 5,
          skip: (currentPage - 1) * 5,
        },
        filters: {
          workspaceId,
        },
      },
    },
  });

  const [updateWorkspacePermissions, { loading: updateLoading }] = useMutation(
    UPDATE_WORKSPACE_PERMISSION,
  );

  const [createWorkspacePermission, { loading: createLoading }] = useMutation(
    CREATE_WORKSPACE_PERMISSION,
  );

  const [removeWorkspacePermission, { loading: removeLoading }] = useMutation(
    REMOVE_WORKSPACE_PERMISSION,
  );

  useEffect(() => {
    if (getAllPermissionsQuery.data) {
      const { WorkspacePermissionGetAll } = getAllPermissionsQuery.data as {
        WorkspacePermissionGetAll: WorkspacePermissionGetAllPaginatedResponse;
      };

      setPermissions(WorkspacePermissionGetAll.data);
    }
  }, [getAllPermissionsQuery.data]);

  const searchPermissions = (search: string) => {
    setPermissions(
      getAllPermissionsQuery.data?.WorkspacePermissionGetAll.data.filter(
        ({ user }: any) =>
          user.fullName.toLowerCase().includes(search.toLowerCase()),
      ) ?? [],
    );
  };

  const handleCreatePermission = (userId: string) => {
    return new Promise<any[]>((resolve, reject) => {
      createWorkspacePermission({
        variables: {
          input: {
            workspaceId,
            userId,
            readable: true,
            editable: false,
          },
        },
      })
        .then((res) => {
          getAllPermissionsQuery.refetch();
          resolve(permissions.concat(res.data.WorkspacePermissionCreate));
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err.message,
          });
          reject(err);
        });
    });
  };

  const handleDeletePermission = (permissionId: string) => {
    return new Promise<any[]>((resolve, reject) => {
      removeWorkspacePermission({
        variables: {
          input: {
            workspacePermissionId: permissionId,
          },
        },
      })
        .then(() => {
          getAllPermissionsQuery.refetch();
          resolve(
            permissions.filter((permission) => permission._id !== permissionId),
          );
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err.message,
          });
          reject(err);
        });
    });
  };

  const handleUpdatePermission = ({
    permissionId,
    readable,
    editable,
  }: {
    permissionId: string;
    readable?: boolean;
    editable?: boolean;
  }) => {
    return new Promise((resolve, reject) => {
      updateWorkspacePermissions({
        variables: {
          input: {
            workspacePermissionId: permissionId,
            readable,
            editable,
          },
        },
      })
        .then(() => {
          getAllPermissionsQuery.refetch();
          resolve(undefined);
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err.message,
          });
          reject(err);
        });
    });
  };

  return {
    permissions,
    loading:
      getAllPermissionsQuery.loading ||
      updateLoading ||
      createLoading ||
      removeLoading,
    updateWorkspacePermission: handleUpdatePermission,
    createWorkspacePermission: handleCreatePermission,
    deleteWorkspacePermission: handleDeletePermission,
    refetch: getAllPermissionsQuery.refetch,
    searchPermissions,
    total: getAllPermissionsQuery.data?.WorkspacePermissionGetAll.total,
  };
};
