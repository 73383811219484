import { ADMIN_GET_ALL_USERS } from "@adminBundles/UIAppBundle/queries/user.query";
import { usersToOptions } from "@adminBundles/UIAppBundle/utils/functions";
import {
  AdminsUserGetAllPaginatedResponse,
  UserRole,
} from "@adminRoot/api.types";
import { useQuery } from "@apollo/client";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export function DebounceUserSelect({ ...props }: any) {
  const [options, setOptions] = useState<any[]>([]);
  const [query, setQuery] = useState("");

  const { data, loading } = useQuery(ADMIN_GET_ALL_USERS, {
    variables: {
      input: {
        options: {
          limit: 10000,
          skip: 0,
        },
        filters: {
          roles: [UserRole.USER],
          query: query || "",
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { AdminsUserGetAll } = data as {
        AdminsUserGetAll: AdminsUserGetAllPaginatedResponse;
      };

      setOptions(
        usersToOptions(AdminsUserGetAll.data).map((usr) => {
          return {
            ...usr,
            disabled: props.teamMembers.find(
              (member: any) => member.user?._id === usr.value,
            ),
          };
        }),
      );
    }
  }, [data]);

  return (
    <Select
      showSearch
      filterOption={false}
      placeholder="Select a user"
      onSearch={debounce((value) => setQuery(value), 300)}
      notFoundContent={loading ? <Spin size="small" /> : null}
      loading={loading}
      {...props}
      options={options}
    />
  );
}
