import React, { useEffect, useState } from "react";
import type { SelectProps } from "antd";
import { Select, Space, Tooltip } from "antd";
import { getBusinessUnitById } from "@stonex/xrisk-core-common";

interface ItemProps {
  label: string;
  value: string;
}

const sharedProps: SelectProps = {
  mode: "multiple",
  style: { width: "100%" },
  placeholder: "Select business unit roles...",
  maxTagCount: "responsive",
  allowClear: true,
};

interface props extends SelectProps {
  businessUnitId: string;
  selectedRoles?: string[];
}

export function BusinessUnitRoleMultiSelect({
  businessUnitId,
  ...props
}: props) {
  const [options, setOptions] = useState<ItemProps[]>();

  const [values, setValues] = useState(["credit-analyst"]);

  useEffect(() => {
    const businessUnit = getBusinessUnitById(businessUnitId);
    const businessUnitRoles = businessUnit?.availableRoles ?? [];

    setOptions(
      businessUnitRoles.map(({ id: value, name: label }) => ({
        label,
        value,
      })),
    );
  }, []);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Select
        {...sharedProps}
        value={values}
        onChange={(values) => setValues(values)}
        options={options}
        maxTagPlaceholder={(omittedValues) => (
          <Tooltip
            overlayStyle={{ pointerEvents: "none" }}
            title={omittedValues.map(({ label }) => label).join(", ")}
          >
            <span>Hover Me</span>
          </Tooltip>
        )}
        {...props}
      />
    </Space>
  );
}
