import { KernelContext } from "@bluelibs/core";

if (!process.env.NX_APP_URL) {
  console.warn(`There is no enviornment variable file.`);
}

export default {
  // App
  API_URL: process.env.NX_API_URL,
  API_ROOT_URL: process.env.NX_API_ROOT_URL,
  ROOT_PORT: parseInt(process.env.NX_PORT || process.env.NX_ROOT_PORT || "0"),

  // Other
  NODE_ENV: process.env.NX_NODE_ENV,
  CONTEXT:
    (process.env.NX_NODE_ENV as KernelContext) || KernelContext.DEVELOPMENT,
};
