import {
  FolderAddFilled,
  FolderFilled,
  MoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { WorkspaceFolder } from "@adminRoot/api.types";
import { Button, Collapse, Empty, Flex, Popover, Spin, Typography } from "antd";
import { useDrag } from "react-dnd";
import { WorkspaceItem } from "./WorkspaceItem";
import { DropResult } from "react-beautiful-dnd";
import { useAtom } from "jotai";
import { AddEditFolderModalAtom } from "../../AddEditFolderModal/AddEditFolderModal";
import { useDropFolderItem } from "@adminBundles/UIAppBundle/hooks/useDropFolderItem";
import React, { useEffect } from "react";
import { ExtendedWorkspaceFolder } from "@adminBundles/UIAppBundle/hooks/useWorkspaceMenu";

interface props {
  folder: ExtendedWorkspaceFolder;
  workspaceFoldersData: WorkspaceFolder[];
  index: number;
  refetch: () => void;
}

export const FolderItem = ({
  folder,
  workspaceFoldersData,
  refetch,
}: props) => {
  const [_, setAddEditFolderModalConfig] = useAtom(AddEditFolderModalAtom);

  const { isOver, drop, loading } = useDropFolderItem(folder, refetch);

  const [, drag] = useDrag(() => ({
    type: "folder",
    item: folder,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        //
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const handleSettingsClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setAddEditFolderModalConfig({
      open: true,
      folderId: folder._id,
      parentFolderId: folder.parentWorkspaceFolder?._id,
      folderName: folder.name,
      type: "edit",
      businessUnitId: folder.internalBusinessUnitId,
      context: folder.context,
    });
  };

  const handleAddFolderClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    setAddEditFolderModalConfig({
      open: true,
      folderId: null,
      parentFolderId: folder._id,
      parentFolderName: folder.name,
      folderName: "",
      type: "add",
      businessUnitId: folder.internalBusinessUnitId,
      context: folder.context,
    });
  };

  const [activeKey, setActiveKey] = React.useState<string[]>([]);

  useEffect(() => {
    const openFolders = window.localStorage.getItem("openFolders");
    if (openFolders) {
      setActiveKey(JSON.parse(openFolders));
    }
  }, []);

  return (
    <Collapse
      ghost
      bordered={false}
      size="small"
      onChange={(e) => {
        setActiveKey(e as string[]);
        window.localStorage.setItem("openFolders", JSON.stringify(e));
      }}
      activeKey={activeKey}
      ref={drop}
      className={`folderListItem ${isOver ? "folderListItemHover" : ""}`}
      expandIcon={() => null}
    >
      <Collapse.Panel
        key={folder._id}
        header={
          <Typography.Text
            className={
              activeKey.includes(folder._id)
                ? "folderHeaderActive"
                : "folderHeader"
            }
            ref={drag}
          >
            <div className="text">
              <FolderFilled />
              &nbsp; &nbsp;
              {folder.name}
              {loading ? <Spin style={{ marginLeft: "12px" }} /> : null}
            </div>

            <div className="moreButton" onClick={(e) => e.stopPropagation()}>
              <Popover
                overlayInnerStyle={{ backgroundColor: "#1668dc" }}
                content={
                  <Flex vertical>
                    <Button
                      style={{ textAlign: "left" }}
                      ghost
                      type="text"
                      onClick={handleAddFolderClick}
                    >
                      <FolderAddFilled /> Add Folder
                    </Button>
                    <Button
                      style={{ textAlign: "left" }}
                      ghost
                      type="text"
                      onClick={handleSettingsClick}
                    >
                      <SettingOutlined className="settings" /> Folder Settings
                    </Button>
                  </Flex>
                }
              >
                <Button type="text">
                  <MoreOutlined />
                </Button>
              </Popover>
            </div>
          </Typography.Text>
        }
      >
        {folder.subFolders?.map((subfolder, index) => (
          <FolderItem
            folder={subfolder as ExtendedWorkspaceFolder}
            workspaceFoldersData={workspaceFoldersData}
            index={index}
            refetch={refetch}
            key={subfolder._id}
          />
        ))}

        {folder.workspaces?.map((workspace) => (
          <WorkspaceItem workspace={workspace} key={workspace._id} />
        ))}

        {!folder.subFolders && folder.workspaces?.length === 0 && (
          <div className="emptyFolder" />
          // <Empty description="Folder Empty" />
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
