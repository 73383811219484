export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  EJSON: any;
  JSON: any;
  JSONObject: any;
  ObjectId: any;
  Upload: any;
};

export type AdminsBusinessUnitPermissionCreateInput = {
  businessUnitRoles: Array<InputMaybe<Scalars['String']>>;
  hasAccessToAllPanels: Scalars['Boolean'];
  internalAllowedPanels: Array<InputMaybe<BusinessUnitPermissionInternalAllowedPanelInput>>;
  internalBusinessUnitId: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type AdminsBusinessUnitPermissionGetAllFiltersInput = {
  businessUnitRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasAccessToAllPanels?: InputMaybe<Scalars['Boolean']>;
  internalBusinessUnitId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type AdminsBusinessUnitPermissionGetAllInput = {
  filters?: InputMaybe<AdminsBusinessUnitPermissionGetAllFiltersInput>;
  options?: InputMaybe<AdminsBusinessUnitPermissionGetAllOptionsInput>;
};

export type AdminsBusinessUnitPermissionGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<AdminsBusinessUnitPermissionGetAllSortOptionParams>;
};

export type AdminsBusinessUnitPermissionGetAllPaginatedResponse = {
  __typename?: 'AdminsBusinessUnitPermissionGetAllPaginatedResponse';
  data: Array<Maybe<AdminsBusinessUnitPermissionGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdminsBusinessUnitPermissionGetAllSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  hasAccessToAllPanels?: InputMaybe<Scalars['Int']>;
  internalBusinessUnitId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type AdminsBusinessUnitPermissionGetResponse = {
  __typename?: 'AdminsBusinessUnitPermissionGetResponse';
  _id?: Maybe<Scalars['ObjectId']>;
  businessUnitRoles: Array<Maybe<Scalars['String']>>;
  hasAccessToAllPanels: Scalars['Boolean'];
  internalAllowedPanels: Array<Maybe<BusinessUnitPermissionInternalAllowedPanel>>;
  internalBusinessUnitId: Scalars['String'];
  user?: Maybe<User>;
};

export type AdminsBusinessUnitPermissionRemoveInput = {
  businessUnitPermissionId: Scalars['ObjectId'];
};

export type AdminsBusinessUnitPermissionUpdateInput = {
  businessUnitRoles: Array<InputMaybe<Scalars['String']>>;
  hasAccessToAllPanels: Scalars['Boolean'];
  internalAllowedPanels?: InputMaybe<Array<InputMaybe<BusinessUnitPermissionInternalAllowedPanelInput>>>;
  internalBusinessUnitId: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type AdminsTestInput = {
  data?: InputMaybe<Scalars['JSON']>;
};

export type AdminsUserCreateInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
};

export type AdminsUserGetAllFiltersInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
};

export type AdminsUserGetAllInput = {
  filters?: InputMaybe<AdminsUserGetAllFiltersInput>;
  options?: InputMaybe<AdminsUserGetAllOptionsInput>;
};

export type AdminsUserGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<AdminsUserGetAllSortOptionParams>;
};

export type AdminsUserGetAllPaginatedResponse = {
  __typename?: 'AdminsUserGetAllPaginatedResponse';
  data: Array<Maybe<AdminsUserGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdminsUserGetAllSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Scalars['Int']>;
};

export type AdminsUserGetResponse = {
  __typename?: 'AdminsUserGetResponse';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  lastName: Scalars['String'];
  roles: Array<Maybe<UserRole>>;
  userId: Scalars['ObjectId'];
};

export type AdminsUserRemoveInput = {
  userId: Scalars['ObjectId'];
};

export type AdminsUserUpdateInput = {
  firstName?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  userId: Scalars['ObjectId'];
};

export type BusinessUnitInternalAllowedPanelsGetInput = {
  workspaceId: Scalars['ObjectId'];
};

export type BusinessUnitInternalAllowedPanelsGetResponse = {
  __typename?: 'BusinessUnitInternalAllowedPanelsGetResponse';
  data: Array<Maybe<Scalars['JSON']>>;
};

export type BusinessUnitPermission = {
  __typename?: 'BusinessUnitPermission';
  _id?: Maybe<Scalars['ObjectId']>;
  businessUnitRoles: Array<Maybe<Scalars['String']>>;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  hasAccessToAllPanels: Scalars['Boolean'];
  internalAllowedPanels: Array<Maybe<BusinessUnitPermissionInternalAllowedPanel>>;
  internalBusinessUnitId: Scalars['String'];
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  user: User;
  userId: Scalars['ObjectId'];
};

export type BusinessUnitPermissionInsertInput = {
  businessUnitRoles: Array<InputMaybe<Scalars['String']>>;
  hasAccessToAllPanels: Scalars['Boolean'];
  internalAllowedPanels: Array<InputMaybe<BusinessUnitPermissionInternalAllowedPanelInput>>;
  internalBusinessUnitId: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type BusinessUnitPermissionInternalAllowedPanel = {
  __typename?: 'BusinessUnitPermissionInternalAllowedPanel';
  path: InternalPanelAccessPath;
};

export type BusinessUnitPermissionInternalAllowedPanelInput = {
  path: InternalPanelAccessPathInput;
};

export type BusinessUnitPermissionUpdateInput = {
  businessUnitRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hasAccessToAllPanels?: InputMaybe<Scalars['Boolean']>;
  internalAllowedPanels?: InputMaybe<Array<InputMaybe<BusinessUnitPermissionInternalAllowedPanelInput>>>;
  internalBusinessUnitId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
};

export type BusinessUnitStructure = {
  __typename?: 'BusinessUnitStructure';
  businessUnitId: Scalars['String'];
  workspaceFolders: Array<Maybe<WorkspaceFolder>>;
  workspaces: Array<Maybe<Workspace>>;
};

export type BusinessUnitUsersGetFiltersInput = {
  query?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['ObjectId'];
};

export type BusinessUnitUsersGetInput = {
  filters?: InputMaybe<BusinessUnitUsersGetFiltersInput>;
  options?: InputMaybe<BusinessUnitUsersGetOptionsInput>;
};

export type BusinessUnitUsersGetOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<BusinessUnitUsersGetSortOptionParams>;
};

export type BusinessUnitUsersGetPaginatedResponse = {
  __typename?: 'BusinessUnitUsersGetPaginatedResponse';
  data: Array<Maybe<UsersUserGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type BusinessUnitUsersGetSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['Int']>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type DocumentUpdateInput = {
  _id: Scalars['ObjectId'];
  modifier: Scalars['EJSON'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type InternalPanelAccessPath = {
  __typename?: 'InternalPanelAccessPath';
  dataProviderId: Scalars['String'];
  endpointId: Scalars['String'];
  panelId: Scalars['String'];
};

export type InternalPanelAccessPathInput = {
  dataProviderId: Scalars['String'];
  endpointId: Scalars['String'];
  panelId: Scalars['String'];
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AdminsBusinessUnitPermissionCreate: AdminsBusinessUnitPermissionGetResponse;
  AdminsBusinessUnitPermissionRemove: Scalars['Boolean'];
  AdminsBusinessUnitPermissionUpdate: AdminsBusinessUnitPermissionGetResponse;
  AdminsUserCreate: AdminsUserGetResponse;
  AdminsUserRemove: Scalars['Boolean'];
  AdminsUserUpdate: AdminsUserGetResponse;
  BusinessUnitPermissionsDeleteOne?: Maybe<Scalars['Boolean']>;
  BusinessUnitPermissionsInsertOne?: Maybe<BusinessUnitPermission>;
  BusinessUnitPermissionsUpdateOne: BusinessUnitPermission;
  PanelCreate: PanelGetResponse;
  PanelDuplicate: PanelGetResponse;
  PanelRemove: Scalars['Boolean'];
  PanelUpdate: PanelGetResponse;
  PanelsDeleteOne?: Maybe<Scalars['Boolean']>;
  PanelsInsertOne?: Maybe<Panel>;
  PanelsUpdateOne: Panel;
  UsersDeleteOne?: Maybe<Scalars['Boolean']>;
  UsersInsertOne?: Maybe<User>;
  UsersUpdateOne: User;
  UsersUserUpdate: UsersUserGetResponse;
  WorkspaceCreate: WorkspaceGetResponse;
  WorkspaceDuplicate: WorkspaceGetResponse;
  WorkspaceFolderCreate: WorkspaceFolderGetResponse;
  WorkspaceFolderRemove: Scalars['Boolean'];
  WorkspaceFolderUpdate?: Maybe<WorkspaceFolderGetResponse>;
  WorkspaceFoldersDeleteOne?: Maybe<Scalars['Boolean']>;
  WorkspaceFoldersInsertOne?: Maybe<WorkspaceFolder>;
  WorkspaceFoldersUpdateOne: WorkspaceFolder;
  WorkspacePermissionCreate: WorkspacePermissionGetResponse;
  WorkspacePermissionRemove: Scalars['Boolean'];
  WorkspacePermissionUpdate: WorkspacePermissionGetResponse;
  WorkspacePermissionsDeleteOne?: Maybe<Scalars['Boolean']>;
  WorkspacePermissionsInsertOne?: Maybe<WorkspacePermission>;
  WorkspacePermissionsUpdateOne: WorkspacePermission;
  WorkspaceRemove: Scalars['Boolean'];
  WorkspaceUpdate: WorkspaceGetResponse;
  WorkspaceVariableCreate: WorkspaceVariableGetResponse;
  WorkspaceVariableRemove: Scalars['Boolean'];
  WorkspaceVariableUpdate: WorkspaceVariableGetResponse;
  WorkspaceVariablesDeleteOne?: Maybe<Scalars['Boolean']>;
  WorkspaceVariablesInsertOne?: Maybe<WorkspaceVariable>;
  WorkspaceVariablesUpdateOne: WorkspaceVariable;
  WorkspacesDeleteOne?: Maybe<Scalars['Boolean']>;
  WorkspacesInsertOne?: Maybe<Workspace>;
  WorkspacesUpdateOne: Workspace;
  changePassword?: Maybe<Scalars['Boolean']>;
  forgotPassword?: Maybe<Scalars['Boolean']>;
  login: LoginResponse;
  logout?: Maybe<Scalars['Boolean']>;
  register: RegistrationResponse;
  /** This mutation is used to create a new token based on the existing one. Your previous token will no longer be usable. */
  reissueToken: Scalars['String'];
  requestLoginLink: RequestLoginLinkInputResponse;
  resetPassword: ResetPasswordResponse;
  verifyEmail: VerifyEmailResponse;
  verifyMagicCode: VerifyMagicLinkResponse;
};


export type MutationAdminsBusinessUnitPermissionCreateArgs = {
  input: AdminsBusinessUnitPermissionCreateInput;
};


export type MutationAdminsBusinessUnitPermissionRemoveArgs = {
  input: AdminsBusinessUnitPermissionRemoveInput;
};


export type MutationAdminsBusinessUnitPermissionUpdateArgs = {
  input: AdminsBusinessUnitPermissionUpdateInput;
};


export type MutationAdminsUserCreateArgs = {
  input: AdminsUserCreateInput;
};


export type MutationAdminsUserRemoveArgs = {
  input: AdminsUserRemoveInput;
};


export type MutationAdminsUserUpdateArgs = {
  input: AdminsUserUpdateInput;
};


export type MutationBusinessUnitPermissionsDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationBusinessUnitPermissionsInsertOneArgs = {
  document: BusinessUnitPermissionInsertInput;
};


export type MutationBusinessUnitPermissionsUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: BusinessUnitPermissionUpdateInput;
};


export type MutationPanelCreateArgs = {
  input: PanelCreateInput;
};


export type MutationPanelDuplicateArgs = {
  input: PanelDuplicateInput;
};


export type MutationPanelRemoveArgs = {
  input: PanelRemoveInput;
};


export type MutationPanelUpdateArgs = {
  input: PanelUpdateCustomInput;
};


export type MutationPanelsDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationPanelsInsertOneArgs = {
  document: PanelInsertInput;
};


export type MutationPanelsUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: PanelUpdateInput;
};


export type MutationUsersDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationUsersInsertOneArgs = {
  document: UserInsertInput;
};


export type MutationUsersUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: UserUpdateInput;
};


export type MutationUsersUserUpdateArgs = {
  input: UsersUserUpdateInput;
};


export type MutationWorkspaceCreateArgs = {
  input: WorkspaceCreateInput;
};


export type MutationWorkspaceDuplicateArgs = {
  input: WorkspaceDuplicateInput;
};


export type MutationWorkspaceFolderCreateArgs = {
  input: WorkspaceFolderCreateInput;
};


export type MutationWorkspaceFolderRemoveArgs = {
  input: WorkspaceFolderRemoveInput;
};


export type MutationWorkspaceFolderUpdateArgs = {
  input: WorkspaceFolderUpdateCustomInput;
};


export type MutationWorkspaceFoldersDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationWorkspaceFoldersInsertOneArgs = {
  document: WorkspaceFolderInsertInput;
};


export type MutationWorkspaceFoldersUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: WorkspaceFolderUpdateInput;
};


export type MutationWorkspacePermissionCreateArgs = {
  input: WorkspacePermissionCreateInput;
};


export type MutationWorkspacePermissionRemoveArgs = {
  input: WorkspacePermissionRemoveInput;
};


export type MutationWorkspacePermissionUpdateArgs = {
  input: WorkspacePermissionUpdateCustomInput;
};


export type MutationWorkspacePermissionsDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationWorkspacePermissionsInsertOneArgs = {
  document: WorkspacePermissionInsertInput;
};


export type MutationWorkspacePermissionsUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: WorkspacePermissionUpdateInput;
};


export type MutationWorkspaceRemoveArgs = {
  input: WorkspaceRemoveInput;
};


export type MutationWorkspaceUpdateArgs = {
  input: WorkspaceUpdateCustomInput;
};


export type MutationWorkspaceVariableCreateArgs = {
  input: WorkspaceVariableCreateInput;
};


export type MutationWorkspaceVariableRemoveArgs = {
  input: WorkspaceVariableRemoveInput;
};


export type MutationWorkspaceVariableUpdateArgs = {
  input: WorkspaceVariableUpdateCustomInput;
};


export type MutationWorkspaceVariablesDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationWorkspaceVariablesInsertOneArgs = {
  document: WorkspaceVariableInsertInput;
};


export type MutationWorkspaceVariablesUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: WorkspaceVariableUpdateInput;
};


export type MutationWorkspacesDeleteOneArgs = {
  _id: Scalars['ObjectId'];
};


export type MutationWorkspacesInsertOneArgs = {
  document: WorkspaceInsertInput;
};


export type MutationWorkspacesUpdateOneArgs = {
  _id: Scalars['ObjectId'];
  document: WorkspaceUpdateInput;
};


export type MutationchangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationforgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationloginArgs = {
  input: LoginInput;
};


export type MutationregisterArgs = {
  input: RegistrationInput;
};


export type MutationreissueTokenArgs = {
  token: Scalars['String'];
};


export type MutationrequestLoginLinkArgs = {
  input: RequestLoginLinkInput;
};


export type MutationresetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationverifyEmailArgs = {
  input: VerifyEmailInput;
};


export type MutationverifyMagicCodeArgs = {
  input: VerifyMagicLinkInput;
};

export type Panel = {
  __typename?: 'Panel';
  _id?: Maybe<Scalars['ObjectId']>;
  clonedFromPanel?: Maybe<Panel>;
  clonedFromPanelId?: Maybe<Scalars['ObjectId']>;
  configs?: Maybe<Scalars['JSON']>;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  description?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  internalPanelAccessPath: InternalPanelAccessPath;
  name: Scalars['String'];
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  visualUnitType: PanelVisualUnitType;
  workspace: Workspace;
  workspaceId: Scalars['ObjectId'];
};

export type PanelCreateInput = {
  configs?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['JSON']>;
  internalPanelAccessPath: InternalPanelAccessPathInput;
  name: Scalars['String'];
  visualUnitType?: InputMaybe<PanelVisualUnitType>;
  workspaceId: Scalars['ObjectId'];
};

export type PanelDuplicateInput = {
  panelId: Scalars['ObjectId'];
};

export type PanelGetAllFiltersInput = {
  name?: InputMaybe<Scalars['String']>;
  visualUnitType?: InputMaybe<PanelVisualUnitType>;
  workspaceId?: InputMaybe<Scalars['ObjectId']>;
};

export type PanelGetAllInput = {
  filters?: InputMaybe<PanelGetAllFiltersInput>;
  options?: InputMaybe<PanelGetAllOptionsInput>;
};

export type PanelGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<PanelGetAllSortOptionParams>;
};

export type PanelGetAllPaginatedResponse = {
  __typename?: 'PanelGetAllPaginatedResponse';
  data: Array<Maybe<PanelGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type PanelGetAllSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['Int']>;
  visualUnitType?: InputMaybe<Scalars['Int']>;
};

export type PanelGetInput = {
  panelId: Scalars['ObjectId'];
};

export type PanelGetResponse = {
  __typename?: 'PanelGetResponse';
  _id: Scalars['ObjectId'];
  configs?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  internalPanelAccessPath: InternalPanelAccessPath;
  name: Scalars['String'];
  visualUnitType?: Maybe<PanelVisualUnitType>;
  workspaceId: Scalars['ObjectId'];
};

export type PanelInsertInput = {
  clonedFromPanelId?: InputMaybe<Scalars['ObjectId']>;
  configs?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['JSON']>;
  internalPanelAccessPath: InternalPanelAccessPathInput;
  name: Scalars['String'];
  visualUnitType: PanelVisualUnitType;
  workspaceId: Scalars['ObjectId'];
};

export type PanelRemoveInput = {
  panelId: Scalars['ObjectId'];
};

export type PanelUpdateCustomInput = {
  configs?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['JSON']>;
  internalPanelAccessPath?: InputMaybe<InternalPanelAccessPathInput>;
  name?: InputMaybe<Scalars['String']>;
  panelId: Scalars['ObjectId'];
  visualUnitType?: InputMaybe<PanelVisualUnitType>;
  workspaceId: Scalars['ObjectId'];
};

export type PanelUpdateInput = {
  clonedFromPanelId?: InputMaybe<Scalars['ObjectId']>;
  configs?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['JSON']>;
  internalPanelAccessPath?: InputMaybe<InternalPanelAccessPathInput>;
  name?: InputMaybe<Scalars['String']>;
  visualUnitType?: InputMaybe<PanelVisualUnitType>;
  workspaceId?: InputMaybe<Scalars['ObjectId']>;
};

export enum PanelVisualUnitType {
  CHART = 'CHART',
  TABLE = 'TABLE'
}

export type Query = {
  __typename?: 'Query';
  AdminsBusinessUnitPermissionGetAll: AdminsBusinessUnitPermissionGetAllPaginatedResponse;
  AdminsTest?: Maybe<Scalars['JSON']>;
  AdminsUserGetAll: AdminsUserGetAllPaginatedResponse;
  BusinessUnitInternalAllowedPanelsGet: BusinessUnitInternalAllowedPanelsGetResponse;
  BusinessUnitPermissionsCount: Scalars['Int'];
  BusinessUnitPermissionsFind: Array<Maybe<BusinessUnitPermission>>;
  BusinessUnitPermissionsFindOne?: Maybe<BusinessUnitPermission>;
  BusinessUnitUsersGet: BusinessUnitUsersGetPaginatedResponse;
  PanelGet: PanelGetResponse;
  PanelGetAll: PanelGetAllPaginatedResponse;
  PanelsCount: Scalars['Int'];
  PanelsFind: Array<Maybe<Panel>>;
  PanelsFindOne?: Maybe<Panel>;
  UserGetAll: UserGetAllPaginatedResponse;
  UsersCount: Scalars['Int'];
  UsersFind: Array<Maybe<User>>;
  UsersFindOne?: Maybe<User>;
  WorkspaceFolderGetAll: WorkspaceFolderGetAllPaginatedResponse;
  WorkspaceFoldersCount: Scalars['Int'];
  WorkspaceFoldersFind: Array<Maybe<WorkspaceFolder>>;
  WorkspaceFoldersFindOne?: Maybe<WorkspaceFolder>;
  WorkspaceGet: WorkspaceGetResponse;
  WorkspaceGetAll: WorkspaceGetAllResponse;
  WorkspaceList: WorkspaceListPaginatedResponse;
  WorkspacePermissionGetAll: WorkspacePermissionGetAllPaginatedResponse;
  WorkspacePermissionsCount: Scalars['Int'];
  WorkspacePermissionsFind: Array<Maybe<WorkspacePermission>>;
  WorkspacePermissionsFindOne?: Maybe<WorkspacePermission>;
  WorkspaceVariableGetAll: WorkspaceVariableGetAllPaginatedResponse;
  WorkspaceVariablesCount: Scalars['Int'];
  WorkspaceVariablesFind: Array<Maybe<WorkspaceVariable>>;
  WorkspaceVariablesFindOne?: Maybe<WorkspaceVariable>;
  WorkspacesCount: Scalars['Int'];
  WorkspacesFind: Array<Maybe<Workspace>>;
  WorkspacesFindOne?: Maybe<Workspace>;
  exampleDataProviderA_exampleEndpointA?: Maybe<exampleDataProviderA_exampleEndpointA_response>;
  exampleDataProviderA_exampleEndpointB?: Maybe<exampleDataProviderA_exampleEndpointB_response>;
  exampleDataProviderA_exampleEndpointC?: Maybe<exampleDataProviderA_exampleEndpointC_response>;
  exampleDataProviderB_exampleEndpointA?: Maybe<exampleDataProviderB_exampleEndpointA_response>;
  exampleDataProviderB_exampleEndpointB?: Maybe<exampleDataProviderB_exampleEndpointB_response>;
  exampleDataProviderB_exampleEndpointS?: Maybe<exampleDataProviderB_exampleEndpointS_response>;
  framework?: Maybe<Scalars['String']>;
  me: User;
};


export type QueryAdminsBusinessUnitPermissionGetAllArgs = {
  input: AdminsBusinessUnitPermissionGetAllInput;
};


export type QueryAdminsTestArgs = {
  input?: InputMaybe<AdminsTestInput>;
};


export type QueryAdminsUserGetAllArgs = {
  input: AdminsUserGetAllInput;
};


export type QueryBusinessUnitInternalAllowedPanelsGetArgs = {
  input: BusinessUnitInternalAllowedPanelsGetInput;
};


export type QueryBusinessUnitPermissionsCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryBusinessUnitPermissionsFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryBusinessUnitPermissionsFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryBusinessUnitUsersGetArgs = {
  input: BusinessUnitUsersGetInput;
};


export type QueryPanelGetArgs = {
  input: PanelGetInput;
};


export type QueryPanelGetAllArgs = {
  input: PanelGetAllInput;
};


export type QueryPanelsCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryPanelsFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryPanelsFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryUserGetAllArgs = {
  input: UserGetAllInput;
};


export type QueryUsersCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryUsersFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryUsersFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceFolderGetAllArgs = {
  input: WorkspaceFolderGetAllInput;
};


export type QueryWorkspaceFoldersCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceFoldersFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceFoldersFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceGetArgs = {
  input: WorkspaceGetInput;
};


export type QueryWorkspaceGetAllArgs = {
  input: WorkspaceGetAllInput;
};


export type QueryWorkspaceListArgs = {
  input: WorkspaceListInput;
};


export type QueryWorkspacePermissionGetAllArgs = {
  input: WorkspacePermissionGetAllInput;
};


export type QueryWorkspacePermissionsCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspacePermissionsFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspacePermissionsFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceVariableGetAllArgs = {
  input: WorkspaceVariableGetAllInput;
};


export type QueryWorkspaceVariablesCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceVariablesFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspaceVariablesFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspacesCountArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspacesFindArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryWorkspacesFindOneArgs = {
  query?: InputMaybe<QueryInput>;
};


export type QueryexampleDataProviderA_exampleEndpointAArgs = {
  input?: InputMaybe<exampleDataProviderA_exampleEndpointA_filter>;
};


export type QueryexampleDataProviderA_exampleEndpointBArgs = {
  input?: InputMaybe<exampleDataProviderA_exampleEndpointB_filter>;
};


export type QueryexampleDataProviderA_exampleEndpointCArgs = {
  input?: InputMaybe<exampleDataProviderA_exampleEndpointC_filter>;
};


export type QueryexampleDataProviderB_exampleEndpointAArgs = {
  input?: InputMaybe<exampleDataProviderB_exampleEndpointA_filter>;
};


export type QueryexampleDataProviderB_exampleEndpointBArgs = {
  input?: InputMaybe<exampleDataProviderB_exampleEndpointB_filter>;
};


export type QueryexampleDataProviderB_exampleEndpointSArgs = {
  input?: InputMaybe<exampleDataProviderB_exampleEndpointS_filter>;
};

export type QueryInput = {
  filters?: InputMaybe<Scalars['EJSON']>;
  options?: InputMaybe<QueryOptionsInput>;
};

export type QueryOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  /** This is the Nova body that will get merged deeply with your request body. Useful for passing arguments. */
  sideBody?: InputMaybe<Scalars['EJSON']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['JSON']>;
};

export type RegistrationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  /** Please not that if the user is required to validate his email for logging in, token will be null */
  token?: Maybe<Scalars['String']>;
};

export type RequestLoginLinkInput = {
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type RequestLoginLinkInputResponse = {
  __typename?: 'RequestLoginLinkInputResponse';
  confirmationFormat?: Maybe<Scalars['String']>;
  magicCodeSent: Scalars['Boolean'];
  method?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  username: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  token: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  BusinessUnitPermissionsSubscription?: Maybe<SubscriptionEvent>;
  BusinessUnitPermissionsSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  PanelsSubscription?: Maybe<SubscriptionEvent>;
  PanelsSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  Subscription_exampleDataProviderA_exampleEndpointA?: Maybe<Subscription_exampleDataProviderA_exampleEndpointA_response>;
  Subscription_exampleDataProviderA_exampleEndpointB?: Maybe<Subscription_exampleDataProviderA_exampleEndpointB_response>;
  Subscription_exampleDataProviderA_exampleEndpointC?: Maybe<Subscription_exampleDataProviderA_exampleEndpointC_response>;
  Subscription_exampleDataProviderB_exampleEndpointA?: Maybe<Subscription_exampleDataProviderB_exampleEndpointA_response>;
  Subscription_exampleDataProviderB_exampleEndpointB?: Maybe<Subscription_exampleDataProviderB_exampleEndpointB_response>;
  Subscription_exampleDataProviderB_exampleEndpointS?: Maybe<Subscription_exampleDataProviderB_exampleEndpointS_response>;
  UsersSubscription?: Maybe<SubscriptionEvent>;
  UsersSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  WorkspaceFoldersSubscription?: Maybe<SubscriptionEvent>;
  WorkspaceFoldersSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  WorkspaceLiveDataSubscription?: Maybe<WorkspaceLiveDataSubscriptionResponse>;
  WorkspacePermissionsSubscription?: Maybe<SubscriptionEvent>;
  WorkspacePermissionsSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  WorkspaceVariablesSubscription?: Maybe<SubscriptionEvent>;
  WorkspaceVariablesSubscriptionCount?: Maybe<SubscriptionCountEvent>;
  WorkspacesSubscription?: Maybe<SubscriptionEvent>;
  WorkspacesSubscriptionCount?: Maybe<SubscriptionCountEvent>;
};


export type SubscriptionBusinessUnitPermissionsSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionBusinessUnitPermissionsSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionPanelsSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionPanelsSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionSubscription_exampleDataProviderA_exampleEndpointAArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderA_exampleEndpointA_filter>;
};


export type SubscriptionSubscription_exampleDataProviderA_exampleEndpointBArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderA_exampleEndpointB_filter>;
};


export type SubscriptionSubscription_exampleDataProviderA_exampleEndpointCArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderA_exampleEndpointC_filter>;
};


export type SubscriptionSubscription_exampleDataProviderB_exampleEndpointAArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderB_exampleEndpointA_filter>;
};


export type SubscriptionSubscription_exampleDataProviderB_exampleEndpointBArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderB_exampleEndpointB_filter>;
};


export type SubscriptionSubscription_exampleDataProviderB_exampleEndpointSArgs = {
  input?: InputMaybe<Subscription_exampleDataProviderB_exampleEndpointS_filter>;
};


export type SubscriptionUsersSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionUsersSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspaceFoldersSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspaceFoldersSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspacePermissionsSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspacePermissionsSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspaceVariablesSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspaceVariablesSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspacesSubscriptionArgs = {
  body?: InputMaybe<Scalars['EJSON']>;
};


export type SubscriptionWorkspacesSubscriptionCountArgs = {
  filters?: InputMaybe<Scalars['EJSON']>;
};

export type SubscriptionCountEvent = {
  __typename?: 'SubscriptionCountEvent';
  count?: Maybe<Scalars['Int']>;
};

export type SubscriptionEvent = {
  __typename?: 'SubscriptionEvent';
  document?: Maybe<Scalars['EJSON']>;
  event: SubscriptionEventType;
};

export enum SubscriptionEventType {
  added = 'added',
  changed = 'changed',
  ready = 'ready',
  removed = 'removed'
}

export type Subscription_exampleDataProviderA_exampleEndpointA_filter = {
  country?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderA_exampleEndpointA_response = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointA_response';
  columnRowData?: Maybe<Array<Maybe<Subscription_exampleDataProviderA_exampleEndpointA_response_columnRowData>>>;
};

export type Subscription_exampleDataProviderA_exampleEndpointA_response_columnRowData = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointA_response_columnRowData';
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderA_exampleEndpointB_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Subscription_exampleDataProviderA_exampleEndpointB_response = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointB_response';
  chartData?: Maybe<Array<Maybe<Subscription_exampleDataProviderA_exampleEndpointB_response_chartData>>>;
};

export type Subscription_exampleDataProviderA_exampleEndpointB_response_chartData = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointB_response_chartData';
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Subscription_exampleDataProviderA_exampleEndpointC_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderA_exampleEndpointC_response = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointC_response';
  data?: Maybe<Array<Maybe<Subscription_exampleDataProviderA_exampleEndpointC_response_data>>>;
};

export type Subscription_exampleDataProviderA_exampleEndpointC_response_data = {
  __typename?: 'Subscription_exampleDataProviderA_exampleEndpointC_response_data';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderB_exampleEndpointA_filter = {
  country?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderB_exampleEndpointA_response = {
  __typename?: 'Subscription_exampleDataProviderB_exampleEndpointA_response';
  columnRowData?: Maybe<Array<Maybe<Subscription_exampleDataProviderB_exampleEndpointA_response_columnRowData>>>;
};

export type Subscription_exampleDataProviderB_exampleEndpointA_response_columnRowData = {
  __typename?: 'Subscription_exampleDataProviderB_exampleEndpointA_response_columnRowData';
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderB_exampleEndpointB_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Subscription_exampleDataProviderB_exampleEndpointB_response = {
  __typename?: 'Subscription_exampleDataProviderB_exampleEndpointB_response';
  chartData?: Maybe<Array<Maybe<Subscription_exampleDataProviderB_exampleEndpointB_response_chartData>>>;
};

export type Subscription_exampleDataProviderB_exampleEndpointB_response_chartData = {
  __typename?: 'Subscription_exampleDataProviderB_exampleEndpointB_response_chartData';
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Subscription_exampleDataProviderB_exampleEndpointS_filter = {
  filter?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
};

export type Subscription_exampleDataProviderB_exampleEndpointS_response = {
  __typename?: 'Subscription_exampleDataProviderB_exampleEndpointS_response';
  data?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  _id?: Maybe<Scalars['ObjectId']>;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  oktaId?: Maybe<Scalars['String']>;
  profile: UserProfile;
  roles: Array<Maybe<UserRole>>;
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
};

export type UserGetAllFiltersInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type UserGetAllInput = {
  filters?: InputMaybe<UserGetAllFiltersInput>;
  options?: InputMaybe<UserGetAllOptionsInput>;
};

export type UserGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<UserGetAllSortOptionParams>;
};

export type UserGetAllPaginatedResponse = {
  __typename?: 'UserGetAllPaginatedResponse';
  data: Array<Maybe<UsersUserGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type UserGetAllSortOptionParams = {
  email?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['Int']>;
};

export type UserInsertInput = {
  isEnabled: Scalars['Boolean'];
  oktaId?: InputMaybe<Scalars['String']>;
  profile: UserProfileInput;
  roles: Array<InputMaybe<UserRole>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type UserProfileInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER'
}

export type UserUpdateInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  oktaId?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<UserProfileInput>;
  roles?: InputMaybe<Array<InputMaybe<UserRole>>>;
};

export type UsersUserGetResponse = {
  __typename?: 'UsersUserGetResponse';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  userId: Scalars['ObjectId'];
};

export type UsersUserUpdateInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type VerifyEmailInput = {
  token: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  token: Scalars['String'];
};

export type VerifyMagicLinkInput = {
  magicCode: Scalars['String'];
  userId: Scalars['String'];
};

export type VerifyMagicLinkResponse = {
  __typename?: 'VerifyMagicLinkResponse';
  redirectUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  _id?: Maybe<Scalars['ObjectId']>;
  clonedFromWorkspace?: Maybe<Workspace>;
  clonedFromWorkspaceId?: Maybe<Scalars['ObjectId']>;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  description?: Maybe<Scalars['String']>;
  gridSettings?: Maybe<Scalars['JSON']>;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  ownerUser: User;
  ownerUserId: Scalars['ObjectId'];
  permissions: Array<Maybe<WorkspacePermission>>;
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  visibility: WorkspaceVisibility;
};

export type WorkspaceCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  visibility?: InputMaybe<WorkspaceVisibility>;
};

export type WorkspaceDuplicateInput = {
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceFolder = {
  __typename?: 'WorkspaceFolder';
  _id?: Maybe<Scalars['ObjectId']>;
  context: WorkspaceFolderContext;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  parentWorkspaceFolder?: Maybe<WorkspaceFolder>;
  parentWorkspaceFolderId?: Maybe<Scalars['ObjectId']>;
  subFolderCount: Scalars['Int'];
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  user: User;
  userId: Scalars['ObjectId'];
  workspaces: Array<Maybe<Workspace>>;
  workspacesIds: Array<Maybe<Scalars['ObjectId']>>;
};

export enum WorkspaceFolderContext {
  MINE = 'MINE',
  SHARED = 'SHARED'
}

export type WorkspaceFolderCreateInput = {
  context: WorkspaceFolderContext;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  parentWorkspaceFolderId?: InputMaybe<Scalars['ObjectId']>;
};

export type WorkspaceFolderGetAllFiltersInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type WorkspaceFolderGetAllInput = {
  filters?: InputMaybe<WorkspaceFolderGetAllFiltersInput>;
  options?: InputMaybe<WorkspaceFolderGetAllOptionsInput>;
};

export type WorkspaceFolderGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<WorkspaceFolderGetAllSortOptionParams>;
};

export type WorkspaceFolderGetAllPaginatedResponse = {
  __typename?: 'WorkspaceFolderGetAllPaginatedResponse';
  data: Array<Maybe<WorkspaceFolderGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WorkspaceFolderGetAllSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['Int']>;
};

export type WorkspaceFolderGetResponse = {
  __typename?: 'WorkspaceFolderGetResponse';
  _id?: Maybe<Scalars['ObjectId']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  parentWorkspaceFolder?: Maybe<WorkspaceFolder>;
  subFolderCount: Scalars['Int'];
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type WorkspaceFolderInsertInput = {
  context: WorkspaceFolderContext;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  parentWorkspaceFolderId?: InputMaybe<Scalars['ObjectId']>;
  userId: Scalars['ObjectId'];
  workspacesIds: Array<InputMaybe<Scalars['ObjectId']>>;
};

export type WorkspaceFolderRemoveInput = {
  workspaceFolderId: Scalars['ObjectId'];
};

export type WorkspaceFolderUpdateCustomInput = {
  name?: InputMaybe<Scalars['String']>;
  parentWorkspaceFolderId?: InputMaybe<Scalars['ObjectId']>;
  workspaceFolderId?: InputMaybe<Scalars['ObjectId']>;
  workspacesIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type WorkspaceFolderUpdateInput = {
  context?: InputMaybe<WorkspaceFolderContext>;
  internalBusinessUnitId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentWorkspaceFolderId?: InputMaybe<Scalars['ObjectId']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
  workspacesIds?: InputMaybe<Array<InputMaybe<Scalars['ObjectId']>>>;
};

export type WorkspaceGetAllInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type WorkspaceGetAllResponse = {
  __typename?: 'WorkspaceGetAllResponse';
  mine: Array<Maybe<BusinessUnitStructure>>;
  shared: Array<Maybe<BusinessUnitStructure>>;
};

export type WorkspaceGetInput = {
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceGetResponse = {
  __typename?: 'WorkspaceGetResponse';
  _id: Scalars['ObjectId'];
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  gridSettings?: Maybe<Scalars['JSON']>;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  visibility: WorkspaceVisibility;
};

export type WorkspaceInsertInput = {
  clonedFromWorkspaceId?: InputMaybe<Scalars['ObjectId']>;
  description?: InputMaybe<Scalars['String']>;
  gridSettings?: InputMaybe<Scalars['JSON']>;
  internalBusinessUnitId: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  ownerUserId: Scalars['ObjectId'];
  visibility: WorkspaceVisibility;
};

export type WorkspaceListData = {
  __typename?: 'WorkspaceListData';
  mine: Array<Maybe<WorkspaceGetResponse>>;
  shared: Array<Maybe<WorkspaceGetResponse>>;
};

export type WorkspaceListFiltersInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type WorkspaceListInput = {
  filters?: InputMaybe<WorkspaceListFiltersInput>;
  options?: InputMaybe<WorkspaceListOptionsInput>;
};

export type WorkspaceListOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<WorkspaceListSortOptionParams>;
};

export type WorkspaceListPaginatedResponse = {
  __typename?: 'WorkspaceListPaginatedResponse';
  data: WorkspaceListData;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: WorkspaceListTotal;
};

export type WorkspaceListSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['Int']>;
};

export type WorkspaceListTotal = {
  __typename?: 'WorkspaceListTotal';
  mine: Scalars['Int'];
  shared: Scalars['Int'];
};

export type WorkspaceLiveDataSubscriptionBusinessUnitModifyEventData = {
  __typename?: 'WorkspaceLiveDataSubscriptionBusinessUnitModifyEventData';
  businessUnitId: Scalars['ObjectId'];
};

export type WorkspaceLiveDataSubscriptionEventData = WorkspaceLiveDataSubscriptionBusinessUnitModifyEventData | WorkspaceLiveDataSubscriptionPanelModifyEventData | WorkspaceLiveDataSubscriptionWorkspaceFolderModifyEventData | WorkspaceLiveDataSubscriptionWorkspaceModifyEventData;

export enum WorkspaceLiveDataSubscriptionEventType {
  BUSINESS_UNIT_MODIFIED = 'BUSINESS_UNIT_MODIFIED',
  PANEL_MODIFIED = 'PANEL_MODIFIED',
  WORKSPACE_FOLDER_MODIFIED = 'WORKSPACE_FOLDER_MODIFIED',
  WORKSPACE_MODIFIED = 'WORKSPACE_MODIFIED'
}

export type WorkspaceLiveDataSubscriptionPanelModifyEventData = {
  __typename?: 'WorkspaceLiveDataSubscriptionPanelModifyEventData';
  panelIds?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  workspaceId?: Maybe<Scalars['ObjectId']>;
};

export type WorkspaceLiveDataSubscriptionResponse = {
  __typename?: 'WorkspaceLiveDataSubscriptionResponse';
  data?: Maybe<Scalars['String']>;
  event: WorkspaceLiveDataSubscriptionEventType;
};

export type WorkspaceLiveDataSubscriptionWorkspaceFolderModifyEventData = {
  __typename?: 'WorkspaceLiveDataSubscriptionWorkspaceFolderModifyEventData';
  workspaceFolderId: Scalars['ObjectId'];
};

export type WorkspaceLiveDataSubscriptionWorkspaceModifyEventData = {
  __typename?: 'WorkspaceLiveDataSubscriptionWorkspaceModifyEventData';
  workspaceId: Scalars['ObjectId'];
};

export type WorkspacePermission = {
  __typename?: 'WorkspacePermission';
  _id?: Maybe<Scalars['ObjectId']>;
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  editable?: Maybe<Scalars['Boolean']>;
  readable?: Maybe<Scalars['Boolean']>;
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  user: User;
  userId: Scalars['ObjectId'];
  workspace: Workspace;
  workspaceId: Scalars['ObjectId'];
};

export type WorkspacePermissionCreateInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  readable?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ObjectId'];
  workspaceId: Scalars['ObjectId'];
};

export type WorkspacePermissionGetAllFiltersInput = {
  userId?: InputMaybe<Scalars['ObjectId']>;
  workspaceId?: InputMaybe<Scalars['ObjectId']>;
};

export type WorkspacePermissionGetAllInput = {
  filters?: InputMaybe<WorkspacePermissionGetAllFiltersInput>;
  options?: InputMaybe<WorkspacePermissionGetAllOptionsInput>;
};

export type WorkspacePermissionGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<WorkspacePermissionGetAllSortOptionParams>;
};

export type WorkspacePermissionGetAllPaginatedResponse = {
  __typename?: 'WorkspacePermissionGetAllPaginatedResponse';
  data: Array<Maybe<WorkspacePermissionGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WorkspacePermissionGetAllSortOptionParams = {
  createdAt?: InputMaybe<Scalars['Int']>;
  editable?: InputMaybe<Scalars['Int']>;
  readable?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type WorkspacePermissionGetResponse = {
  __typename?: 'WorkspacePermissionGetResponse';
  _id: Scalars['ObjectId'];
  editable?: Maybe<Scalars['Boolean']>;
  readable?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
};

export type WorkspacePermissionInsertInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  readable?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ObjectId'];
  workspaceId: Scalars['ObjectId'];
};

export type WorkspacePermissionRemoveInput = {
  workspacePermissionId: Scalars['ObjectId'];
};

export type WorkspacePermissionUpdateCustomInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  readable?: InputMaybe<Scalars['Boolean']>;
  workspacePermissionId: Scalars['ObjectId'];
};

export type WorkspacePermissionUpdateInput = {
  editable?: InputMaybe<Scalars['Boolean']>;
  readable?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ObjectId']>;
  workspaceId?: InputMaybe<Scalars['ObjectId']>;
};

export type WorkspaceRemoveInput = {
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceUpdateCustomInput = {
  description?: InputMaybe<Scalars['String']>;
  gridSettings?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  visibility?: InputMaybe<WorkspaceVisibility>;
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceUpdateInput = {
  clonedFromWorkspaceId?: InputMaybe<Scalars['ObjectId']>;
  description?: InputMaybe<Scalars['String']>;
  gridSettings?: InputMaybe<Scalars['JSON']>;
  internalBusinessUnitId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  ownerUserId?: InputMaybe<Scalars['ObjectId']>;
  visibility?: InputMaybe<WorkspaceVisibility>;
};

export type WorkspaceVariable = {
  __typename?: 'WorkspaceVariable';
  _id?: Maybe<Scalars['ObjectId']>;
  color: Scalars['String'];
  /** Represents the date when this object was created */
  createdAt: Scalars['Date'];
  /** Represents the user who has created this object */
  createdBy?: Maybe<User>;
  /** Represents the user's id who has created this object */
  createdById?: Maybe<Scalars['ObjectId']>;
  defaultValue?: Maybe<Scalars['JSON']>;
  identity: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  /** Represents the last time when the object was updated */
  updatedAt: Scalars['Date'];
  /** Represents the user who has made the latest update on this object */
  updatedBy?: Maybe<User>;
  /** Represents the user's id who has made the latest update on this object */
  updatedById?: Maybe<Scalars['ObjectId']>;
  workspace: Workspace;
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceVariableCreateInput = {
  color: Scalars['String'];
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceVariableGetAllFiltersInput = {
  color?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceVariableGetAllInput = {
  filters?: InputMaybe<WorkspaceVariableGetAllFiltersInput>;
  options?: InputMaybe<WorkspaceVariableGetAllOptionsInput>;
};

export type WorkspaceVariableGetAllOptionsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<WorkspaceVariableGetAllSortOptionParams>;
};

export type WorkspaceVariableGetAllPaginatedResponse = {
  __typename?: 'WorkspaceVariableGetAllPaginatedResponse';
  data: Array<Maybe<WorkspaceVariableGetResponse>>;
  limit: Scalars['Int'];
  skip: Scalars['Int'];
  total: Scalars['Int'];
};

export type WorkspaceVariableGetAllSortOptionParams = {
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Int']>;
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type WorkspaceVariableGetResponse = {
  __typename?: 'WorkspaceVariableGetResponse';
  _id: Scalars['ObjectId'];
  color: Scalars['String'];
  defaultValue?: Maybe<Scalars['JSON']>;
  identity: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceVariableInsertInput = {
  color: Scalars['String'];
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity: Scalars['String'];
  label: Scalars['String'];
  type: Scalars['String'];
  workspaceId: Scalars['ObjectId'];
};

export type WorkspaceVariableRemoveInput = {
  workspaceVariableId: Scalars['ObjectId'];
};

export enum WorkspaceVariableType {
  BOOLEAN = 'BOOLEAN',
  DATE_RANGE_TIME_AWARE = 'DATE_RANGE_TIME_AWARE',
  DATE_TIME_AWARE = 'DATE_TIME_AWARE',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export type WorkspaceVariableUpdateCustomInput = {
  color?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceVariableId: Scalars['ObjectId'];
};

export type WorkspaceVariableUpdateInput = {
  color?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['JSON']>;
  identity?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  workspaceId?: InputMaybe<Scalars['ObjectId']>;
};

export enum WorkspaceVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  SHARED = 'SHARED'
}

export type exampleDataProviderA_exampleEndpointA_filter = {
  country?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
};

export type exampleDataProviderA_exampleEndpointA_response = {
  __typename?: 'exampleDataProviderA_exampleEndpointA_response';
  columnRowData?: Maybe<Array<Maybe<exampleDataProviderA_exampleEndpointA_response_columnRowData>>>;
};

export type exampleDataProviderA_exampleEndpointA_response_columnRowData = {
  __typename?: 'exampleDataProviderA_exampleEndpointA_response_columnRowData';
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['String']>;
};

export type exampleDataProviderA_exampleEndpointB_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type exampleDataProviderA_exampleEndpointB_response = {
  __typename?: 'exampleDataProviderA_exampleEndpointB_response';
  chartData?: Maybe<Array<Maybe<exampleDataProviderA_exampleEndpointB_response_chartData>>>;
};

export type exampleDataProviderA_exampleEndpointB_response_chartData = {
  __typename?: 'exampleDataProviderA_exampleEndpointB_response_chartData';
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type exampleDataProviderA_exampleEndpointC_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type exampleDataProviderA_exampleEndpointC_response = {
  __typename?: 'exampleDataProviderA_exampleEndpointC_response';
  data?: Maybe<Array<Maybe<exampleDataProviderA_exampleEndpointC_response_data>>>;
};

export type exampleDataProviderA_exampleEndpointC_response_data = {
  __typename?: 'exampleDataProviderA_exampleEndpointC_response_data';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type exampleDataProviderB_exampleEndpointA_filter = {
  country?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
};

export type exampleDataProviderB_exampleEndpointA_response = {
  __typename?: 'exampleDataProviderB_exampleEndpointA_response';
  columnRowData?: Maybe<Array<Maybe<exampleDataProviderB_exampleEndpointA_response_columnRowData>>>;
};

export type exampleDataProviderB_exampleEndpointA_response_columnRowData = {
  __typename?: 'exampleDataProviderB_exampleEndpointA_response_columnRowData';
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  stock?: Maybe<Scalars['String']>;
};

export type exampleDataProviderB_exampleEndpointB_filter = {
  panelId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type exampleDataProviderB_exampleEndpointB_response = {
  __typename?: 'exampleDataProviderB_exampleEndpointB_response';
  chartData?: Maybe<Array<Maybe<exampleDataProviderB_exampleEndpointB_response_chartData>>>;
};

export type exampleDataProviderB_exampleEndpointB_response_chartData = {
  __typename?: 'exampleDataProviderB_exampleEndpointB_response_chartData';
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type exampleDataProviderB_exampleEndpointS_filter = {
  filter?: InputMaybe<Scalars['String']>;
  panelId?: InputMaybe<Scalars['String']>;
};

export type exampleDataProviderB_exampleEndpointS_response = {
  __typename?: 'exampleDataProviderB_exampleEndpointS_response';
  data?: Maybe<Scalars['String']>;
};
