import {
  Button,
  Col,
  ColorPicker,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Switch,
  notification,
} from "antd";
import "./style.scss";
import { atom, useAtom } from "jotai";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormInstance } from "antd/lib";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_WORKSPACE_VARIABLE,
  GET_WORKSPACE_VARIABLES,
  UPDATE_WORKSPACE_VARIABLE,
} from "@adminBundles/UIAppBundle/queries/workspaces.query";
import {
  WorkspaceVariableCreateInput,
  WorkspaceVariableUpdateInput,
  WorkspaceVariableType,
  WorkspaceVariableUpdateCustomInput,
} from "@adminRoot/api.types";
import { workspaceAtom } from "@adminBundles/UIAppBundle/hooks/useWorkspace";
import dayjs from "dayjs";
import {
  DateRangeTimeAwareType,
  DateTimeAwareType,
} from "../Sidebar/SidebarVariablesForm/SidebarVariablesForm";
import { snakeCaseToText } from "@adminBundles/UIAppBundle/utils/functions";
import { allowedDataProvidersAtom } from "@adminBundles/UIAppBundle/pages/Dashboard/Workspace/Workspace";
import { CustomPanelFilter } from "../AddEditPanelModal/PanelFilters/CustomPanelFilter";
import { isArray } from "lodash";

export const addEditVariableFormConfigAtom = atom({
  visible: false,
  editingVariable: null,
} as any);

export const AddEditVariableForm = forwardRef<Partial<FormInstance>>(
  (props, ref) => {
    const [form] = Form.useForm();
    const [workspace] = useAtom(workspaceAtom);
    const [allowedDataProviders] = useAtom(allowedDataProvidersAtom);

    const [dateSelectOpen, setDateSelectOpen] = useState(false);

    const allowedFormTypes: string[] = allowedDataProviders
      ?.flatMap((dataProvider) =>
        dataProvider.endpoints?.flatMap((endpoint: any) =>
          endpoint.formTypes?.map((formType: any) => formType.id),
        ),
      )
      .filter(Boolean);

    const [addEditVariableFormConfig, setAddEditVariableFormConfig] = useAtom(
      addEditVariableFormConfigAtom,
    );
    useImperativeHandle(ref, () => ({
      submit: () => form.submit(),
    }));

    const [defaultValue, setDefaultValue] = useState<any>(
      addEditVariableFormConfig.editingVariable?.defaultValue,
    );

    const [createWorkspaceVariable, { loading: createVariableLoading }] =
      useMutation<any, { input: WorkspaceVariableCreateInput }>(
        CREATE_WORKSPACE_VARIABLE,
      );

    const [updateWorkspaceVariable, { loading: editVariableLoading }] =
      useMutation<any, { input: WorkspaceVariableUpdateCustomInput }>(
        UPDATE_WORKSPACE_VARIABLE,
      );

    const { refetch } = useQuery(GET_WORKSPACE_VARIABLES, {
      variables: {
        input: {
          filters: {
            workspaceId: workspace.data._id,
          },
        },
      },
    });

    const handleFinish = ({ values }: any) => {
      if (addEditVariableFormConfig.editingVariable) {
        updateWorkspaceVariable({
          variables: {
            input: {
              color: values.color,
              defaultValue: defaultValue
                ? defaultValue
                : values.type === WorkspaceVariableType.BOOLEAN
                ? false
                : null,
              identity: values.identity,
              label: values.label,
              type: values.type,
              workspaceVariableId:
                addEditVariableFormConfig.editingVariable._id,
            },
          },
        })
          .then((res) => {
            refetch();
            setAddEditVariableFormConfig({
              visible: false,
              editingVariable: null,
            });
            form.resetFields();
          })
          .catch((e) => {
            notification.error({
              message: "Error",
              description: e.message,
            });
          });
      } else {
        createWorkspaceVariable({
          variables: {
            input: {
              ...values,
              defaultValue: defaultValue
                ? defaultValue
                : values.type === WorkspaceVariableType.BOOLEAN
                ? false
                : null,
              workspaceId: workspace.data._id,
            },
          },
        })
          .then((res) => {
            refetch();
            setAddEditVariableFormConfig({
              visible: false,
              editingVariable: null,
            });
            form.resetFields();
          })
          .catch((e) => {
            notification.error({
              message: "Error",
              description: e.message,
            });
          });
      }
    };

    useEffect(() => {
      if (addEditVariableFormConfig.editingVariable) {
        form.setFieldsValue({
          values: {
            ...addEditVariableFormConfig.editingVariable,
          },
        });
      } else {
        form.resetFields();
      }
    }, [addEditVariableFormConfig.editingVariable]);

    useEffect(() => {
      setDefaultValue(addEditVariableFormConfig.editingVariable?.defaultValue);
    }, [addEditVariableFormConfig.editingVariable]);

    const renderDefaultValueInput = (type: any, value: any) => {
      const handleChange = (value: any) => {
        setDefaultValue(value);
      };

      if (type === WorkspaceVariableType.STRING) {
        return (
          <Input
            placeholder="Enter a default value..."
            autoComplete="off"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
          />
        );
      } else if (type === WorkspaceVariableType.NUMBER) {
        return (
          <InputNumber
            placeholder="Enter a default value..."
            autoComplete="off"
            value={value}
            onChange={(value) => handleChange(value)}
          />
        );
      } else if (type === WorkspaceVariableType.BOOLEAN) {
        return (
          <Switch checked={value} onChange={(value) => handleChange(value)} />
        );
      } else if (
        type === WorkspaceVariableType.DATE_RANGE_TIME_AWARE ||
        type === WorkspaceVariableType.DATE_TIME_AWARE
      ) {
        return (
          <Select
            allowClear
            open={dateSelectOpen}
            onClick={(e) => {
              // @ts-ignore
              if (
                // @ts-ignore
                e.target.className === "ant-select-selection-item" ||
                // @ts-ignore
                e.target.className === "ant-select-selection-search-input"
              ) {
                setDateSelectOpen(!dateSelectOpen);
              }
            }}
            value={
              isArray(value)
                ? dayjs(value[0]).format("YYYY-MM-DD") +
                  " - " +
                  dayjs(value[1]).format("YYYY-MM-DD")
                : dayjs(value).isValid() && value !== undefined
                ? dayjs(value).format("YYYY-MM-DD")
                : value
            }
            onChange={(value) => {
              handleChange(value);
              setDateSelectOpen(false);
            }}
            placeholder={"Select a date"}
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  {type === WorkspaceVariableType.DATE_TIME_AWARE ? (
                    <DatePicker
                      allowClear={true}
                      value={
                        dayjs(value).isValid() && value !== undefined
                          ? dayjs(value)
                          : null
                      }
                      onChange={(value) => {
                        handleChange(value);
                        setDateSelectOpen(false);
                      }}
                    />
                  ) : (
                    <DatePicker.RangePicker
                      allowClear={true}
                      value={
                        isArray(value)
                          ? [dayjs(value[0]), dayjs(value[1])]
                          : null
                      }
                      onChange={(value) => {
                        setDateSelectOpen(false);
                        handleChange(value);
                      }}
                    />
                  )}
                </>
              );
            }}
          >
            {Object.values(
              type === WorkspaceVariableType.DATE_TIME_AWARE
                ? DateTimeAwareType
                : DateRangeTimeAwareType,
            ).map((value) => (
              <Select.Option value={value} key={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        );
      } else if (type) {
        return (
          <CustomPanelFilter
            filterDisabled={false}
            filterValue={value}
            formType={type}
            onChange={(value) => handleChange(value)}
          />
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "12px 0px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              setAddEditVariableFormConfig({
                visible: !addEditVariableFormConfig.visible,
                editingVariable: null,
              });
              form.resetFields();
            }}
            shape="round"
          >
            {addEditVariableFormConfig.visible ? (
              <MinusOutlined />
            ) : (
              <PlusOutlined />
            )}
            Add New
          </Button>
        </div>
        {addEditVariableFormConfig.visible && (
          <Form
            layout="vertical"
            form={form}
            disabled={createVariableLoading || editVariableLoading}
            onFinish={handleFinish}
            initialValues={{
              values: {
                color: "#d71515",
              },
            }}
          >
            <Form.List name={"values"}>
              {() => (
                <>
                  <h2>
                    {addEditVariableFormConfig.editingVariable
                      ? `Edit Variable: ${addEditVariableFormConfig.editingVariable.label}`
                      : "Add Variable"}
                  </h2>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="ID"
                        name="identity"
                        rules={[
                          { required: true, message: "Please enter an ID" },
                        ]}
                      >
                        <Input
                          placeholder="Enter an ID..."
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Label"
                        name="label"
                        rules={[
                          { required: true, message: "Please enter a label" },
                          {
                            min: 2,
                            message: "Label must be at least 2 characters long",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter a label..."
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Type"
                        name="type"
                        rules={[
                          { required: true, message: "Please select a type" },
                        ]}
                      >
                        <Select
                          placeholder="Select a type..."
                          onChange={(value) => {
                            form.setFieldsValue({
                              type: value,
                            });
                            if (value === WorkspaceVariableType.BOOLEAN) {
                              setDefaultValue(false);
                            } else {
                              setDefaultValue(null);
                            }
                          }}
                        >
                          {[
                            ...Object.keys(WorkspaceVariableType),
                            ...allowedFormTypes,
                          ].map((type) => {
                            return (
                              <Select.Option key={type} value={type}>
                                {snakeCaseToText(type)}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Default Value">
                        {renderDefaultValueInput(
                          form.getFieldValue("values")?.type ||
                            addEditVariableFormConfig.editingVariable?.type,
                          defaultValue,
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Color"
                        name="color"
                        rules={[
                          { required: true, message: "Please select a color" },
                        ]}
                      >
                        <ColorPicker
                          disabledAlpha
                          defaultValue={"#d71515"}
                          onChange={(_, hex) =>
                            form.setFieldsValue({
                              values: {
                                ...form.getFieldValue("values"),
                                color: hex,
                              },
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
        )}
        {createVariableLoading ||
          (editVariableLoading && (
            <Flex justify="center" style={{ marginTop: "10px" }}>
              <Spin />
            </Flex>
          ))}
      </>
    );
  },
);
