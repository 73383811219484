import { IRoute } from "@bluelibs/x-ui";
import { Login } from "./Login/Login";
import { AdminLogin } from "./AdminLogin/AdminLogin";

export const LOGIN: IRoute = {
  path: "/login",
  component: Login,
};

export const LOGIN_ADMIN: IRoute = {
  path: "/login/admin",
  component: AdminLogin,
};
